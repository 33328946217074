import { HttpClient } from "@angular/common/http";
import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from "@angular/core";
import { IStmtResults } from "@model/stmt-results";
import { ToastrService } from "ngx-toastr";
import { BehaviorSubject, combineLatest, of } from "rxjs";
import { map, shareReplay, switchMap } from "rxjs/operators";

@Component({
	selector: "cm-client-edit-domain[customerid][domain_nameid]",
	template: `
		<cm-modal [show]="show" (showChange)="showChange.emit($event)">
			<ng-template cmModal>
				<div class="row">
					<div
						class="h1 col-12 bg-primary text-white text-uppercase flex-center px-sm-5 px-3 pt-5 pb-sm-5 pb-3 mb-0"
					>
						Edit Domain
					</div>
				</div>
				<div class="row form-group justify-content-center" *ngIf="domainInfo$ | async as domainInfo">
					<div class="col-6 my-2">
						<label>Domain Name</label>
						<input [(ngModel)]="domainInfo.domain_name" class="form-control" />
					</div>
					<div class="col-6 my-2">
						<label>Password</label>
						<input [(ngModel)]="domainInfo.password" class="form-control" />
					</div>
					<div class="col-6 my-2">
						<label>Expires</label>
						<input type="date" [(ngModel)]="domainInfo.expires" class="form-control" />
					</div>
					<div class="col-6 my-2">
						<label>DNS Provider</label>
						<input type="text" [(ngModel)]="domainInfo.dns_provider" class="form-control" />
					</div>
					<div class="col-6 my-2">
						<label>MX/DMARC/SPF</label>
						<input type="text" [(ngModel)]="domainInfo.mx_dmarc_spf" class="form-control" />
					</div>
					<div class="col-6 my-2">
						<label>Registrar</label>
						<select [(ngModel)]="domainInfo.registrarid" class="form-control">
							<option *ngFor="let option of registrars$ | async" [value]="option.registrarid">
								{{ option.registrar }}
							</option>
						</select>
					</div>
					<div class="col-6 my-2">
						<label>Domain Category</label>
						<select [(ngModel)]="domainInfo.domain_catid" class="form-control">
							<option *ngFor="let option of domainCats$ | async" [value]="option.domain_catid">
								{{ option.domain_cat }}
							</option>
						</select>
					</div>
					<div class="col-12 text-center my-2">
						<button class="btn btn-primary" (click)="save(domainInfo)">Save</button>
					</div>
				</div>
			</ng-template>
		</cm-modal>
	`,
	styles: [
		`
			:host {
				display: block;
			}
		`,
	],
})
export class ClientEditDomainComponent implements OnChanges {
	@Input() customerid!: number;
	@Input() domain_nameid: number = 0;
	@Input() show: boolean = false;

	@Output() showChange = new EventEmitter();
	@Output() saved = new EventEmitter<void>();

	customeridBS = new BehaviorSubject(0);
	domain_nameidBS = new BehaviorSubject(0);
	saving = false;

	defaultDomain$ = of({
		domain_nameid: null,
		domain_name: null,
		password: null,
		expries: null,
		dns_provider: null,
		mx_dmarc_spf: null,
		registrarid: null,
		domain_catid: null,
	});

	constructor(private http: HttpClient, private toastr: ToastrService) {}

	domainCats$ = this.http
		.post("/api/statement/GetDomainCats", {})
		.pipe(map((response: IStmtResults<any>) => response.results));

	registrars$ = this.http
		.post("/api/statement/GetRegistrars", {})
		.pipe(map((response: IStmtResults<any>) => response.results));

	domainInfo$ = combineLatest([this.customeridBS, this.domain_nameidBS]).pipe(
		switchMap(([customerid, domain_nameid]) => {
			if (domain_nameid === 0 || domain_nameid === null) {
				return this.defaultDomain$;
			} else {
				return this.http
					.post("/api/statement/GetDomainInfo", {
						vars: { customerid, domain_nameid },
					})
					.pipe(
						map((response: IStmtResults<any>) =>
							response.results && response.results[0]
								? response.results[0]
								: {
										domain_nameid: null,
										domain_name: null,
										password: null,
										expries: null,
										dns_provider: null,
										mx_dmarc_spf: null,
										registrarid: null,
										domain_catid: null,
								  },
						),
						shareReplay(1),
					);
			}
		}),
	);

	save(domainInfo: any) {
		if (domainInfo.domain_nameid) {
			this.http
				.post("/api/statement/UpdateCustomerDomain", {
					vars: { ...domainInfo, customerid: this.customerid, slug: domainInfo.domain_name },
				})
				.subscribe(() => {
					this.showChange.next(false);
				});
		} else {
			if (domainInfo.domain_name === undefined || !domainInfo.domain_name) {
				this.toastr.error("Please enter a domain name.");
				return;
			}
			this.http
				.post("/api/statement/AddCustomerDomain", {
					vars: { ...domainInfo, customerid: this.customerid, slug: domainInfo.domain_name },
				})
				.subscribe(() => {
					this.showChange.next(false);
				});
		}
	}

	ngOnChanges(changes: SimpleChanges): void {
		if (changes.customerid) {
			this.customeridBS.next(changes.customerid.currentValue);
		}
		if (changes.domain_nameid) {
			this.domain_nameidBS.next(changes.domain_nameid.currentValue);
		}
	}
}
