import { HttpClient } from "@angular/common/http";
import { Component, EventEmitter, Input, Output } from "@angular/core";

@Component({
	selector: "cm-canned-response-add",
	template: `
		<cm-modal [show]="show" (closeClick)="message = ''" (showChange)="showChange.emit($event)">
			<ng-template cmModal>
				<div class="row">
					<div
						class="h1 col-12 bg-primary text-white text-uppercase flex-center px-sm-5 px-3 pt-5 pb-sm-5 pb-3 mb-0"
					>
						Canned Response
					</div>
				</div>
				<div class="row py-2 align-items-center">
					<div class="col-12">
						<cm-tinymce [(value)]="message"></cm-tinymce>
					</div>
				</div>
				<div class="row py-2 align-items-center">
					<div class="col-12">
						<button class="btn btn-primary w-100 rounded px-1" (click)="saveResponse()">
							Save
						</button>
					</div>
				</div>
			</ng-template>
		</cm-modal>
	`,
	styles: [
		`
			:host ::ng-deep cm-modal .modal-inner > .container {
				width: 750px;
				max-width: 95vw;
			}
		`,
	],
})
export class CannedResponseComponent {
	@Input() show: boolean = false;
	@Output() showChange = new EventEmitter();

	message = "";
	constructor(private http: HttpClient) {}

	saveResponse() {
		this.http
			.post("/api/statement/InsertEmpCannedResponse", {
				vars: { canned_response: this.message.trim() },
			})
			.subscribe(() => {
				this.message = "";
				this.show = false;
				this.showChange.emit(false);
			});
	}
}
