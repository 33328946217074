import { IEnvironment } from "./environment-types";
import { environment as environment12 } from "./environment.12";
import { environmentDefault } from "./environment.default";

export { IEnvironment } from "./environment-types";

export const environment: IEnvironment = {
	...environmentDefault,
	...environment12,
	production: true,
};
