import { Component, Input, Output, EventEmitter, Inject } from "@angular/core";
import { DOCUMENT } from "@angular/common";

@Component({
	selector: "cm-tinymce",
	templateUrl: "./tinymce.component.html",
	styleUrls: ["./tinymce.component.scss"],
})
export class TinymceComponent {
	@Input() disabled: boolean = false;
	@Input() label?: string;
	@Input() intro?: string;
	@Input() value: string = "";

	@Output() valueChange = new EventEmitter<string>();

	error: string | null = null;
	focused: boolean = false;
	validated: boolean = false;
	tinyMceInit: any = {
		suffix: ".min",
		inline: false,
		theme: "silver",
		browser_spellcheck: true,
		contextmenu: false,
		inline_styles: true,
		min_height: 350,
		plugins: [
			"autoresize advlist autolink lists link charmap print preview hr anchor pagebreak",
			"searchreplace wordcount visualblocks visualchars code",
			"insertdatetime media nonbreaking save table directionality",
			"emoticons template paste textpattern imagetools codesample toc",
		],
		toolbar1:
			"undo redo | styleselect | fontsizeselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | link",
		toolbar2: "print preview media | forecolor backcolor emoticons | codesample",
		image_caption: true,
		imagetools_cors_hosts: ["tinymce.com", "codepen.io"],
		force_br_newlines: false,
		force_p_newlines: false,
		remove_linebreaks: true,
		forced_root_block: "",
		relative_urls: false,
		remove_script_host: false,
		convert_urls: false,
		plugin_preview_width:
			Math.max(this.document.documentElement!.clientWidth, this.document.body.clientWidth || 0) - 100,
		plugin_preview_height:
			Math.max(this.document.documentElement!.clientHeight, this.document.body.clientHeight || 0) - 100,
		valid_elements: "+*[*]",
		valid_children: "+body[style]",
	};
	constructor(@Inject(DOCUMENT) private document: any) {}
}
