import { Pipe, PipeTransform } from "@angular/core";
import { iter, iterObj } from "@common/iter";

@Pipe({ name: "keyval" })
export class KeyValPipe implements PipeTransform {
	transform<K, V>(value: Iterable<[K, V]> | {}): { key: K; val: V }[] {
		const val = value as any;
		if (val === undefined) {
			return [];
		}
		if (val === null) {
			return [];
		}
		return (val[Symbol.iterator] ? iter<any>(val) : iterObj(val)).map(([key, val]) => ({ key, val })).toArray();
	}
}
