import { Observable, of, Subject } from "rxjs";
import { SMap } from "./smap";

const hasIntersectionObserver =
	"IntersectionObserver" in window &&
	"isIntersecting" in IntersectionObserverEntry.prototype;

const shouldLoad = hasIntersectionObserver
	? new IntersectionObserver(
			(entries) => {
				for (const { isIntersecting, target } of entries) {
					if (isIntersecting) {
						for (const sub of subjects.get(target).unwrap()) {
							sub.next(undefined);
							sub.complete();
						}
						subjects.delete(target);
						shouldLoad!.unobserve(target);
					}
				}
			},
			{ rootMargin: "300px" },
	  )
	: null;

const subjects = new SMap<Element, Subject<void>[]>();

export function shouldLoad$(el: Element): Observable<void> {
	if (!hasIntersectionObserver) {
		return of();
	}

	const sub = new Subject<void>();
	subjects.getOrInsert(el, []).push(sub);
	shouldLoad!.observe(el);
	return sub.asObservable();
}
