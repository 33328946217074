import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
	name: "tel",
})
export class TelPipe implements PipeTransform {
	transform(value: any, args: Array<any>): string {
		if (!value) {
			return "";
		}

		const newValue = value
			.toString()
			.trim()
			.replace(/[^\d]/g, "");
		let country;
		let city;
		let phoneNumber;

		switch (newValue.length) {
			case 10: // +1PPP####### -> C (PPP) ###-####
				country = 1;
				city = newValue.slice(0, 3);
				phoneNumber = newValue.slice(3);
				break;

			case 11: // +CPPP####### -> CCC (PP) ###-####
				country = parseInt(newValue[0]);
				city = newValue.slice(1, 4);
				phoneNumber = newValue.slice(4);
				break;

			case 12: // +CCCPP####### -> CCC (PP) ###-####
				country = parseInt(newValue.slice(0, 3));
				city = newValue.slice(3, 5);
				phoneNumber = newValue.slice(5);
				break;

			default:
				return value;
		}

		if (country === 1) {
			country = "";
		}

		phoneNumber = phoneNumber.slice(0, 3) + "-" + phoneNumber.slice(3);

		return (country + " (" + city + ") " + phoneNumber).trim();
	}
}

export const TelPipeInjectables: Array<any> = [TelPipe];
