import {
	ChangeDetectionStrategy,
	Component,
	EventEmitter,
	Input,
	OnChanges,
	Output,
	SimpleChanges,
} from "@angular/core";
import { iterFiles } from "@common/iter";
import { RestService, Service } from "@core/app/rest.service";
import { faUpload } from "@fortawesome/pro-solid-svg-icons";
import { ToastrService } from "ngx-toastr";
import Resumable from "resumablejs";
import { BehaviorSubject } from "rxjs";
import prettyBytes from "pretty-bytes";

export enum FileType {
	Any,
	Image,
	Video,
}

@Component({
	selector: "cm-filezone",
	changeDetection: ChangeDetectionStrategy.OnPush,
	template: `
		<cm-dropzone
			class="dropzone text-center my-2"
			role="button"
			(click)="onFileClick()"
			(filesDropped)="type === FileType.Image ? uploadImages($event) : uploadFiles($event)"
		>
			<div class="row">
				<p class="col-12 m-0">
					<fa-icon [icon]="faUpload" [fixedWidth]="true" style="font-size:3rem;"></fa-icon>
				</p>
				<p class="col-12 m-0">Click or Drag & Drop your {{ typeStr() }} here</p>
			</div>
		</cm-dropzone>

		<ngb-progressbar *ngIf="(progressBS | async) !== null" type="info" [value]="progressBS | async">
			Uploading...
		</ngb-progressbar>
		<ngb-progressbar *ngIf="uploadingImagesBS | async" type="info" [value]="100" [striped]="true" [animated]="true">
			Uploading...
		</ngb-progressbar>
	`,
	styles: [":host { display: block; }"],
})
export class FilezoneComponent implements OnChanges {
	@Input() type: FileType = FileType.Any;
	@Input() query: any = {};
	@Input() maxSize?: number;
	@Input() extensions?: string[];
	@Input() minWidth?: number;
	@Input() maxWidth?: number;
	@Input() instagram?: boolean;

	@Output() uploaded = new EventEmitter<any>();

	faUpload = faUpload;
	FileType = FileType;

	progressBS = new BehaviorSubject<number | null>(null);
	uploadingImagesBS = new BehaviorSubject(false);
	resumable: Resumable;
	imgService: Service;

	constructor(private toastr: ToastrService, restService: RestService) {
		this.resumable = new Resumable({
			target: `/api/crud/file/chunkUpload`,
			chunkSize: 1024 * 1024,
			simultaneousUploads: 4,
			testMethod: "POST",
			testChunks: false,
		});
		this.resumable.on("fileSuccess", (_file: any, event: any) => {
			switch (this.type) {
				case FileType.Any:
					this.uploaded.emit(JSON.parse(event).file);
					break;
				case FileType.Video:
					this.uploaded.emit(JSON.parse(event).av);
					break;
			}
			this.progressBS.next(null);
		});
		this.resumable.on("fileProgress", () => this.progressBS.next(Math.round(this.resumable.progress() * 100)));
		this.resumable.on("error", (message: string) => console.error(message));
		this.resumable.on("filesAdded", () => this.resumable.upload());

		this.imgService = restService.init("image-library/v1");
	}

	ngOnChanges(changes: SimpleChanges): void {
		if (changes.type) {
			let url;
			switch (this.type) {
				case FileType.Any:
					url = "file";
					break;
				case FileType.Video:
					url = "av";
					break;
			}

			this.resumable.opts.target = `/api/crud/${url}/chunkUpload`;
		}
		if (changes.query) {
			this.resumable.opts.query = changes.query.currentValue;
		}
	}

	onFileClick() {
		let accept;
		switch (this.type) {
			case FileType.Any:
				accept = "*";
				break;
			case FileType.Image:
				accept = "image/*";
				break;
			case FileType.Video:
				accept = "video/*";
				break;
		}

		const input = document.createElement("input");
		input.type = "file";
		input.multiple = true;
		input.hidden = true;
		input.accept = accept;
		input.addEventListener("change", () => {
			const files = iterFiles(input.files!).toArray();
			this.type === FileType.Image ? this.uploadImages(files) : this.uploadFiles(files);
			input.remove();
		});
		input.addEventListener("blur", () => input.remove());
		document.body.append(input);
		input.click();
	}

	uploadFiles(files: File[]) {
		if (!files.length) {
			this.toastr.error("Please try uploading again", `Error Uploading`);
			return;
		}
		this.progressBS.next(0);
		this.resumable.addFiles(files);
	}

	async uploadImages(files: File[]) {
		if (!files.length) {
			this.toastr.error("Please try uploading again", `Error Uploading`);
			return;
		}
		const oversizedFiles = [];
		const widthFiles: string[] = [];
		const extensionFiles = [];
		const aspectFiles: string[] = [];
		const re = /(?:\.([^.]+))?$/;
		for (const file of files) {
			const ext = re.exec(file.name);
			if (this.maxSize && file.size > this.maxSize) {
				oversizedFiles.push(file.name);
			} else if (this.extensions && !this.extensions.includes(ext ? ext[1] : "")) {
				extensionFiles.push(file.name);
			}
			if (this.minWidth || this.maxWidth || this.instagram) {
				try {
					await new Promise((resolve, reject) => {
						const reader = new FileReader();
						reader.onload = () => {
							const image = new Image();
							image.src = reader.result as string;
							image.onload = () => {
								if (this.minWidth && image.width < this.minWidth) {
									widthFiles.push(file.name);
									reject();
								} else if (this.maxWidth && image.width > this.maxWidth) {
									widthFiles.push(file.name);
									reject();
								} else if (
									this.instagram &&
									(image.width / image.height < 0.8 || image.width / image.height > 1.91)
								) {
									aspectFiles.push(file.name);
									reject();
								} else {
									resolve(null);
								}
							};
						};
						reader.readAsDataURL(file);
					});
				} catch {}
			}
		}
		if (oversizedFiles.length && this.maxSize) {
			this.toastr.error(
				oversizedFiles.join("<br>"),
				`The following files are over the size limit (${prettyBytes(this.maxSize, { binary: true })})`,
				{ enableHtml: true },
			);
		}
		if (extensionFiles.length) {
			this.toastr.error(extensionFiles.join("<br>"), `The following files are not the correct file type`, {
				enableHtml: true,
			});
		}
		if (widthFiles.length) {
			this.toastr.error(widthFiles.join("<br>"), `The following files are not the correct width`, {
				enableHtml: true,
			});
		}
		if (aspectFiles.length) {
			this.toastr.error(aspectFiles.join("<br>"), `The following files are not the correct aspect ratio`, {
				enableHtml: true,
			});
		}

		if (oversizedFiles.length || extensionFiles.length || widthFiles.length || aspectFiles.length) {
			return;
		}

		this.uploadingImagesBS.next(true);
		this.imgService
			.post$("images", { files, urls: [], img: "", img_alt: "", ...this.query }, undefined, true)
			.subscribe((response: any) => {
				for (const image of response.images) {
					this.uploaded.emit(image);
				}
				this.uploadingImagesBS.next(false);
			});
	}

	typeStr() {
		switch (this.type) {
			case FileType.Any:
				return "files";
			case FileType.Image:
				return "images";
			case FileType.Video:
				return "videos";
		}
	}
}
