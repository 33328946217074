import { HttpClient } from "@angular/common/http";
import { Component, EventEmitter, Input, Output, SimpleChanges } from "@angular/core";
import { ToastrService } from "ngx-toastr";
import { of, BehaviorSubject } from "rxjs";
import { switchMap, map, shareReplay } from "rxjs/operators";

@Component({
	selector: "cm-customer-email-add",
	template: `
		<cm-modal
			[show]="show"
			(closeClick)="email = ''; showChange.emit(false)"
			(showChange)="showChange.emit($event)"
		>
			<ng-template cmModal>
				<div class="row">
					<div
						class="h1 col-12 bg-primary text-white text-uppercase flex-center px-sm-5 px-3 pt-5 pb-sm-5 pb-3 mb-0"
					>
						Customer Email
					</div>
				</div>
				<ng-container *ngIf="tagid">
					<div class="row py-2 align-items-center">
						<div class="col-12">
							<div class="h3">Emails With Tag</div>
							<ng-container *ngFor="let te of taggedEmails$ | async">
								<div class="row py-2">
									<div class="col-8">{{ te.customer_email }}</div>
									<div class="col-4">
										<button
											class="btn btn-danger"
											*ngIf="(cantRemoveEmails$ | async)?.indexOf(te.customer_email) === -1"
											(click)="removeTag(te.customer_emailid)"
										>
											Remove Tag
										</button>
									</div>
								</div>
							</ng-container>
							<div class="h3">Emails Without Tag</div>
							<ng-container *ngFor="let ue of unTaggedEmails$ | async">
								<div class="row py-2">
									<div class="col-8">{{ ue.customer_email }}</div>
									<div class="col-4">
										<button class="btn btn-primary" (click)="addTag(ue.customer_emailid)">
											Add Tag
										</button>
									</div>
								</div>
							</ng-container>
						</div>
					</div>
				</ng-container>
				<div class="row py-2 align-items-center">
					<div class="col-12">
						<input type="text" [(ngModel)]="email" class="form-control rounded" />
					</div>
				</div>
				<div class="row py-2 align-items-center">
					<div class="col-12">
						<button
							class="btn btn-primary w-100 rounded px-1"
							[disabled]="email === ''"
							(click)="saveResponse()"
						>
							Save
						</button>
					</div>
				</div>
			</ng-template>
		</cm-modal>
	`,
	styles: [
		`
			:host ::ng-deep cm-modal .modal-inner > .container {
				width: 500px;
				max-width: 95vw;
			}
		`,
	],
})
export class CustomerEmailComponent {
	@Input() data!: any;
	@Input() tagid?: any;
	@Input() show: boolean = false;
	@Output() showChange = new EventEmitter();

	email = "";
	constructor(private http: HttpClient, private toastrService: ToastrService) {}

	taggedEmails$ = of([]);
	unTaggedEmails$ = of([]);
	cantRemoveEmails$ = of([]);

	updateBS = new BehaviorSubject(null);

	saveResponse() {
		if (this.data.customerid === undefined || !this.data.customerid) {
			this.toastrService.error("Please select a customer.");
			return;
		}
		this.http
			.post("/api/statement/InsertCustomerEmail", {
				vars: { email: this.email.trim(), customerid: this.data.customerid, tagid: this.tagid },
			})
			.subscribe(() => {
				this.email = "";
				this.show = false;
				this.showChange.emit(false);
				this.updateBS.next(null);
			});
	}

	ngOnChanges(changes: SimpleChanges): void {
		if (changes.tagid) {
			this.taggedEmails$ = this.updateBS.pipe(
				switchMap(() =>
					this.http.post("/api/statement/GetCustEmails", {
						vars: { customerid: this.data.customerid, tagid: this.tagid },
					}),
				),
				map((response: any) => response.results),
			);
			this.cantRemoveEmails$ = this.updateBS.pipe(
				switchMap(() =>
					this.http.post("/api/statement/GetCustEmailsCantRemove", {
						vars: { customerid: this.data.customerid },
					}),
				),
				map((response: any) => response.results.map((r: any) => r.customer_email)),
				shareReplay(1),
			);
			this.unTaggedEmails$ = this.updateBS.pipe(
				switchMap(() =>
					this.http.post("/api/statement/GetCustEmails", {
						vars: { customerid: this.data.customerid, notTagid: this.tagid },
					}),
				),
				map((response: any) => response.results),
			);
		}
	}

	addTag(customer_emailid: number) {
		this.http
			.post("/api/statement/InsertEmailTag", {
				vars: { customer_emailid, tagid: this.tagid },
			})
			.subscribe(() => {
				this.updateBS.next(null);
			});
	}

	removeTag(customer_emailid: number) {
		this.http
			.post("/api/statement/DeleteEmailTag", {
				vars: { customer_emailid, tagid: this.tagid },
			})
			.subscribe(() => {
				this.updateBS.next(null);
			});
	}
}
