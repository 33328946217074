import { DOCUMENT } from "@angular/common";
import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { differenceInSeconds } from "date-fns-2";
import { CookieService } from "ngx-cookie-service";
import { fromEvent, Subscription } from "rxjs";
import { isPrerendering } from "shared";
import { GTMService } from "./gtm.service";

let LAST_TRACK: any = null;
let TRACK_POLL_TIMEOUT: any = null;

@Injectable({ providedIn: "root" })
export class TrackingService {
	onWindowScroll: Subscription | null = null;
	onDocumentMouseLeave: Subscription | null = null;

	constructor(
		@Inject(DOCUMENT) private document: any,
		private GTMService: GTMService,
		private cookieService: CookieService,
		private route: ActivatedRoute,
		private http: HttpClient,
	) {
		window.onbeforeunload = () => this.trackVisit({ leave: true });
	}

	/**
	 * Track route changes in the app
	 *
	 * @param route
	 */
	trackRouteChange(prevUrl: string | null, nextUrl: string) {
		const input = { currentUrl: { url: nextUrl }, lastUrl: { url: prevUrl } };

		// track the visit to this page
		this.trackVisit(input);

		// let google analytics know the url has changed
		const url = window.location.href.replace(window.location.origin, "");
		if (url.indexOf("admin") === -1 && url.indexOf("lead-management") === -1) {
			this.GTMService.trackEvent("ngRouteChange", {
				route: window.location.href.replace(window.location.origin, ""),
			});
		}

		const trackVisitRateLimited = () => this.trackVisitRateLimited(input);

		// TODO: this should be 0.5, 1, 2.5, 5, 10, and 15 minutes
		// track again after 5, 10, and 15 minutes
		this.timeoutSequenceCancel();
		if (!isPrerendering())
			this.timeoutSequence([1000 * 60 * 5, 1000 * 60 * 5, 1000 * 60 * 5], trackVisitRateLimited);

		// track mouseleave and scroll up
		if (this.onWindowScroll) {
			this.onWindowScroll.unsubscribe();
			this.onWindowScroll = null;
			this.onDocumentMouseLeave!.unsubscribe();
			this.onDocumentMouseLeave = null;
		}
		LAST_TRACK = new Date();
		this.onDocumentMouseLeave = fromEvent(this.document, "mouseleave").subscribe(trackVisitRateLimited);
		this.onWindowScroll = fromEvent(window, "scroll").subscribe(trackVisitRateLimited);
	}

	/**
	 * Tracks a visit to the page
	 *
	 * @param input
	 */
	trackVisit(input: any) {
		// if (isScullyRunning()) {
		// 	return;
		// }
		// if (input !== undefined && !input.hasOwnProperty("currentUrl")) {
		// 	input.currentUrl = { url: window.location.pathname };
		// }
		// if (input !== undefined && input.hasOwnProperty("currentUrl") && input.currentUrl.url.indexOf("admin") === -1) {
		// 	const searchQuery = input.searchQuery || null;
		// 	const leave = input.leave || false;
		// 	let email;
		// 	let utm_campaign;
		// 	let utm_source;
		// 	let utm_medium;
		// 	let utm_term;
		// 	this.route.queryParams
		// 		.pipe(first())
		// 		.subscribe((params) => {
		// 			email = params.email || null;
		// 			utm_campaign = params.utm_campaign || null;
		// 			utm_source = params.utm_source || null;
		// 			utm_medium = params.utm_medium || null;
		// 			utm_term = params.utm_term || null;
		// 		})
		// 		.unsubscribe();
		// 	this.http
		// 		.post("/api/visitor/track", {
		// 			host: window.location.host,
		// 			hostname: window.location.hostname,
		// 			protocol: window.location.protocol,
		// 			port: window.location.port,
		// 			actionName: input.actionName || null,
		// 			actionId: input.actionId || null,
		// 			searchQuery: searchQuery,
		// 			ts: Math.floor(Date.now() / 1000),
		// 			leave: leave,
		// 			currentUrl: input.currentUrl || { url: window.location.pathname },
		// 			lastUrl: input.lastUrl || null,
		// 			email: email,
		// 			utm_campaign: utm_campaign,
		// 			utm_source: utm_source,
		// 			utm_medium: utm_medium,
		// 			utm_term: utm_term,
		// 			referer: null,
		// 		})
		// 		.subscribe((response: any) => {
		// 			if (response.success === true && leave !== true && response.visitorId !== 0) {
		// 				this.cookieService.set("visitorId", response.visitorId, { expires: 365 * 30 });
		// 				this.cookieService.set("ga_vid", response.visitorId, { expires: 365 * 30 });
		// 				this.cookieService.set("uid", response.visitorId, { expires: 365 * 30 });
		// 				if (!this.cookieService.check("visitorVisitId")) {
		// 					// Set 30 minutes cookie
		// 					this.cookieService.set("visitorVisitId", response.visitorVisitId, { expires: 1 / 48 });
		// 				} else {
		// 					// Refresh
		// 					this.cookieService.set("visitorVisitId", this.cookieService.get("visitorVisitId"), {
		// 						expires: 1 / 48,
		// 					});
		// 				}
		// 			}
		// 		});
		// }
	}

	/**
	 * @param input
	 * @private
	 */
	private trackVisitRateLimited(input: any) {
		const now = new Date();

		// rate limit to one request every 60 seconds
		if (differenceInSeconds(now, LAST_TRACK) < 60) {
			return;
		}

		LAST_TRACK = now;
		if (this.document.visibilityState === "visible") {
			this.trackVisit(input);
		}
	}

	// TODO: move into new service
	private timeoutSequence(sequence: any, callback: any) {
		if (sequence.length === 0) {
			return;
		}

		const delay = sequence.pop();

		TRACK_POLL_TIMEOUT = setTimeout(() => {
			callback();
			this.timeoutSequence(sequence, callback);
		}, delay);
	}

	// TODO: move into new service
	private timeoutSequenceCancel() {
		if (TRACK_POLL_TIMEOUT) {
			clearTimeout(TRACK_POLL_TIMEOUT);
			TRACK_POLL_TIMEOUT = null;
		}
	}
}
