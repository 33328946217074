import { Injectable } from "@angular/core";
import { empty, fromEvent, Observable } from "rxjs";
import { IOption, some, none } from "@common/option";
import { map, startWith } from "rxjs/operators";

const WINDOW: IOption<Window> = typeof window === "undefined" ? none() : some(window);

@Injectable({ providedIn: "root" })
export class Window2Service {
	readonly event = {
		scroll$: windowEvent("scroll"),
		resize$: windowEvent("resize"),
	};

	scrollY$ = this.event.scroll$.pipe(
		map(() => window.scrollY),
		startWith(WINDOW.mapOr(0, (win) => win.scrollY)),
	);
}

function windowEvent(event: string): Observable<Event> {
	return WINDOW.mapOrElse(
		() => empty(),
		(win) => fromEvent(win, event),
	);
}
