import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { ReactiveFormsModule } from "@angular/forms";
import { RadioBlockDirective } from "./components/radio-block.directive";
import { RadioBlocksComponent } from "./components/radio-blocks.component";

@NgModule({
	declarations: [RadioBlocksComponent, RadioBlockDirective],
	imports: [CommonModule, ReactiveFormsModule],
	exports: [RadioBlocksComponent, RadioBlockDirective],
})
export class FormUtilModule {}
