import { HttpClient } from "@angular/common/http";
import { Component, Input, OnChanges, SimpleChanges } from "@angular/core";
import { IStmtResults } from "@model/stmt-results";
import { ToastrService } from "ngx-toastr";
import { BehaviorSubject } from "rxjs";
import { map, shareReplay, switchMap } from "rxjs/operators";

@Component({
	selector: "cm-client-edit-contract-options[customerid]",
	template: `
		<div class="row">
			<div class="col-12"><strong>Includes:</strong></div>
			<ng-container *ngFor="let projectCat of projectCats; index as i">
				<div class="col-12 col-lg-6">
					<div class="form-check w-100 w-lg-auto">
						<input
							class="mr-1"
							id="option{{ i }}"
							type="checkbox"
							[ngModel]="(customerProjects$ | async)?.has(projectCat.project_catid)"
							(ngModelChange)="save($event, projectCat.project_catid)"
						/>
						<label for="option{{ i }}">{{ projectCat.project_cat }}</label
						><br />
					</div>
				</div>
			</ng-container>
		</div>
	`,
	styles: [
		`
			:host {
				display: block;
			}
		`,
	],
})
export class ClientEditContractOptionsComponent implements OnChanges {
	@Input() customerid!: number;

	customeridBS = new BehaviorSubject(0);

	saving = false;

	projectCats: any[] = [];

	customerProjectCats = new Map();

	customerProjects$ = this.customeridBS.pipe(
		switchMap((customerid) =>
			this.http.post("/api/statement/GetCustomerProjects", {
				vars: { customerid, project_cat_ofid: 83, groupByProject: false },
			}),
		),
		map((response: IStmtResults<any>) => {
			const ret = new Map();

			response.results?.forEach((row) => {
				ret.set(row.project_catid, row.customer_project);
			});
			return ret;
		}),
		shareReplay(1),
	);

	constructor(private http: HttpClient, private toastr: ToastrService) {
		this.http
			.post("/api/statement/GetProjectCats", { vars: { project_cat_ofid: 83 } })
			.subscribe((response: IStmtResults<any>) => (this.projectCats = response.results!));
	}

	save(enable: boolean, project_catid: number) {
		if (!project_catid || !this.customerid) {
			this.toastr.error("Missing Project Category and Customer");
			return;
		}
		this.saving = true;

		if (enable) {
			this.http
				.post("/api/statement/AddCustomerProject", {
					vars: { customerid: this.customerid, project_catid: project_catid },
				})
				.subscribe(() => this.customeridBS.next(this.customerid));
		} else {
			this.http
				.post("/api/statement/DeleteCustomerProject", {
					vars: { customerid: this.customerid, project_catid: project_catid },
				})
				.subscribe(() => this.customeridBS.next(this.customerid));
		}
	}

	ngOnChanges(changes: SimpleChanges): void {
		if (changes.customerid) {
			this.customeridBS.next(changes.customerid.currentValue);
		}
	}
}
