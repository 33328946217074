import { HttpClient } from "@angular/common/http";
import { Component, Input, OnChanges, SimpleChanges } from "@angular/core";
import { IStmtResults } from "@model/stmt-results";
import { ToastrService } from "ngx-toastr";
import { BehaviorSubject } from "rxjs";
import { map, shareReplay, switchMap } from "rxjs/operators";

@Component({
	selector: "cm-client-edit-contract[customerid]",
	template: `
		<div class="row">
			<div class="col-12 col-md-6 col-lg-3">
				<p><strong>Contract Type:</strong></p>
				<label for="starts">Starts: </label><br />
				<input type="date" id="starts" [(ngModel)]="starts" (ngModelChange)="save(true, null)" /><br />
				<label for="ends">Ends: </label><br />
				<input type="date" id="ends" [(ngModel)]="ends" (ngModelChange)="save(true, null)" /><br />
			</div>
			<div class="col-12 col-md-6 col-lg-9">
				<div class="row">
					<ng-container *ngFor="let projectCat of projectCats">
						<div class="col-12 col-lg-4">
							<div class="form-check w-100 w-lg-auto">
								<input
									class="mr-1"
									type="checkbox"
									[ngModel]="(customerProjects$ | async)?.has(projectCat.project_catid)"
									(ngModelChange)="save($event, projectCat.project_catid)"
								/>
								<label>{{ projectCat.project_cat }}</label
								><br />
							</div>
						</div>
					</ng-container>
				</div>
			</div>
		</div>
	`,
	styles: [
		`
			:host {
				display: block;
			}
		`,
	],
})
export class ClientEditContractComponent implements OnChanges {
	@Input() customerid!: number;

	customeridBS = new BehaviorSubject(0);

	customer_projectid: null | number = null;

	saving = false;

	starts: any = null;
	ends: any = null;
	product_catid: any = 114;

	projectCats: any[] = [];

	customerProjectCats = new Map();

	customerProjects$ = this.customeridBS.pipe(
		switchMap((customerid) =>
			this.http.post("/api/statement/GetCustomerProjects", {
				vars: { customerid, project_cat_ofid: 113, groupByProject: false },
			}),
		),
		map((response: IStmtResults<any>) => {
			const ret = new Map();
			if (response.results && response.results[0]) {
				this.customer_projectid = response.results[0].customer_projectid;
				this.starts = response.results[0].start_date;
				this.ends = response.results[0].end_date;
				this.product_catid = response.results![0].project_catid;
				ret.set(response.results![0].project_catid, response.results![0].customer_project);
			} else {
				this.customer_projectid = null;
				this.starts = null;
				this.ends = null;
				this.product_catid = 114;
			}
			return ret;
		}),
		shareReplay(1),
	);

	constructor(private http: HttpClient, private toastr: ToastrService) {
		this.http
			.post("/api/statement/GetProjectCats", { vars: { project_cat_ofid: 113 } })
			.subscribe((response: IStmtResults<any>) => (this.projectCats = response.results!));
	}

	save(enable: boolean, project_catid: number | null) {
		this.saving = true;

		if (this.customer_projectid !== null) {
			if (enable) {
				this.http
					.post("/api/statement/UpdateCustomerProject", {
						vars: {
							customerid: this.customerid,
							project_catid: project_catid !== null ? project_catid : this.product_catid,
							customer_projectid: this.customer_projectid,
							start: this.starts !== "" ? this.starts : null,
							end: this.ends !== "" ? this.ends : null,
						},
					})
					.subscribe(() => this.customeridBS.next(this.customerid));
			} else {
				this.http
					.post("/api/statement/UpdateCustomerProject", {
						vars: {
							customerid: this.customerid,
							project_catid: 114,
							customer_projectid: this.customer_projectid,
							start: this.starts !== "" ? this.starts : null,
							end: this.ends !== "" ? this.ends : null,
						},
					})
					.subscribe(() => this.customeridBS.next(this.customerid));
			}
		} else {
			if (enable) {
				if (!project_catid) {
					this.toastr.error("Missing Project Category");
					return;
				}
				this.http
					.post("/api/statement/AddNewCustomerProject", {
						vars: {
							customerid: this.customerid,
							project_catid: project_catid !== null ? project_catid : this.product_catid,
							customer_project: "Contract",
							start: this.starts !== "" ? this.starts : null,
							end: this.ends !== "" ? this.ends : null,
						},
					})
					.subscribe(() => this.customeridBS.next(this.customerid));
			} else {
				this.http
					.post("/api/statement/AddNewCustomerProject", {
						vars: {
							customerid: this.customerid,
							customer_project: "Contract",
							project_catid: 114,
							start: this.starts !== "" ? this.starts : null,
							end: this.ends !== "" ? this.ends : null,
						},
					})
					.subscribe(() => this.customeridBS.next(this.customerid));
			}
		}
	}

	ngOnChanges(changes: SimpleChanges): void {
		if (changes.customerid) {
			this.customeridBS.next(changes.customerid.currentValue);
		}
	}
}
