import { HttpClient } from "@angular/common/http";
import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from "@angular/core";
import { CrmQueuesService } from "@core/app/crm/services/queues.service";
import { BehaviorSubject, combineLatest, Observable, of } from "rxjs";
import { map, switchMap } from "rxjs/operators";

@Component({
	selector: "cm-email-uncomplete",
	template: `
		<cm-modal [show]="show" (closeClick)="reset()" (showChange)="showChange.emit($event)">
			<ng-template cmModal>
				<div class="row">
					<div
						class="h1 col-12 bg-primary text-white text-center text-uppercase flex-center px-sm-5 px-3 pt-5 pb-sm-5 pb-3 mb-0"
					>
						Email
					</div>
				</div>
				<div class="row py-2 align-items-center">
					<div class="col-12">
						<div
							class="my-1 p-2 pointer-cursor border rounded d-inline-block"
							(click)="showAddEmail = true"
						>
							+ Add New Customer Email
						</div>
					</div>
					<div class="col-12">
						<label class="py-2 w-100">Customer Email</label>
						<ng-container *ngFor="let e of customerEmail; index as index; trackBy: trackByFn">
							<div class="row my-1 align-items-center">
								<div class="col-10">
									<select [(ngModel)]="customerEmail[index]" class="form-control rounded">
										<option [value]="''"></option>
										<option
											*ngFor="let email of customerEmails$ | async"
											[value]="email.customer_email"
										>
											{{ email.customer_email }}
										</option>
									</select>
								</div>
								<div class="col-2">
									<button class="btn-danger rounded" (click)="customerEmail.splice(index, 1)">
										X
									</button>
								</div>
							</div>
						</ng-container>
						<div
							class="my-1 p-2 pointer-cursor border rounded d-inline-block"
							(click)="customerEmail.push('')"
						>
							+ Add Additional Email
						</div>
					</div>
				</div>
				<div class="row py-2 align-items-center">
					<div class="col-12">
						<label class="py-2 w-100">Customer CC Email</label>
						<ng-container *ngFor="let e of customerCcEmail; index as index; trackBy: trackByFn">
							<div class="row">
								{{ e }}
								<div class="col-10 my-1 align-items-center">
									<select [(ngModel)]="customerCcEmail[index]" class="form-control rounded">
										<option [value]="''"></option>
										<option
											*ngFor="let email of customerEmails$ | async"
											[value]="email.customer_email"
										>
											{{ email.customer_email }}
										</option>
									</select>
								</div>
								<div class="col-2">
									<button class="btn-danger rounded" (click)="customerCcEmail.splice(index, 1)">
										X
									</button>
								</div>
							</div>
						</ng-container>
						<div
							class="my-1 p-2 pointer-cursor border rounded d-inline-block"
							(click)="customerCcEmail.push('')"
						>
							+ Add Additional Email
						</div>
					</div>
				</div>
				<div class="row py-2 align-items-center">
					<div class="col-12">
						<label class="py-2 w-100">Customer BCC Email</label>
						<ng-container *ngFor="let e of customerBccEmail; index as index; trackBy: trackByFn">
							<div class="row">
								<div class="col-10 my-1 align-items-center">
									<select [(ngModel)]="customerBccEmail[index]" class="form-control rounded">
										<option [value]="''"></option>
										<option
											*ngFor="let email of customerEmails$ | async"
											[value]="email.customer_email"
										>
											{{ email.customer_email }}
										</option>
									</select>
								</div>
								<div class="col-2">
									<button class="btn-danger rounded" (click)="customerBccEmail.splice(index, 1)">
										X
									</button>
								</div>
							</div>
						</ng-container>
						<div
							class="my-1 p-2 pointer-cursor border rounded d-inline-block"
							(click)="customerBccEmail.push('')"
						>
							+ Add Additional Email
						</div>
					</div>
				</div>
				<div class="row py-2 align-items-center">
					<div class="col-12 form-group">
						<label>Subject</label>
						<div
							class="m-1 p-2 pointer-cursor border rounded d-inline-block"
							(click)="showAddSubject = true"
						>
							+ Add New Canned Subject
						</div>
						<select
							[ngModel]="cannedSub"
							(ngModelChange)="subject = $event"
							class="form-control rounded my-2"
						>
							<option value=""></option>
							<option *ngFor="let cr of cannedSubjects$ | async" [value]="cr.value">
								{{ cr.preview }}
							</option>
						</select>
						<input [(ngModel)]="subject" class="form-control rounded" />
					</div>
				</div>
				<div class="row py-2 align-items-center">
					<div class="col-12">
						<label class="py-2">Canned Response </label>
						<div class="m-1 p-2 pointer-cursor border rounded d-inline-block" (click)="showAdd = true">
							+ Add New Canned Response
						</div>
						<select [ngModel]="canned" (ngModelChange)="message = $event" class="form-control rounded">
							<option value=""></option>
							<option *ngFor="let cr of cannedResponses$ | async" [value]="cr.value">
								{{ cr.preview }}
							</option>
						</select>
					</div>
				</div>
				<div class="row py-2 align-items-center">
					<div class="col-12">
						<cm-tinymce [(value)]="message"></cm-tinymce>
					</div>
				</div>
				<div class="row py-2 align-items-center">
					<div class="col-12">
						<cm-filezone [query]="{ file_catid: 20 }" (uploaded)="uploadedFiles.push($event)"></cm-filezone>
						<div class="my-1" *ngIf="uploadedFiles.length > 0">
							{{ uploadedFiles.length }} File(s) to Attach
						</div>
					</div>
				</div>
				<div class="row py-2 align-items-center">
					<div class="col-12">
						<button
							class="btn btn-primary w-100 rounded px-1"
							[disabled]="customerEmail.length < 1 || subject === '' || message === ''"
							(click)="sendEmail()"
						>
							Send Email
						</button>
					</div>
				</div>
			</ng-template>
		</cm-modal>
		<cm-canned-response-add
			[show]="showAdd"
			(showChange)="updateBS.next(null); showAdd = $event"
		></cm-canned-response-add>
		<cm-canned-subject-add
			[show]="showAddSubject"
			(showChange)="updateBS.next(null); showAddSubject = $event"
		></cm-canned-subject-add>
		<cm-customer-email-add
			[show]="showAddEmail"
			(showChange)="updateBS.next(null); showAddEmail = $event"
			[data]="{ customerid: customerid }"
		></cm-customer-email-add>
	`,
	styles: [
		`
			:host ::ng-deep cm-modal .modal-inner > .container {
				width: 830px;
				max-width: 95vw;
			}
		`,
	],
})
export class UncompleteEmailComponent implements OnChanges {
	@Input() customerid: any;
	@Input() tasks: any;
	@Input() show: boolean = false;
	@Output() showChange = new EventEmitter();

	uploadedFiles: any[] = [];
	showAdd = false;
	showAddEmail = false;
	viewHistory = false;
	showAddSubject = false;
	showAll = false;

	message = "";
	customerEmail = [""];
	customerCcEmail = [];
	customerBccEmail = [];
	canned = "";
	subject = "";
	cannedSub = "";

	customeridBS = new BehaviorSubject(0);
	updateBS = new BehaviorSubject(null);

	emails$: Observable<any> = of([]);

	constructor(private http: HttpClient, public queuesService: CrmQueuesService) {}

	cannedResponses$ = combineLatest([this.updateBS, this.customeridBS]).pipe(
		switchMap(([_, customerid]) =>
			this.http.post("/api/statement/GetEmpCannedResponses", {
				vars: { customerid },
			}),
		),
		map((response: any) => response.results),
	);

	cannedSubjects$ = combineLatest([this.updateBS, this.customeridBS]).pipe(
		switchMap(([_, customerid]) =>
			this.http.post("/api/statement/GetEmpCannedSubjects", {
				vars: { customerid },
			}),
		),
		map((response: any) => response.results),
	);

	customerEmails$ = combineLatest([this.updateBS, this.customeridBS]).pipe(
		switchMap(([_, customerid]) =>
			this.http.post("/api/statement/GetCustEmails", {
				vars: { customerid },
			}),
		),
		map((response: any) => response.results),
	);

	ngOnChanges(changes: SimpleChanges): void {
		if (changes.customerid) {
			this.customeridBS.next(changes.customerid.currentValue);
		}
	}

	reset() {
		this.message = "";
		this.subject = "";
		this.uploadedFiles = [];
		this.canned = "";
		this.cannedSub = "";
		this.customerEmail = [""];
		this.customerCcEmail = [];
		this.customerBccEmail = [];
	}

	sendEmail() {
		this.http
			.post("/api/onboarding/sendEmail", {
				customerid: this.customerid,
				customerEmail: this.customerEmail,
				customerCcEmail: this.customerCcEmail,
				customerBccEmail: this.customerBccEmail,
				message: this.message,
				subject: this.subject,
				files: this.uploadedFiles.map((row: any) => row.fileid),
				tasks: this.tasks,
			})
			.subscribe(() => {
				this.reset();
				this.show = false;
				this.showChange.emit(false);
			});
	}

	trackByFn(i: number, _item: any) {
		return i;
	}
}
