import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { SearchComponent } from "./components/search/search.component";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { FormsModule } from "@angular/forms";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { RouterModule } from "@angular/router";

@NgModule({
	declarations: [SearchComponent],
	imports: [CommonModule, FontAwesomeModule, FormsModule, NgbModule, RouterModule],
	exports: [SearchComponent],
})
export class SearchModule {}
