import { Component } from "@angular/core";
import { iter, tuple } from "@common/iter";
import { faFacebook, faGithub, faGoogle, faLinkedin, faTwitter } from "@fortawesome/free-brands-svg-icons";
import { IStmtResults } from "@model/stmt-results";
import { AuthService, ConfigService as AuthConfig } from "ng2-ui-auth";
import { ToastrService } from "ngx-toastr";
import { Observable } from "rxjs";
import { map, shareReplay, tap } from "rxjs/operators";
import { RestService } from "../rest.service";
import { UserService } from "../user.service";

@Component({
	selector: "cm-social-login",
	templateUrl: "./social-login.component.html",
	styleUrls: ["./social-login.component.scss"],
})
export class SocialLoginComponent {
	icons = {
		facebook: faFacebook,
		github: faGithub,
		google: faGoogle,
		linkedin: faLinkedin,
		twitter: faTwitter,
	};

	providers$: Observable<{ affiliate_name: string; oauth_id: string }[]>;

	constructor(
		private auth: AuthService,
		private toastrService: ToastrService,
		private userService: UserService,
		authConfig: AuthConfig,
		restService: RestService,
	) {
		this.providers$ = restService
			.init("statement")
			.post$("GetActiveOAuthProviders")
			.pipe(
				map((response: IStmtResults<{ affiliate_name: string; oauth_id: string }>) => response.results!),
				tap((results) => {
					const providers = iter(results)
						.map((row) =>
							tuple(row.affiliate_name.toLowerCase(), {
								name: row.affiliate_name,
								url: `/api/auth/${row.affiliate_name.toLowerCase()}`,
								clientId: row.oauth_id,
							}),
						)
						.toObject();
					authConfig.updateProviders(providers);
				}),
				shareReplay(),
			);
	}

	/**
	 * Opens window to authenticate with the social platform
	 *
	 * @param button
	 */
	authenticate(affiliateName: string) {
		// Open Pop-up
		this.auth.authenticate(affiliateName).subscribe(
			(response) => this.userService.setUser(response.user),
			(error) => {
				if (error.message) {
					// Ng2UiAuthModule promise reject error.
					this.toastrService.info(error.message);
				} else {
					this.toastrService.info("Login Cancelled");
				}
			},
		);
	}
}
