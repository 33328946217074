import { HttpClient } from "@angular/common/http";
import { Component, EventEmitter, Input, Output } from "@angular/core";

@Component({
	selector: "cm-canned-subject-add",
	template: `
		<cm-modal [show]="show" (closeClick)="subject = ''" (showChange)="showChange.emit($event)">
			<ng-template cmModal>
				<div class="row">
					<div
						class="h1 col-12 bg-primary text-white text-uppercase flex-center px-sm-5 px-3 pt-5 pb-sm-5 pb-3 mb-0"
					>
						Canned Subject
					</div>
				</div>
				<div class="row py-2 align-items-center">
					<div class="col-12">
						<input [(ngModel)]="subject" class="form-control" />
					</div>
				</div>
				<div class="row py-2 align-items-center">
					<div class="col-12">
						<button class="btn btn-primary w-100 rounded px-1" (click)="save()">
							Save
						</button>
					</div>
				</div>
			</ng-template>
		</cm-modal>
	`,
	styles: [
		`
			:host ::ng-deep cm-modal .modal-inner > .container {
				width: 500px;
				max-width: 95vw;
			}
		`,
	],
})
export class CannedSubjectComponent {
	@Input() show: boolean = false;
	@Output() showChange = new EventEmitter();

	subject = "";
	constructor(private http: HttpClient) {}

	save() {
		this.http
			.post("/api/statement/InsertEmpCannedSubject", {
				vars: { canned_subject: this.subject.trim() },
			})
			.subscribe(() => {
				this.subject = "";
				this.show = false;
				this.showChange.emit(false);
			});
	}
}
