import { IOption, none, some } from "./option";
import { iter } from "./iter";
import { Frozen, Immutable } from "./decorators";

@Frozen
@Immutable
export class Path {
	constructor(private path: string) {}

	/**
	 * Returns:
	 * * None, if there is no file name;
	 * * None, if there is no embedded .;
	 * * None, if the file name begins with . and has no other .s within;
	 * * Otherwise, the portion of the file name after the final .
	 */
	extension(): IOption<string> {
		return this.fileName().andThen<string>((fileName) => {
			const start = fileName.lastIndexOf(".");

			if (start <= 0) {
				return none();
			}

			return some(fileName.substring(start + 1));
		});
	}

	/**
	 * @returns * The final segment of `path`, if there is one. May return file or directory name. If the path ends with
	 * "..", returns `none`.
	 */
	fileName(): IOption<string> {
		return iter(this.pathSegments())
			.last()
			.andThen<string>((lastSegment) =>
				lastSegment === "." || lastSegment === ".." ? none() : some(lastSegment),
			);
	}

	/**
	 * @returns
	 * The non-extension portion of `fileName(path)`.
	 *
	 * Returns:
	 * * None, if there is no file name;
	 * * The entire file name if there is no embedded .;
	 * * The entire file name if the file name begins with . and has no other .s within;
	 * * Otherwise, the portion of the file name before the final .
	 */
	fileStem(): IOption<string> {
		return this.fileName().map((fileName) => {
			let end: number | undefined = fileName.lastIndexOf(".");

			if (end <= 0) {
				end = undefined;
			}

			return fileName.substring(0, end);
		});
	}

	join(path: PathLike): Path {
		if (path instanceof Path) {
			path = path.path;
		}

		if (path.startsWith("/")) {
			return new Path(path);
		}

		const slash = this.path.endsWith("/") ? "" : "/";
		return new Path(`${this.path}${slash}${path}`);
	}

	parent(): IOption<Path> {
		const segments = iter(this.pathSegments()).toArray();
		if (segments.pop()) {
			return some(new Path(segments.join("/")));
		} else {
			return none();
		}
	}

	toString(): string {
		return this.path;
	}

	private *pathSegments(): IterableIterator<string> {
		if (this.path && (typeof this.path === "string" || (this.path as any) instanceof String)) {
			const segments = this.path.split("/");

			if (!segments.length) {
				return;
			}

			if (segments[0]) {
				yield segments[0];
			}

			for (const segment of iter(segments).skip(1)) {
				if (segment !== "" && segment !== ".") {
					yield segment;
				}
			}
		} else {
			return;
		}
	}
}

export type PathLike = Path | string;
