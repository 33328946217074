<ng-container>
	<cm-label *ngIf="label" [forid]="id" [text]="label" [required]="required"></cm-label>
	<div *ngIf="intro" [innerHTML]="intro"></div>
	<div class="input-group">
		<input
			#input
			type="text"
			class="form-control"
			[id]="id"
			[name]="name"
			[disabled]="disabled"
			[required]="required"
			[(ngModel)]="value"
			(ngModelChange)="onModelChange()"
			(blur)="validate()"
		/>
		<div class="input-group-append">
			<button
				type="button"
				class="btn btn-outline-secondary btn-sm"
				[disabled]="disabled"
				(click)="onPickerToggle($event)"
				[ngClass]="{ active: pickerVisible }"
			>
				<fa-icon [icon]="faClock"></fa-icon>
			</button>
		</div>
	</div>
	<div *ngIf="pickerVisible" class="position-relative" (click)="$event.stopPropagation()">
		<div class="position-absolute bg-light border shadow z-3 d-flex p-3">
			<ngb-timepicker
				[seconds]="true"
				[meridian]="true"
				[(ngModel)]="ngbTime"
				class="d-inline-block"
			></ngb-timepicker>
			<button class="btn btn-primary ml-3" (click)="onSubmit()">Submit</button>
		</div>
	</div>
	<div class="invalid-feedback">{{ error }}</div>
</ng-container>
