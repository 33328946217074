import { HttpClient } from "@angular/common/http";
import { Component, Input, OnChanges, SimpleChanges } from "@angular/core";
import { IStmtResults } from "@model/stmt-results";
import { ToastrService } from "ngx-toastr";
import { BehaviorSubject } from "rxjs";
import { map, switchMap } from "rxjs/operators";

@Component({
	selector: "cm-client-edit-ai[customerid]",
	template: `
		<p><strong>Client Details:</strong></p>
		<label for="ai-clent">Details</label><br />
		<textarea
			*ngIf="aiClient$ | async as aiClient"
			id="ai-client"
			class="mr-1 form-control"
			[ngModel]="aiClient.customer_note"
			(ngModelChangeDebounced)="saveNote($event, aiClient.customer_noteid, 21)"
		></textarea>
		<label for="ai-clent">Details</label><br />
		<textarea
			*ngIf="aiClientDetailed$ | async as aiClientDetailed"
			id="ai-client"
			class="mr-1 form-control"
			[ngModel]="aiClientDetailed.customer_note"
			(ngModelChangeDebounced)="saveNote($event, aiClientDetailed.customer_noteid, 26)"
		></textarea>

		<label for="ai-facebook">Facebook Details</label><br />
		<textarea
			*ngIf="aiFacebook$ | async as aiFacebook"
			id="ai-facebook"
			class="mr-1 form-control"
			[ngModel]="aiFacebook.customer_note"
			(ngModelChangeDebounced)="saveNote($event, aiFacebook.customer_noteid, 22)"
		></textarea>

		<label for="ai-instagram">Instagram Details</label><br />
		<textarea
			*ngIf="aiInstrgram$ | async as aiInstrgram"
			id="ai-instagram"
			class="mr-1 form-control"
			[ngModel]="aiInstrgram.customer_note"
			(ngModelChangeDebounced)="saveNote($event, aiInstrgram.customer_noteid, 23)"
		></textarea>

		<label for="ai-review">Review Details</label><br />
		<textarea
			*ngIf="aiReview$ | async as aiReview"
			id="ai-review"
			class="mr-1 form-control"
			[ngModel]="aiReview.customer_note"
			(ngModelChangeDebounced)="saveNote($event, aiReview.customer_noteid, 24)"
		></textarea>

		<label for="ai-comment">Comment Details</label><br />
		<textarea
			*ngIf="aiComment$ | async as aiComment"
			id="ai-comment"
			class="mr-1 form-control"
			[ngModel]="aiComment.customer_note"
			(ngModelChangeDebounced)="saveNote($event, aiComment.customer_noteid, 25)"
		></textarea>
	`,
	styles: [
		`
			:host {
				display: block;
			}
		`,
	],
})
export class ClientEditAIComponent implements OnChanges {
	@Input() customerid!: number;

	customeridBS = new BehaviorSubject(0);

	aiClient$ = this.customeridBS.pipe(
		switchMap((customerid) =>
			this.http.post("/api/statement/GetCustomerNotes", {
				vars: { customerid, note_titleid: 21 },
			}),
		),
		map((response: IStmtResults<any>) =>
			response.results && response.results[0]
				? response.results[0]
				: { customer_note: "", customer_noteid: null },
		),
	);

	aiClientDetailed$ = this.customeridBS.pipe(
		switchMap((customerid) =>
			this.http.post("/api/statement/GetCustomerNotes", {
				vars: { customerid, note_titleid: 26 },
			}),
		),
		map((response: IStmtResults<any>) =>
			response.results && response.results[0]
				? response.results[0]
				: { customer_note: "", customer_noteid: null },
		),
	);

	aiFacebook$ = this.customeridBS.pipe(
		switchMap((customerid) =>
			this.http.post("/api/statement/GetCustomerNotes", {
				vars: { customerid, note_titleid: 22 },
			}),
		),
		map((response: IStmtResults<any>) =>
			response.results && response.results[0]
				? response.results[0]
				: { customer_note: "", customer_noteid: null },
		),
	);

	aiInstrgram$ = this.customeridBS.pipe(
		switchMap((customerid) =>
			this.http.post("/api/statement/GetCustomerNotes", {
				vars: { customerid, note_titleid: 23 },
			}),
		),
		map((response: IStmtResults<any>) =>
			response.results && response.results[0]
				? response.results[0]
				: { customer_note: "", customer_noteid: null },
		),
	);

	aiReview$ = this.customeridBS.pipe(
		switchMap((customerid) =>
			this.http.post("/api/statement/GetCustomerNotes", {
				vars: { customerid, note_titleid: 24 },
			}),
		),
		map((response: IStmtResults<any>) =>
			response.results && response.results[0]
				? response.results[0]
				: { customer_note: "", customer_noteid: null },
		),
	);

	aiComment$ = this.customeridBS.pipe(
		switchMap((customerid) =>
			this.http.post("/api/statement/GetCustomerNotes", {
				vars: { customerid, note_titleid: 25 },
			}),
		),
		map((response: IStmtResults<any>) =>
			response.results && response.results[0]
				? response.results[0]
				: { customer_note: "", customer_noteid: null },
		),
	);

	constructor(private http: HttpClient, private toastr: ToastrService) {}

	saveNote(note: string, customerNoteId: null | number, note_titleid: number) {
		if (note) {
			if (customerNoteId) {
				this.http
					.post("/api/statement/UpdateCustomerNote", {
						vars: { customer_noteid: customerNoteId, customer_note: note },
					})
					.subscribe(() => this.customeridBS.next(this.customerid));
			} else {
				this.http
					.post("/api/statement/AddCustomerNote", {
						vars: { customerid: this.customerid, note_titleid, customer_note: note },
					})
					.subscribe(() => this.customeridBS.next(this.customerid));
			}
		} else if (customerNoteId) {
			this.http
				.post("/api/statement/DeleteCustomerNote", {
					vars: { customer_noteid: customerNoteId },
				})
				.subscribe(() => this.customeridBS.next(this.customerid));
		}
	}

	ngOnChanges(changes: SimpleChanges): void {
		if (changes.customerid) {
			this.customeridBS.next(changes.customerid.currentValue);
		}
	}
}
