import { IOption, option } from "./option";

/**
 * Wraps around a native `Map` type and adds a couple methods that are inspired by Rust's
 * [Entry](https://doc.rust-lang.org/std/collections/hash_map/enum.Entry.html) type.
 */
export class SMap<K, V> {
	private map: Map<K, V>;

	// TODO: figure out this overload thing
	constructor(entries: Iterable<[K, V]>);
	constructor(entries?: ReadonlyArray<[K, V]> | null);
	constructor(entries: any) {
		this.map = new Map(entries);
	}

	toMap(): Map<K, V> {
		return this.map;
	}

	getOrInsert(key: K, val: V): V {
		return this.get(key).match({
			some: (val) => val,
			none: () => {
				this.map.set(key, val);
				return val;
			},
		});
	}

	getOrInsertWith(key: K, cb: () => V): V {
		return this.get(key).match({
			some: (val) => val,
			none: () => {
				const val = cb();
				this.map.set(key, val);
				return val;
			},
		});
	}

	clear(): void {
		this.map.clear();
	}

	delete(key: K): boolean {
		return this.map.delete(key);
	}

	entries(): IterableIterator<[K, V]> {
		return this.map.entries();
	}

	forEach(callbackfn: (value: V, key: K, map: Map<K, V>) => void, thisArg?: any): void {
		this.map.forEach(callbackfn, thisArg);
	}

	get(key: K): IOption<V> {
		return option(this.map.get(key));
	}

	has(key: K): boolean {
		return this.map.has(key);
	}

	keys(): IterableIterator<K> {
		return this.map.keys();
	}

	set(key: K, value: V): Map<K, V> {
		return this.map.set(key, value);
	}

	values(): IterableIterator<V> {
		return this.map.values();
	}

	[Symbol.iterator](): IterableIterator<[K, V]> {
		return this.map[Symbol.iterator]();
	}
}
