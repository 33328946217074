<ng-container *ngIf="(providers$ | async)?.length">
	<div class="text-center">
		<button
			*ngFor="let provider of (providers$ | async)"
			class="btn social {{ provider.affiliate_name | lowercase }}"
			(click)="authenticate(provider.affiliate_name.toLowerCase())"
			ngbTooltip="{{ provider.affiliate_name }}"
			placement="bottom"
		>
			<fa-icon
				[class]="provider.affiliate_name === 'Facebook' ? 'mr-2' : ''"
				[icon]="icons[provider.affiliate_name | lowercase]"
			></fa-icon>
			{{ provider.affiliate_name === "Facebook" ? "Log in with Facebook" : "" }}
		</button>
	</div>

	<div class="or">
		<span>OR</span>
	</div>
</ng-container>
