import { DOCUMENT, getNumberOfCurrencyDigits } from "@angular/common";
import { HttpClient } from "@angular/common/http";
import { ChangeDetectionStrategy, Component, Inject, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { Window2Service } from "@common/services/window2.service";
import { UserService } from "@core/app/user.service";
import { faBars, faTimesCircle } from "@fortawesome/pro-solid-svg-icons";
import { IPageData } from "@model/page-data";
import { combineLatest, Observable, of, Subject } from "rxjs";
import { concat, distinctUntilChanged, map, shareReplay, switchMap } from "rxjs/operators";

@Component({
	selector: "cm-header-design-force",
	templateUrl: "./header-design-force.component.html",
	styleUrls: ['./header-design-force.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderDesignForceComponent implements OnInit {
	fixed$: Observable<boolean>;
	siteUrl: string;
	dealer: string;
	logoUrl: string;
	loadS: Subject<void> = new Subject();
	searchValues$: Observable<any>;
	searchOptions: any;
	location = this.pageData.locations[0];
	mobileMenu: boolean = false;
	faTimesCircle = faTimesCircle;
	faBars = faBars;
	fullRoute: string = '';
	mainRoute: string = '';

	constructor(
		private router: Router,
		http: HttpClient,
		@Inject("PAGE_DATA") private pageData: IPageData,
		window2Service: Window2Service,
		user: UserService,
	) {
		this.fixed$ = window2Service.scrollY$.pipe(
			map((scrollY) => scrollY > 46),
			distinctUntilChanged(),
		);

		this.siteUrl = "https://" + this.pageData.host + "/";
		this.dealer = this.pageData.headerData.queries.GetDealerInfo[0].dealer;
		this.logoUrl =
			this.pageData.headerData.queries.GetDealerInfo[0].img_dir +
			this.pageData.headerData.queries.GetDealerInfo[0].img_file;
		this.searchValues$ = combineLatest(user.loggedIn$, user.isCustomer$, this.loadS).pipe(
			map(([loggedIn, isCustomer]) =>
				loggedIn && !isCustomer
					? this.pageData.searchUrls.salesSearchResults
					: this.pageData.searchUrls.searchResults,
			),
			switchMap((url) => of(null).pipe(concat(http.get(url)))),
			shareReplay(),
		);

		this.searchOptions = {
			disabled: false,
			focusFirst: false,
			focus: () => this.loadS.next(),
			changed: (model: any, event: any) => {
				if (event && event.keyCode === 13) {
					if (model && model.hasOwnProperty("link")) {
						this.router.navigateByUrl(model.link);
					} else if (model && model.hasOwnProperty("item")) {
						this.router.navigateByUrl(model.item.link);
					} else if (model.length > 0) {
						this.router.navigateByUrl("/search?s=" + model);
					}
				} else if (model && (model.hasOwnProperty("link") || model.hasOwnProperty("item"))) {
					if (model.link) {
						this.router.navigateByUrl(model.link);
					} else if (model.item && model.item.link) {
						this.router.navigateByUrl(model.item.link);
					}
				}
			},
			enterKeySubmit: true,
			placeholder: "What are you looking for?",
		};
	}
	ngOnInit(): void {
		this.router.events.subscribe((url: any) => {
			this.getRoutes(this.router.url);
		});
	}

	// sets route and sub route for styling 
	getRoutes(route: string) {
		this.fullRoute = route;
		let mainRoute = this.fullRoute.slice(0, 5);
		if (mainRoute === '/serv') {
			this.mainRoute = '/services';
		} else if (mainRoute === '/indu') {
			this.mainRoute = '/industries';
		} else {
			this.mainRoute = route;
		}
	}

	toggleNav() {
		this.mobileMenu = !this.mobileMenu
	}
}