import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { ImageModule } from "@core/app/image/image.module";
import { CrmSharedModule } from "../shared/shared.module";
import { ModalModule } from "shared";
import { QueueItemEmailComponent } from "./components/queue-item.component";
import { TinymceComponent } from "./components/tinymce/tinymce.component";
import { EditorModule } from "@tinymce/tinymce-angular";
import { CannedResponseComponent } from "./components/canned-response.component";
import { CustomerEmailComponent } from "./components/customer-email.component";
import { QueueItemApprovalEmailComponent } from "./components/queue-item-approval.component";
import { SafeHtmlPipe } from "./pipes/safe-html.pipe";
import { CannedSubjectComponent } from "./components/canned-subject.component";
import { FormModule } from "@core/app/form/form.module";
import { LayoutModule } from "@core/app/layout/layout.module";
import { FormsModule } from "@angular/forms";
import { UncompleteEmailComponent } from "./components/uncomplete.component";

@NgModule({
	declarations: [
		QueueItemEmailComponent,
		TinymceComponent,
		CannedResponseComponent,
		CustomerEmailComponent,
		QueueItemApprovalEmailComponent,
		SafeHtmlPipe,
		CannedSubjectComponent,
		UncompleteEmailComponent,
	],
	imports: [
		CommonModule,
		ImageModule,
		NgbModule,
		FontAwesomeModule,
		CrmSharedModule,
		ModalModule,
		EditorModule,
		FormModule,
		LayoutModule,
		FormsModule,
	],
	exports: [
		QueueItemEmailComponent,
		QueueItemApprovalEmailComponent,
		CustomerEmailComponent,
		UncompleteEmailComponent,
		TinymceComponent,
	],
})
export class CrmEmailModule {}
