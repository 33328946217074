<div class="calculator-holder fixed-calc" [ngClass]="{ moving: moving }" ngDraggable [handle]="DragHandle">
	<button class="close" type="button" class="close" (click)="closeCalc()" aria-hidden="true">&times;</button>
	<div #DragHandle class="move"><fa-icon [icon]="faArrows"></fa-icon></div>
	<form class="calculator-form">
		<div class="calculator-ops container-fluid">
			<div class="calculator-ops-top row">
				<div class="col-3">
					<div class="clear-button" (click)="calculator('C')">C</div>
				</div>
				<div class="col-9">
					<div class="total"><input name="screen" [(ngModel)]="screen" readonly /></div>
				</div>
			</div>
			<div class="calculator-buttons row">
				<div class="col-3">
					<div class="number-button" (click)="calculator(7)">7</div>
				</div>
				<div class="col-3">
					<div class="number-button" (click)="calculator(8)">8</div>
				</div>
				<div class="col-3">
					<div class="number-button" (click)="calculator(9)">9</div>
				</div>
				<div class="col-3">
					<div class="number-button" (click)="calculator('+')">+</div>
				</div>
			</div>
			<div class="calculator-buttons row">
				<div class="col-3">
					<div class="number-button" (click)="calculator(4)">4</div>
				</div>
				<div class="col-3">
					<div class="number-button" (click)="calculator(5)">5</div>
				</div>
				<div class="col-3">
					<div class="number-button" (click)="calculator(6)">6</div>
				</div>
				<div class="col-3">
					<div class="number-button" (click)="calculator('-')">-</div>
				</div>
			</div>
			<div class="calculator-buttons row">
				<div class="col-3">
					<div class="number-button" (click)="calculator(1)">1</div>
				</div>
				<div class="col-3">
					<div class="number-button" (click)="calculator(2)">2</div>
				</div>
				<div class="col-3">
					<div class="number-button" (click)="calculator(3)">3</div>
				</div>
				<div class="col-3">
					<div class="operator-button" (click)="calculator('/')">/</div>
				</div>
			</div>
			<div class="calculator-buttons row">
				<div class="col-3">
					<div class="number-button" (click)="calculator(0)">0</div>
				</div>
				<div class="col-3">
					<div class="operator-button" (click)="calculator('=')">=</div>
				</div>
				<div class="col-3">
					<div class="operator-button" (click)="calculator('.')">.</div>
				</div>
				<div class="col-3">
					<div class="operator-button" (click)="calculator('x')">x</div>
				</div>
			</div>
		</div>
	</form>
</div>
