<div class="cm-time-clock">
	<ng-container *ngIf="loggedIn$ | async; else login">
		<ng-container *ngIf="loaded; else loading">
			<div class="username" *ngIf="kioskMode">Hello, {{ userName }}</div>
			<h4 class="title">Time Clock</h4>
			<hr />
			<div class="d-flex justify-content-center">
				<div class="d-flex flex-column align-items-stretch">
					<ng-container *ngFor="let punch of punchTypes$ | async | keyvalue">
						<button
							*ngIf="clockMachine.canGo(punch.key)"
							type="button"
							class="btn btn-outline-dark"
							[disabled]="saving"
							(click)="switchState(punch.key)"
						>
							{{ punch.value.name }}
						</button>
					</ng-container>
					<button
						*ngIf="kioskMode"
						type="button"
						class="btn btn-outline-dark single-punch-selection"
						(click)="logOut()"
					>
						Log Out
					</button>
				</div>
			</div>
			<hr />
			<div *ngIf="punchTypes$ | async as punchTypes" class="current-status">
				Current Status:
				<span
					class="mr-1"
					[ngStyle]="{ backgroundColor: punchTypes.get(currentPunchState).color || '#b22222' }"
				>
					&nbsp;&nbsp;&nbsp;&nbsp;
				</span>
				{{ punchTypes.get(currentPunchState)!.status || "Not Clocked In" }}
			</div>
		</ng-container>
	</ng-container>
	<ng-template #login>
		<cm-login *ngIf="kioskMode"></cm-login>
	</ng-template>
</div>

<ng-template #timeVerify let-modal>
	<div class="modal-header">
		<h4 class="modal-title" id="timeVerify">Verify Time Punches</h4>
		<button type="button" class="close" aria-label="Close" (click)="modal.dismiss()">
			<span aria-hidden="true">&times;</span>
		</button>
	</div>
	<div class="modal-body">
		<ng-container *ngFor="let day of verifyDays">
			<h4 class="summary">{{ day.date | date : "fullDate" }}</h4>
			<table class="table table-light table-sm">
				<thead>
					<tr>
						<th>Time Punch</th>
						<th>Time</th>
						<th>Work Time</th>
						<th>Lunch/Break Time</th>
						<th>&nbsp;</th>
					</tr>
				</thead>
				<tbody>
					<tr
						*ngFor="let punch of day.items.items"
						[ngClass]="{ 'text-success': punch.change.change !== null }"
					>
						<td>{{ punch.punchType }}</td>
						<td>{{ getDateObject(punch.punch_at) | date : "shortTime" }}</td>
						<td>
							<ng-container *ngIf="punch.workTime">
								{{ punch.workTime | number : "1.2-2" }}
							</ng-container>
						</td>
						<td>
							<ng-container *ngIf="punch.breakTime">
								{{ punch.breakTime | number : "1.2-2" }}
							</ng-container>
						</td>
						<td>
							<button
								*ngIf="!punch.verified_by_userid"
								class="btn btn-sm mr-1"
								[ngClass]="{
									'btn-outline-danger': punch.change.change === null,
									'btn-outline-secondary': punch.change.change !== null
								}"
								(click)="openChangeModal(punch, day)"
							>
								Change
							</button>
							<span
								*ngIf="punch.approved_by_userid === null && punch.punch_flag === 'System Punch'"
								class="mr-1 text-danger"
							>
								<strong>SYSTEM PUNCH Please Adjust</strong>
							</span>
						</td>
					</tr>
					<tr>
						<td colspan="3" class="text-right">Day Total:</td>
						<td>
							<strong>{{ day.items.dayTotal | number : "1.2-2" }}</strong>
						</td>
						<td>
							<button class="btn btn-success" (click)="submitVerify(day)">Verify</button>
						</td>
					</tr>
				</tbody>
			</table>
		</ng-container>
	</div>
</ng-template>

<ng-template #changePunch let-modal>
	<div class="modal-header">
		<h4 class="modal-title" id="changePunch">Verify Punches</h4>
		<button type="button" class="close" aria-label="Close" (click)="modal.dismiss()">
			<span aria-hidden="true">&times;</span>
		</button>
	</div>
	<div class="modal-body">
		<div class="row">
			<div class="col-12">
				<strong>Original:</strong>
				{{ (punchTypes$ | async)?.get(changeItem.change.original.time_punch_typeid)?.name }}
				{{ changeItem.change.original.punch_at | date : "short" }}
			</div>
			<div class="col-12">
				<hr />
			</div>
			<div class="col-12">
				<strong>Change:</strong>
			</div>
			<div class="col-12">
				<div class="form-group">
					<label>Time Punch Type:</label>
					<select
						id="time_punch_typeid"
						class="form-control"
						[(ngModel)]="changeItem.change.change.time_punch_typeid"
						(ngModelChange)="changeItem.change.change.modified = true"
					>
						<option *ngFor="let punch of punchTypes$ | async | keyvalue" value="{{ punch.key }}">
							{{ punch.value.name }}
						</option>
					</select>
				</div>
			</div>
			<div class="col-12">
				<div class="form-group">
					<label>Time:</label><br />
					<input
						type="text"
						class="form-control"
						mwlFlatpickr
						[(ngModel)]="changeItem.change.change.punch_at"
						(ngModelChange)="changeItem.change.change.modified = true"
						[altInput]="true"
						[altFormat]="'F j, Y H:i K'"
						[dateFormat]="'Y-m-d H:i:S'"
						[enableTime]="true"
						minDate="{{ changeItem.punchDate }} 00:00:00"
						maxDate="{{ changeItem.punchDate }} 23:59:59"
						[minuteIncrement]="1"
					/>
				</div>
			</div>
		</div>
	</div>
	<div class="modal-footer">
		<button class="btn btn-secondary" (click)="modal.dismiss()">Cancel</button>
		<button class="btn btn-primary" (click)="modal.close()" [disabled]="!changeItem.change.change.modified">
			Apply
		</button>
	</div>
</ng-template>

<ng-template #loading>
	<ngb-progressbar type="info" [value]="100" [striped]="true" [animated]="true">Loading...</ngb-progressbar>
</ng-template>
