<cm-header-design-force></cm-header-design-force>
<ng-container>
	<cm-user-widget></cm-user-widget>
</ng-container>
<span itemscope [attr.itemtype]="webPageSchema">
	<div class="site-wrapper" itemscope itemtype="http://schema.org/WebPageElement" itemprop="mainContentOfPage">
		<router-outlet></router-outlet>
	</div>
</span>
<div toastContainer></div>
<cm-footer-design-force></cm-footer-design-force>