<div class="cm-login login-page">
	<cm-social-login></cm-social-login>
	<div *ngIf="view === 'SignIn'" class="sign-in">
		<form #login="ngForm" (ngSubmit)="signIn()" name="loginForm">
			<div class="form-group">
				<label>Username</label>
				<input
					type="text"
					class="form-control"
					id="userName-{{ idNum }}"
					autofocus
					required
					[(ngModel)]="username"
					name="username"
					placeholder="Username"
					#name="ngModel"
					(ngModelChange)="errorMessage = ''"
				/>
				<div [hidden]="name.valid || name.pristine">Username is required.</div>
			</div>
			<div class="loginPassword form-group">
				<label>Password</label>
				<input
					type="password"
					class="form-control"
					id="userPassword-{{ idNum }}"
					required
					maxlength="100"
					[(ngModel)]="password"
					name="password"
					(keyup.enter)="signIn()"
					#pass="ngModel"
					(ngModelChange)="errorMessage = ''"
				/>
				<div [hidden]="pass.valid || pass.pristine">
					Password is required and must be under 100 characters.
				</div>
			</div>
			<div class="form-check">
				<input
					type="checkbox"
					class="form-check-input"
					id="remember-me"
					[(ngModel)]="remember"
					[ngModelOptions]="{ standalone: true }"
				/>
				<label class="form-check-label" for="remember-me">Remember me</label>
			</div>
			<div *ngIf="errorMessage" class="error">
				<div class="well">{{ errorMessage }}</div>
			</div>
			<div class="buttons text-center mt-2" *ngIf="!signingIn">
				<button
					class="signin_button btn btn-primary"
					aria-label="signin_button"
					[disabled]="!login.form.valid"
					type="submit"
				>
					Sign In
				</button>
			</div>
			<ng-container *ngIf="signingIn">
				<div class="buttons text-center">
					<ngb-progressbar type="info" [value]="100" [striped]="true" [animated]="true">
						Loading...
					</ngb-progressbar>
				</div>
			</ng-container>
			<div class="create-account mt-1 mb-1">
				<span (click)="forgotPassword()">Forgot Password?</span>
			</div>
			<div class="create-account">
				<span (click)="view = 'SignUp'">Create Account</span>
			</div>
		</form>
	</div>
	<div *ngIf="view === 'SignUp'" class="sign-up">
		<div class="container">
			<form #create="ngForm" (ngSubmit)="signUp()" name="loginForm" class="row">
				<div class="col-6 form-group">
					<label for="firstName-{{ idNum }}">First Name</label>
					<input
						type="text"
						required
						id="firstName-{{ idNum }}"
						name="firstName"
						#firstName="ngModel"
						class="form-control"
						[(ngModel)]="user.firstName"
					/>
				</div>
				<div class="col-6 form-group">
					<label for="lastName-{{ idNum }}">Last Name</label>
					<input
						type="text"
						required
						id="lastName-{{ idNum }}"
						name="lastName"
						#lastName="ngModel"
						class="form-control"
						[(ngModel)]="user.lastName"
					/>
				</div>
				<div class="col-12 form-group">
					<label for="userLogin-{{ idNum }}">Username</label>
					<input
						type="text"
						required
						id="userLogin-{{ idNum }}"
						name="userLogin"
						#userLogin="ngModel"
						class="form-control"
						[(ngModel)]="user.userLogin"
					/>
				</div>
				<div class="col-12 form-group">
					<label for="email-{{ idNum }}">Email Address</label>
					<input
						type="email"
						required
						id="email-{{ idNum }}"
						name="email"
						#email="ngModel"
						class="form-control"
						[(ngModel)]="user.email"
					/>
				</div>
				<div class="col-12 form-group">
					<label for="phoneNumber-{{ idNum }}">Phone Number</label>
					<input
						type="tel"
						required
						id="phoneNumber-{{ idNum }}"
						name="phoneNumber"
						#phoneNumber="ngModel"
						class="form-control"
						[(ngModel)]="user.phoneNumber"
					/>
				</div>
				<div class="col-12 form-group">
					<label for="password-{{ idNum }}">Password</label>
					<input
						type="password"
						required
						id="password-{{ idNum }}"
						name="password"
						validateEqual="confirmPassword"
						reverse="true"
						#password="ngModel"
						maxlength="100"
						class="form-control"
						[(ngModel)]="user.password"
					/>
				</div>
				<div class="col-12 form-group">
					<label for="confirmPassword-{{ idNum }}">Confirm Password</label>
					<input
						type="password"
						required
						id="confirmPassword-{{ idNum }}"
						name="confirmPassword"
						validateEqual="password"
						#confirmPassword="ngModel"
						maxlength="100"
						class="form-control"
						[(ngModel)]="user.confirmPassword"
					/>
				</div>
				<div class="col-12">
					<div class="container">
						<div
							class="row"
							[hidden]="
								firstName.valid &&
								lastName.valid &&
								userLogin.valid &&
								email.valid &&
								phoneNumber.valid &&
								password.valid &&
								confirmPassword.valid
							"
						>
							<div class="col-12">All fields are required.</div>
						</div>
						<div class="row" [hidden]="password.valid || password.pristine">
							<div class="col-12">Password must be under 100 characters.</div>
						</div>
						<div class="row" [hidden]="confirmPassword.valid || confirmPassword.pristine">
							<div class="col-12">Password and Confirm Password do no match.</div>
						</div>
						<div class="row">
							<div class="col-5">
								<button class="btn btn-primary" [disabled]="!create.form.valid" type="submit">
									Sign Up
								</button>
							</div>
							<div class="col-7 text-right">
								<button class="btn btn-outline-secondary bold" (click)="view = 'SignIn'">
									&lt; Back to Sign In
								</button>
							</div>
						</div>
					</div>
				</div>
			</form>
		</div>
	</div>
</div>
