/// <reference types="googlemaps" />

import {
	AfterContentInit,
	ApplicationRef,
	Component,
	ContentChildren,
	ElementRef,
	EmbeddedViewRef,
	Input,
	OnChanges,
	OnDestroy,
	QueryList,
	Renderer2,
	SimpleChanges,
	TemplateRef,
} from "@angular/core";
import MarkerClusterer from "@googlemaps/markerclustererplus";
import { BehaviorSubject, combineLatest, Observable, ReplaySubject } from "rxjs";
import { map, scan, shareReplay, startWith, switchMap, takeUntil, tap } from "rxjs/operators";
import { loadScript, setDifference } from "shared/common";
import { isPrerendering } from "../prerender/prerender.service";
import { MapPopupDirective } from "./map-popup.directive";

const SCRIPT_URL =
	"https://maps.googleapis.com/maps/api/js?v=weekly&libraries=places&key=AIzaSyDK6RybE29BiXD8VPd_BtKeE0LuvjaCcYA";

const mapPool: google.maps.Map[] = [];

MarkerClusterer.IMAGE_PATH = "//dfm-cdn.com/static/12/m";

@Component({
	selector: "cm-google-maps",
	template: ``,
	styles: [
		`
			:host {
				display: block;
			}

			:host > div {
				height: 100%;
			}

			:host ::ng-deep .popup {
				position: absolute;
				transform: translateX(-50%) translateY(-50%);
			}
		`,
	],
})
export class GoogleMapsComponent implements AfterContentInit, OnChanges, OnDestroy {
	@Input() disableDefaultUI: boolean = false;
	@Input() markers: google.maps.ReadonlyMarkerOptions[] = [];
	@Input() cluster: boolean = false;

	@ContentChildren(MapPopupDirective) popups!: QueryList<MapPopupDirective>;

	ngAfterContentInitRS = new ReplaySubject<void>(1);
	ngOnDestroyRS = new ReplaySubject<void>(1);
	disableDefaultUIRS = new ReplaySubject<boolean>(1);
	markersBS = new BehaviorSubject<google.maps.ReadonlyMarkerOptions[]>([]);
	clusterRS = new ReplaySubject<boolean>(1);

	constructor(private renderer: Renderer2, app: ApplicationRef, el: ElementRef) {
		if (isPrerendering()) return;

		const script$ = loadScript(SCRIPT_URL);

		// Popup must extend a class in Google's SDK, so wait for it to load before creating Popup
		const Popup$ = script$.pipe(
			map(() => createPopupClass(app)),
			shareReplay(1),
		);

		// create map
		const map$ = script$.pipe(
			map(() => mapPool.pop() || new google.maps.Map(this.renderer.createElement("div"))),
			tap((map) => renderer.appendChild(el.nativeElement, map.getDiv())),
			shareReplay(1),
		);

		// convert QueryList to observable
		const popupTpls$: Observable<QueryList<MapPopupDirective>> = this.ngAfterContentInitRS.pipe(
			switchMap(() => this.popups.changes.pipe(startWith(this.popups))),
		);

		// create/destroy markers
		const markers$ = combineLatest([map$, this.markersBS]).pipe(
			scan((markers, [map, markerOpts]) => {
				const oldOpts = new Set(markers.keys());
				const newOpts = new Set(markerOpts || []);
				for (const del of setDifference(oldOpts, newOpts)) {
					markers.get(del)!.setMap(null);
					markers.delete(del);
				}
				for (const add of setDifference(newOpts, oldOpts)) {
					const marker = new google.maps.Marker(add);
					marker.setMap(map);
					markers.set(add, marker);
				}
				return markers;
			}, new Map() as Map<google.maps.MarkerOptions, google.maps.Marker>),
			map((markers) => Array.from(markers.values())),
			shareReplay(1),
		);

		// create/destroy popups
		const popups$ = combineLatest([map$, popupTpls$, Popup$]).pipe(
			scan((popups, [map, popupTpls, Popup]) => {
				const oldOpts = new Set(popups.keys());
				const newOpts = new Set(popupTpls || []);
				for (const del of setDifference(oldOpts, newOpts)) {
					popups.get(del)!.setMap(null);
					popups.delete(del);
				}
				for (const add of setDifference(newOpts, oldOpts)) {
					const pos = new google.maps.LatLng(add.pos.lat, add.pos.lng);
					const popup = new Popup(add.tpl, pos);
					popup.setMap(map);
					popups.set(add, popup);
				}
				return popups;
			}, new Map() as Map<MapPopupDirective, IPopup>),
			map((popups) => Array.from(popups.values())),
		);

		// update map options
		combineLatest([map$, this.disableDefaultUIRS])
			.pipe(takeUntil(this.ngOnDestroyRS))
			.subscribe(([map, disableDefaultUI]) => map.setOptions({ disableDefaultUI }));

		// calculate bounds
		const bounds$ = script$.pipe(
			switchMap(() => combineLatest([this.markersBS, popupTpls$])),
			takeUntil(this.ngOnDestroyRS),
			map(([markers, popups]) => {
				const bounds = new google.maps.LatLngBounds();
				for (const marker of markers) if (marker.position) bounds.extend(marker.position);
				for (const popup of popups) if (popup.pos) bounds.extend(popup.pos);
				return bounds;
			}),
			shareReplay(1),
		);

		// update bounds
		combineLatest([map$, bounds$]).subscribe(([map, bounds]) => map.fitBounds(bounds));

		// create/destroy clusterer
		const clusterer$ = combineLatest([map$, this.clusterRS]).pipe(
			scan<[google.maps.Map<any>, boolean], MarkerClusterer | null>((clusterer, [map, cluster]) => {
				if (cluster && !clusterer) {
					clusterer = new MarkerClusterer(map);
				} else if (!cluster && clusterer) {
					clusterer.clearMarkers();
					clusterer.setMap(null);
					clusterer = null;
				}
				return clusterer;
			}, null),
			shareReplay(1),
		);

		// cluster/uncluster markers and popups
		combineLatest([clusterer$, markers$, popups$]).subscribe(([clusterer, markers, popups]) => {
			if (clusterer) {
				clusterer.clearMarkers();
				clusterer.addMarkers(markers);
				clusterer.addMarkers(popups as unknown as google.maps.Marker[]);
			}
		});

		// repaint clusters when bounds change
		combineLatest([clusterer$, bounds$]).subscribe(([clusterer]) => setTimeout(() => clusterer?.repaint()));

		// clean up clusterer
		this.ngOnDestroyRS.pipe(switchMap(() => clusterer$)).subscribe((clusterer) => {
			if (!clusterer) return;
			clusterer.clearMarkers();
			clusterer.setMap(null);
		});

		// clean up markers
		this.ngOnDestroyRS.pipe(switchMap(() => markers$)).subscribe((markers) => {
			for (const marker of markers) marker.setMap(null);
		});

		// clean up map
		this.ngOnDestroyRS.pipe(switchMap(() => map$)).subscribe((map) => mapPool.push(map));
	}

	ngAfterContentInit(): void {
		this.ngAfterContentInitRS.next();
		this.ngAfterContentInitRS.complete();
	}

	ngOnChanges(changes: SimpleChanges): void {
		if (changes.disableDefaultUI) this.disableDefaultUIRS.next(this.disableDefaultUI);
		if (changes.markers) this.markersBS.next(this.markers);
		if (changes.cluster) this.clusterRS.next(this.cluster);
	}

	ngOnDestroy(): void {
		this.ngOnDestroyRS.next();
		this.ngOnDestroyRS.complete();
	}
}

type IPopup = InstanceType<ReturnType<typeof createPopupClass>>;
function createPopupClass(app: ApplicationRef) {
	return class Popup extends google.maps.OverlayView {
		private content = document.createElement("div");
		private view?: EmbeddedViewRef<any>;

		constructor(private tpl: TemplateRef<any>, private pos: google.maps.LatLng) {
			super();
			this.content.classList.add("popup");
			this.content.addEventListener("mouseover", () => (this.content.style.zIndex = "1"));
			this.content.addEventListener("mouseout", () => (this.content.style.zIndex = "0"));
		}

		override onAdd() {
			this.view = this.tpl.createEmbeddedView(null);
			app.attachView(this.view);
			for (const node of this.view.rootNodes) this.content.appendChild(node);

			this.getPanes().floatPane.appendChild(this.content);

			// stop clicks, etc., from bubbling up to the map
			google.maps.OverlayView.preventMapHitsAndGesturesFrom(this.content);
		}

		override onRemove() {
			this.content.parentElement!.removeChild(this.content);
			this.view!.destroy();
		}

		override draw() {
			const posPx = this.getProjection().fromLatLngToDivPixel(this.pos);
			this.content.style.left = `${posPx.x}px`;
			this.content.style.top = `${posPx.y}px`;
			// Hide the popup when it is far out of view.
			this.content.style.display = Math.abs(posPx.x) < 4000 && Math.abs(posPx.y) < 4000 ? "block" : "none";
		}

		getDraggable() {
			return false;
		}

		getPosition() {
			return this.pos;
		}
	};
}
