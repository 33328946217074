import { HttpClient } from "@angular/common/http";
import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { IStmtResults } from "@model/stmt-results";
import { ToastrService } from "ngx-toastr";
import { BehaviorSubject, of } from "rxjs";
import { map, shareReplay, switchMap } from "rxjs/operators";

@Component({
	selector: "cm-design-force-client-contact",
	template: `
		<cm-modal [show]="show" (showChange)="showChange.emit($event)">
			<ng-template cmModal>
				<div class="row">
					<div
						class="h1 col-12 bg-primary text-white text-uppercase flex-center px-sm-5 px-3 pt-5 pb-sm-5 pb-3 mb-0"
					>
						<span *ngIf="contactid">Update Contact</span>
						<span *ngIf="!contactid">Add Contact</span>
					</div>
				</div>
				<div class="row form-group justify-content-center" *ngIf="contactInfo$ | async as contactInfo">
					<div class="col-6 my-2">
						<label>First Name</label>
						<input [(ngModel)]="contactInfo.first_name" class="form-control" />
					</div>
					<div class="col-6 my-2">
						<label>Last Name</label>
						<input [(ngModel)]="contactInfo.last_name" class="form-control" />
					</div>
					<div class="col-6 my-2">
						<label>Email</label>
						<input type="email" [(ngModel)]="contactInfo.contact_email" class="form-control" />
					</div>
					<div class="col-6 my-2">
						<label>Phone</label>
						<input type="email" [(ngModel)]="contactInfo.contact_phone_number" class="form-control" />
					</div>
					<div class="col-6 my-2">
						<label>Phone Number Type</label>
						<select [(ngModel)]="contactInfo.phone_number_typeid" class="form-control">
							<option
								*ngFor="let option of phoneNumberTypes$ | async"
								[value]="option.phone_number_typeid"
							>
								{{ option.name }}
							</option>
						</select>
					</div>
					<div class="col-12 text-center my-2">
						<button class="btn btn-primary" (click)="saveContact(contactInfo)">Save</button>
					</div>
				</div>
			</ng-template>
		</cm-modal>
	`,
	styles: [
		`
			:host {
				display: block;
			}

			:host ::ng-deep input[type="text"] {
				max-width: 100% !important;
			}
		`,
	],
})
export class ClientContactComponent implements OnInit {
	@Input() show: boolean = false;
	@Input() customerid!: number;
	@Input() contactid: null | number = null;
	@Output() showChange = new EventEmitter();

	contactidBS: BehaviorSubject<any> = new BehaviorSubject(null);

	defaultContact$ = of({
		contactid: null,
		first_name: null,
		last_name: null,
		contact_email: null,
		contact_emailid: null,
		contact_phone_number: null,
		contact_phone_numberid: null,
		phone_number_typeid: null,
	});

	phoneNumberTypes$ = this.http
		.post("/api/statement/phone_number_typeid", {})
		.pipe(map((response: IStmtResults<any>) => response.results));

	contactInfo$ = this.contactidBS.pipe(
		switchMap((contactid) => {
			if (contactid === null) {
				return this.defaultContact$;
			} else {
				return this.http
					.post("/api/statement/GetContactInfo", {
						vars: { contactid },
					})
					.pipe(
						map((response: IStmtResults<any>) =>
							response.results && response.results[0]
								? response.results[0]
								: {
										contactid: null,
										first_name: null,
										last_name: null,
										contact_email: null,
										contact_emailid: null,
										contact_phone_number: null,
										contact_phone_numberid: null,
										phone_number_typeid: null,
								  },
						),
						shareReplay(1),
					);
			}
		}),
	);

	constructor(private http: HttpClient, private toastrService: ToastrService) {}

	ngOnInit(): void {
		this.contactidBS.next(this.contactid);
	}

	saveContact(contactInfo: any) {
		this.http.post("/api/contact/processContact", { customerid: this.customerid, ...contactInfo }).subscribe(
			() => this.showChange.emit(false),
			() => this.toastrService.error("There was an error with processing. Please try again"),
		);
	}
}
