import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { ModalFileUploadComponent } from "../admin/components/modal-file-upload/modal-file-upload.component";
import { DropzoneComponent } from "../dropzone/dropzone.component";
import { ImageModule } from "../image/image.module";
import { LoginComponent } from "../login/login.component";
import { ReplaceLineBreaksPipe } from "../shared/pipes/nl2br.pipe";
import { SocialLoginComponent } from "../social-login/social-login.component";
import { BgComponent } from "./components/bg.component";
import { FilezoneComponent } from "./components/filezone.component";
import { LayersComponent } from "./components/layers/layers.component";
import { SkewComponent } from "./components/skew.component";
import { TintComponent } from "./components/tint.component";

@NgModule({
	declarations: [
		BgComponent,
		TintComponent,
		DropzoneComponent,
		ModalFileUploadComponent,
		LoginComponent,
		LayersComponent,
		SocialLoginComponent,
		ReplaceLineBreaksPipe,
		SkewComponent,
		FilezoneComponent,
	],
	imports: [CommonModule, FormsModule, NgbModule, FontAwesomeModule, ImageModule],
	exports: [
		BgComponent,
		TintComponent,
		DropzoneComponent,
		ModalFileUploadComponent,
		LoginComponent,
		SocialLoginComponent,
		ReplaceLineBreaksPipe,
		LayersComponent,
		SkewComponent,
		FilezoneComponent,
	],
})
export class LayoutModule {}
