// TODO: generate from database

export enum TimePunchType {
	ClockIn = 1,
	ClockOut = 2,
	ClockInFromHome = 3,
	ClockOutFromHome = 4,
	TakeBreak = 5,
	ReturnFromBreak = 6,
	TakeLunch = 7,
	ReturnFromLunch = 8,
	RunErrand = 9,
	ReturnFromErrand = 10,
	Vacation = 11,
	Vacation2Hours = 12,
	VacationHalfDay = 13,
	NonpaidVacation = 14,
	NonpaidVacation2Hours = 15,
	NonpaidVacationHalfDay = 16,
	PaidHoliday = 17,
	AutoPunch = 18,
}
