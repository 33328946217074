import { Injectable } from "@angular/core";
import { Observable, of } from "rxjs";
import { switchMap, take, tap } from "rxjs/operators";
import { isPrerendering, PrerenderService } from "shared";

@Injectable({ providedIn: "root" })
export class TransferRxService {
	constructor(private transfer: PrerenderService) {}

	/**
	 * Transfers the last emitted value of an observable from SSR to the client. Mainly useful to transfer the result of
	 * http requests.
	 *
	 * Emits `undefined` if the value doesn't exist.
	 */
	transfer$<T>(key: string, cb: () => Observable<T>): Observable<T> {
		if (isPrerendering()) {
			return cb().pipe(tap((val) => this.transfer.setState(key, val)));
		} else {
			return this.transfer.getState<T>(key).pipe(
				switchMap((x) => (x === undefined ? cb() : of(x))),
				take(1),
			);
		}
	}
}
