import { HttpClient } from "@angular/common/http";
import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from "@angular/core";
import { iter } from "@common/iter";
import { SMap } from "@common/map2";
import { IStmtResults } from "@model/stmt-results";
import { ToastrService } from "ngx-toastr";
import { BehaviorSubject, combineLatest } from "rxjs";
import { map, shareReplay, switchMap } from "rxjs/operators";

@Component({
	selector: "cm-client-edit-store-hours[locationid][org_deptid][customerid]",
	template: `
		<cm-modal [show]="show" (showChange)="showChange.emit($event)">
			<ng-template cmModal>
				<div class="row">
					<div
						class="h1 col-12 bg-primary text-white text-uppercase flex-center px-sm-5 px-3 pt-5 pb-sm-5 pb-3 mb-0"
					>
						Location Hours
					</div>
				</div>
				<form *ngIf="hours$ | async as hours" class="container-fluid py-2 my-2" (ngSubmit)="save(hours)">
					<p><strong>Location Hours:</strong></p>
					<ng-container *ngFor="let row of hours | keyval">
						<div *ngFor="let day of row.val.days" class="w-100 mb-2 d-flex align-items-center">
							<div class="flex-grow-1">{{ day.day }}</div>
							<cm-field-time
								name="{{ row.key }}-{{ day.day }}-from"
								class="ml-3"
								[(value)]="day.from"
							></cm-field-time>
							<cm-field-time
								name="{{ row.key }}-{{ day.day }}-to"
								class="ml-3"
								[(value)]="day.to"
							></cm-field-time>
						</div>
						<hr />
					</ng-container>
					<p><strong>Where to Update:</strong></p>
					<div class="form-check w-100 w-lg-auto">
						<div class="row">
							<ng-container *ngFor="let projectCat of projectCats; index as i">
								<div class="col-12 col-lg-4">
									<input
										class="mr-1"
										type="checkbox"
										id="option{{ i }}"
										name="option{{ i }}"
										[ngModel]="customerProjectCats.has(projectCat.project_catid)"
										(ngModelChange)="updateProjects($event, projectCat.project_catid)"
									/>
									<label for="option{{ i }}">{{ projectCat.project_cat }}</label>
								</div>
							</ng-container>
						</div>
					</div>
					<div class="text-right">
						<button class="btn btn-primary" [disabled]="saving">Submit</button>
					</div>
				</form>
			</ng-template>
		</cm-modal>
	`,
	styles: [
		`
			:host {
				display: block;
			}
		`,
	],
})
export class ClientEditStoreHoursComponent implements OnChanges {
	@Input() locationid!: number;
	@Input() customerid!: number;
	@Input() org_deptid!: number;
	@Input() show: boolean = false;

	@Output() showChange = new EventEmitter();
	@Output() saved = new EventEmitter<void>();

	locationidBS = new BehaviorSubject(0);
	customeridBS = new BehaviorSubject(0);
	org_deptidBS = new BehaviorSubject(0);

	hours$ = combineLatest([this.locationidBS, this.org_deptidBS]).pipe(
		switchMap(([locationid, org_deptid]) =>
			this.http.post("/api/statement/GetLocationOrgDeptHoursNoFormat", { vars: { locationid, org_deptid } }),
		),
		map((res: any) => {
			const days = new Map([
				["Sunday", 0],
				["Monday", 1],
				["Tuesday", 2],
				["Wednesday", 3],
				["Thursday", 4],
				["Friday", 5],
				["Saturday", 6],
			]);

			// dept -> day -> row
			const ret = new SMap<string, any>();

			for (const row of res.results) {
				const model = ret.getOrInsertWith(row.locationid + "|" + row.site_org_deptid, () => ({
					org_dept: row.org_dept,
					location: row.location,
					days: createModel(),
				})).days[days.get(row.day_of_week)!];
				model.from = row.time_from;
				model.to = row.time_to;
			}

			if (ret.entries.length < 1) {
				ret.getOrInsertWith(this.locationid + "|" + 60, () => ({
					org_dept: "",
					location: "",
					days: createModel(),
				}));
			}

			return ret;
		}),
	);

	saving = false;

	projectCats: any[] = [];
	customerProjectCats: Set<number> = new Set();

	constructor(private http: HttpClient, private toastr: ToastrService) {
		this.http
			.post("/api/statement/GetProjectCats", { vars: { project_cat_ofid: 74 } })
			.subscribe((response: IStmtResults<any>) => (this.projectCats = response.results!));
	}

	save(hours: SMap<string, any[]>) {
		this.saving = true;
		this.http
			.post("/api/store-hours", {
				hours: iter(hours).toObject(),
				notifyChanges: true,
				placesToUpdate: Array.from(this.customerProjectCats),
			})
			.subscribe(
				() => {
					this.toastr.success("Saved store hours");
					this.saving = false;
					this.saved.next();
					this.showChange.emit(false);
				},
				() => {
					this.toastr.error("Failed to save store hours");
					this.saving = false;
				},
			);
	}

	updateProjects(enable: boolean, project_catid: number) {
		if (enable) {
			this.customerProjectCats.add(project_catid);
		} else {
			this.customerProjectCats.delete(project_catid);
		}
	}

	ngOnChanges(changes: SimpleChanges): void {
		if (changes.locationid) {
			this.locationidBS.next(changes.locationid.currentValue);
		}
		if (changes.org_deptid) {
			this.org_deptidBS.next(changes.org_deptid.currentValue);
		}
		if (changes.customerid) {
			this.customeridBS.next(changes.customerid.currentValue);
			this.http
				.post("/api/statement/GetCustomerProjects", {
					vars: { customerid: this.customerid, project_cat_ofid: 74, groupByProject: false },
				})
				.subscribe((response: IStmtResults<any>) => {
					this.customerProjectCats = new Set();

					response.results?.forEach((row) => {
						this.customerProjectCats.add(row.project_catid);
					});
				});
		}
	}
}

function createModel() {
	return [
		{ day: "Sunday", from: "", to: "" },
		{ day: "Monday", from: "", to: "" },
		{ day: "Tuesday", from: "", to: "" },
		{ day: "Wednesday", from: "", to: "" },
		{ day: "Thursday", from: "", to: "" },
		{ day: "Friday", from: "", to: "" },
		{ day: "Saturday", from: "", to: "" },
	];
}
