import { Inject, Pipe, PipeTransform } from "@angular/core";
import { Path } from "@common/path";
import { IPageData } from "@model/page-data";

@Pipe({ name: "image" })
export class ImagePipe implements PipeTransform {
	constructor(@Inject("PAGE_DATA") private pageData: IPageData) {}

	// TODO: set default format to "0,0" once it's supported
	transform(value: string, size?: string): string {
		const dashSize = size ? `-${size}` : "";
		const path = new Path(value);
		const ext = path
			.extension()
			.map((ext) => `.${ext}`)
			.unwrapOr("");
		const fileStem = path.fileStem().unwrapOr(null);
		if (fileStem) {
			return `${this.pageData.appInfo.data.cdnRootPath}${path.parent().unwrap()}/${fileStem}${dashSize}${ext}`;
		} else {
			return "";
		}
	}
}
