import { HttpClient } from "@angular/common/http";
import { Component, Input, OnChanges, SimpleChanges } from "@angular/core";
import { iterObj } from "@common/iter";
import { cloneDeep } from "@common/util";
import { ToastrService } from "ngx-toastr";
import { BehaviorSubject, combineLatest, of, ReplaySubject } from "rxjs";
import { map, shareReplay, switchMap, tap } from "rxjs/operators";

@Component({
	selector: "cm-client-edit-affiliates",
	template: `
		<div><strong>Account Access:</strong></div>
		<div class="row">
			<div *ngFor="let service of services$ | async | keyval; index as serviceIdx" class="col-12 col-lg-6 mt-2">
				<div class="form-group form-check">
					<input
						type="checkbox"
						class="form-check-input"
						id="service-{{ serviceIdx }}"
						[(ngModel)]="service.val.value"
						(ngModelChange)="!$event && resetFields(service.key, service.val)"
					/>
					<label class="form-check-label" for="service-{{ serviceIdx }}">{{ service.key }}</label>
				</div>
				<div class="border-left mt-n2 pl-3 pt-2">
					<div
						*ngFor="let field of service.val.fields | keyval; index as fieldIdx"
						[ngSwitch]="field.val.type"
					>
						<div *ngSwitchCase="'bool'" class="form-group form-check">
							<input
								type="checkbox"
								id="service-field-{{ fieldIdx }}"
								class="form-check-input"
								[(ngModel)]="field.val.value"
								(ngModelChange)="$event && (service.val.value = true)"
							/>
							<label class="form-check-label" for="service-field-{{ fieldIdx }}">
								{{ field.key }}
							</label>
						</div>
						<div *ngSwitchCase="'text'" class="form-group d-flex align-items-center">
							<label for="service-field-{{ fieldIdx }}" class="mr-2">{{ field.key }}</label>
							<input
								id="service-field-{{ fieldIdx }}"
								class="form-control-sm w-auto"
								[(ngModel)]="field.val.value"
								(ngModelChange)="$event && (service.val.value = true)"
							/>
						</div>
						<div *ngSwitchCase="'textarea'" class="form-group">
							<label for="service-field-{{ fieldIdx }}">{{ field.key }}</label>
							<textarea
								id="service-field-{{ fieldIdx }}"
								class="form-control"
								[(ngModel)]="field.val.value"
								(ngModelChange)="$event && (service.val.value = true)"
							></textarea>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="text-right">
			<button type="button" class="btn btn-primary" [disabled]="savingBS | async" (click)="save()">Save</button>
		</div>
	`,
	styles: [
		`
			:host {
				display: block;
			}

			.form-group {
				margin-bottom: 0.5rem;
			}
		`,
	],
})
export class ClientEditAffiliatesComponent implements OnChanges {
	@Input() customerid!: number;

	customeridRS = new ReplaySubject(1);

	loaded = false;
	savingBS = new BehaviorSubject(false);

	customerNote$ = this.customeridRS.pipe(
		switchMap((customerid) =>
			this.http.post("/api/statement/GetCustomerNote", { vars: { customerid, note_titleid: 17 } }),
		),
		map((res: any) => res.results[0]),
		shareReplay(1),
	);

	services$ = this.customerNote$.pipe(
		map((row: any) => deepMerge(serviceDefaults, row ? JSON.parse(row.customer_note) : {})),
		shareReplay(1),
	);

	constructor(private http: HttpClient, private toastr: ToastrService) {}

	ngOnChanges(changes: SimpleChanges): void {
		if (changes.customerid) {
			this.customeridRS.next(changes.customerid.currentValue);
		}
	}

	resetFields(key: string, service: any) {
		service.fields = cloneDeep(serviceDefaults[key].fields);
	}

	save() {
		this.savingBS.next(true);
		combineLatest([this.customerNote$, this.services$])
			.pipe(
				switchMap(([customerNote, services]) => {
					const customer_note = JSON.stringify(services);
					if (customer_note) {
						if (customerNote) {
							return this.http.post("/api/statement/UpdateCustomerNote", {
								vars: { customer_noteid: customerNote.customer_noteid, customer_note },
							});
						} else {
							return this.http.post("/api/statement/AddCustomerNote", {
								vars: { customerid: this.customerid, note_titleid: 17, customer_note },
							});
						}
					} else {
						if (customerNote) {
							return this.http.post("/api/statement/DeleteCustomerNote", {
								vars: { customer_noteid: customerNote.customer_noteid },
							});
						} else {
							return of(null);
						}
					}
				}),
			)
			.subscribe(
				() => {
					this.savingBS.next(false);
					this.toastr.success("Saved account access.");
				},
				() => {
					this.savingBS.next(false);
					this.toastr.error("Failed to save account access.");
				},
			);
	}
}

interface ServiceFields {
	[name: string]: {
		value: boolean;
		fields: {
			[name: string]: {
				type: "bool" | "text" | "textarea";
				value?: any;
			};
		};
	};
}

const serviceDefaults: ServiceFields = {
	Facebook: {
		value: false,
		fields: {
			"Agency Request Accepted": { type: "bool", value: false },
			"Profile Created by DFM": { type: "bool", value: false },
		},
	},
	Instagram: {
		value: false,
		fields: {
			"Agency Request Accepted": { type: "bool", value: false },
			"Profile Created by DFM": { type: "bool", value: false },
		},
	},
	"Trip Advisor": {
		value: false,
		fields: { "Profile Created by DFM": { type: "bool", value: false } },
	},
	Yelp: {
		value: false,
		fields: { "Profile Created by DFM": { type: "bool", value: false } },
	},
	"Google My Business": {
		value: false,
		fields: { "Profile Created by DFM": { type: "bool", value: false } },
	},
	Bing: {
		value: false,
		fields: { "Profile Created by DFM": { type: "bool", value: false } },
	},
	"Payment Processor": {
		value: false,
		fields: { Company: { type: "text", value: "" }, "Profile Created by DFM": { type: "bool", value: false } },
	},
	POS: {
		value: false,
		fields: { Company: { type: "text", value: "" } },
	},
	Shipping: {
		value: false,
		fields: {
			Company: { type: "text", value: "" },
			"Delivery Offered": { type: "text", value: "" },
			"Pickup Offered": { type: "text", value: "" },
		},
	},
	Quickbooks: {
		value: false,
		fields: {
			"What we are integrating": { type: "textarea", value: "" },
		},
	},
};

function deepMerge(lhs: any, rhs: any) {
	const ret = cloneDeep(lhs);
	for (const [key, val] of iterObj(rhs)) {
		if (typeof lhs[key] === "object") {
			ret[key] = deepMerge(lhs[key], val);
		} else {
			ret[key] = val;
		}
	}
	return ret;
}
