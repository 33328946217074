import { CommonModule, Location } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";
import { CampaignPickerComponent } from "@core/app/adwords/components/campaign-picker/campaign-picker.component";
import { AutocompleteComponent } from "@core/app/autocomplete/autocomplete.component";
import { BootstrapModule } from "@core/app/bootstrap/bootstrap.module";
import { ButtonModule } from "@core/app/button/button.module";
import { ClickCopyDirective } from "@core/app/click-copy.directive";
import { FaqModule } from "@core/app/faq/faq.module";
import { GroupByPipe } from "@core/app/group-by.pipe";
import { ImageModule } from "@core/app/image/image.module";
import { LayoutModule } from "@core/app/layout/layout.module";
import { LazyModule } from "@core/app/lazy/lazy.module";
import { SearchModule } from "@core/app/search/search.module";
import { SocialLinks2Component } from "@core/app/shared/components/social-links2/social-links2.component";
import { TableFiltersComponent } from "@core/app/shared/components/table-filters/table-filters.component";
import { TableSelectsComponent } from "@core/app/shared/components/table-selects/table-selects.component";
import { TableComponent } from "@core/app/shared/components/table/table.component";
import { SliderModule } from "@core/app/slider/slider.module";
import { SlugifyPipe } from "@core/app/slugify.pipe";
import { TimeClockComponent } from "@core/app/time-clock/time-clock.component";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { FlatpickrModule } from "angularx-flatpickr";
import { DesignForceModule } from "../design-force/design-force.module";
import { DropzoneComponent } from "../dropzone/dropzone.component";
import { FormModule } from "../form/form.module";
import { UtilModule } from "../util/util.module";
import { NumFormatPipe } from "@common/pipes/numFormat";
import { CrmEmailModule } from "../crm/components/email/email.module";
import { PhotoHeaderButtonComponent } from "../photo-header-button/photo-header-button.component";
import { IndustryNavWrapperComponent } from "../industry-nav-wrapper/industry-nav-wrapper.component";
import { ConsultationFormComponent } from "@core/app/consultation-form/consultation-form.component";
import { HomePageComponent } from "../home-page/home-page.component";
import { SliderLogoComponent } from "../slider-logo/slider-logo.component";
import { SliderStatsComponent } from "../slider-stats/slider-stats.component";
import { SliderSocialAdsComponent } from "../slider-social-ads/slider-social-ads.component";
import { OurReviewsComponent } from "../our-reviews/our-reviews.component";
import { VideoSliderComponent } from "../video-slider/video-slider.component";
import { SocialMediaSliderComponent } from "../social-media-slider/social-media-slider.component";
import { WebsiteSliderComponent } from "../website-slider/website-slider.component";
import { ExploreOurServicesComponent } from "../explore-our-services/explore-our-services.component";
import { StarListingComponent } from "../star-listing/star-listing.component";
import { CreativePromotionsComponent } from "../our-industries-pages/industry-page-block/commonDetails/creative-promotions/creative-promotions.component";
import { CustomPowerfulCrmComponent } from "../our-industries-pages/industry-page-block/commonDetails/custom-powerful-crm/custom-powerful-crm.component";
import { DetailedReportingComponent } from "../our-industries-pages/industry-page-block/commonDetails/detailed-reporting/detailed-reporting.component";
import { MoreSalesComponent } from "../our-industries-pages/industry-page-block/commonDetails/more-sales/more-sales.component";
import { OnlineInventoryListingComponent } from "../our-industries-pages/industry-page-block/commonDetails/online-inventory-listing/online-inventory-listing.component";
import { PowerfulTargetingComponent } from "../our-industries-pages/industry-page-block/commonDetails/powerful-targeting/powerful-targeting.component";
import { SalesToolComponent } from "../our-industries-pages/industry-page-block/commonDetails/sales-tool/sales-tool.component";
import { SocialMediaComponent } from "../our-industries-pages/industry-page-block/commonDetails/social-media/social-media.component";
import { CommonSupportTeamComponent } from "../our-industries-pages/common-support-team/common-support-team.component";
import { ExploreOtherServicesIndustriesComponent } from "../our-industries-pages/industry-page-block/commonDetails/explore-other-services-industries/explore-other-services-industries.component";
import { IndustryNavWrapperShortComponent } from "../industry-nav-wrapper-short/industry-nav-wrapper-short.component";
import { CommonOurIndustryHeaderComponent } from "../services-page/details/common-our-industry-header/common-our-industry-header.component";
import { BuildSheetsModule } from "../build-sheets/build-sheets.module";

@NgModule({
	imports: [
		BootstrapModule,
		CommonModule,
		FontAwesomeModule,
		FormsModule,
		NgbModule,
		FlatpickrModule.forRoot(),
		RouterModule,
		LazyModule,
		FaqModule,
		RouterModule,
		ImageModule,
		ButtonModule,
		SearchModule,
		SliderModule,
		LayoutModule,
		ButtonModule,
		DesignForceModule,
		UtilModule,
		FormModule,
		CrmEmailModule,
	],
	declarations: [
		AutocompleteComponent,
		ClickCopyDirective,
		GroupByPipe,
		SlugifyPipe,
		TimeClockComponent,
		TableComponent,
		TableFiltersComponent,
		TableSelectsComponent,
		SocialLinks2Component,
		CampaignPickerComponent,
		NumFormatPipe,
		PhotoHeaderButtonComponent,
		IndustryNavWrapperComponent,
		IndustryNavWrapperShortComponent,
		ConsultationFormComponent,
		HomePageComponent,
		SliderLogoComponent,
		SliderStatsComponent,
		SliderSocialAdsComponent,
		OurReviewsComponent,
		VideoSliderComponent,
		WebsiteSliderComponent,
		SocialMediaSliderComponent,
		ExploreOurServicesComponent,
		StarListingComponent,
		CreativePromotionsComponent,
		CustomPowerfulCrmComponent,
		DetailedReportingComponent,
		MoreSalesComponent,
		OnlineInventoryListingComponent,
		PowerfulTargetingComponent,
		SalesToolComponent,
		SocialMediaComponent,
		CommonSupportTeamComponent,
		ExploreOtherServicesIndustriesComponent,
		CommonOurIndustryHeaderComponent,
	],
	exports: [
		AutocompleteComponent,
		BootstrapModule,
		ClickCopyDirective,
		CommonModule,
		FontAwesomeModule,
		FormsModule,
		GroupByPipe,
		NgbModule,
		RouterModule,
		SlugifyPipe,
		TimeClockComponent,
		FlatpickrModule,
		LazyModule,
		FaqModule,
		TableComponent,
		TableFiltersComponent,
		TableSelectsComponent,
		ImageModule,
		SocialLinks2Component,
		SearchModule,
		SliderModule,
		LayoutModule,
		CampaignPickerComponent,
		ButtonModule,
		DesignForceModule,
		FormModule,
		NumFormatPipe,
		CrmEmailModule,
		PhotoHeaderButtonComponent,
		IndustryNavWrapperComponent,
		IndustryNavWrapperShortComponent,
		ConsultationFormComponent,
		HomePageComponent,
		SliderLogoComponent,
		SliderStatsComponent,
		SliderSocialAdsComponent,
		OurReviewsComponent,
		VideoSliderComponent,
		WebsiteSliderComponent,
		SocialMediaSliderComponent,
		ExploreOurServicesComponent,
		StarListingComponent,
		CreativePromotionsComponent,
		CustomPowerfulCrmComponent,
		DetailedReportingComponent,
		MoreSalesComponent,
		OnlineInventoryListingComponent,
		PowerfulTargetingComponent,
		SalesToolComponent,
		SocialMediaComponent,
		CommonSupportTeamComponent,
		ExploreOtherServicesIndustriesComponent,
		CommonOurIndustryHeaderComponent,
	],
	providers: [Location],
})
export class SharedModule {}
