import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { CrmCardHeaderComponent } from "./components/card-header.component";
import { ImageModule } from "@core/app/image/image.module";
import { SharedModule } from "@core/app/shared/shared.module";
import { CrmNotesModalComponent } from "./components/notes-modal.component";
import { ModalModule } from "shared";
import { CrmReassignModalComponent } from "./components/reassign-modal.component";
import { FormModule } from "@core/app/form/form.module";
import { FormsModule } from "@angular/forms";

@NgModule({
	declarations: [CrmCardHeaderComponent, CrmNotesModalComponent, CrmReassignModalComponent],
	imports: [CommonModule, ImageModule, NgbModule, FontAwesomeModule, FormModule, ModalModule, FormsModule],
	exports: [CrmCardHeaderComponent, CrmNotesModalComponent, CrmReassignModalComponent],
})
export class CrmSharedModule {}
