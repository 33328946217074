import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { BootstrapModule } from "@core/app/bootstrap/bootstrap.module";
import { FaqComponent } from "./components/faq/faq.component";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";

@NgModule({
	declarations: [FaqComponent],
	imports: [CommonModule, BootstrapModule, FontAwesomeModule],
	exports: [FaqComponent],
})
export class FaqModule {}
