import { Component, HostListener, Output, EventEmitter, HostBinding, Input } from "@angular/core";
import { iterFiles } from "shared/common";

@Component({
	selector: "cm-dropzone",
	template: `<ng-content></ng-content>`,
	styles: [
		`
			:host {
				display: flex;
				justify-content: center;
				align-items: center;

				background-color: #eee;
				border: 2px dashed #aaa;
				color: #666;
				min-height: 4.4em;
				padding: 0.5em 1em;
				text-align: center;
			}
			:host.dragging {
				border: 2px solid #aaa;
			}
		`,
	],
})
export class DropzoneComponent {
	@Input() multiple = false;

	@Output() filesDropped = new EventEmitter<File[] | null>();

	@HostBinding("class.dragging") dragging: boolean = false;
	@HostBinding("attr.role") role: string = "button";

	@HostListener("dragover", ["$event"])
	onDragover(event: Event) {
		event.preventDefault();

		this.dragging = true;
	}

	@HostListener("dragend")
	@HostListener("dragleave")
	onDragend() {
		this.dragging = false;
	}

	@HostListener("click")
	click() {
		const input = document.createElement("input");
		input.type = "file";
		input.multiple = this.multiple;
		input.onchange = () => this.filesDropped.emit(iterFiles(input.files!).toArray());
		input.click();
	}

	@HostListener("drop", ["$event"])
	onDrop(event: any) {
		event.preventDefault();

		this.dragging = false;

		let files;

		if (event.dataTransfer!.items) {
			// Use DataTransferItemList interface to access the file(s)

			files = [];

			// tslint:disable-next-line:prefer-for-of
			for (let i = 0; i < event.dataTransfer!.items.length; i++) {
				// If dropped items aren't files, reject them
				if (event.dataTransfer!.items[i].kind === "file") {
					files.push(event.dataTransfer!.items[i].getAsFile()!);
				}
			}
		} else {
			// Use DataTransfer interface to access the file(s)

			files = iterFiles(event.dataTransfer!.files).toArray();
		}

		this.filesDropped.emit(files);
	}
}
