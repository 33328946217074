import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Iter, iter, tuple } from "@common/iter";
import { some } from "@common/option";
import { cloneDeep, round } from "@common/util";
import { TimePunchType } from "@model/enums/time-punch-type";
import { IStmtResults } from "@model/stmt-results";
import { IGetTimePunchTypes } from "@model/stmt/GetTimePunchTypes";
import { differenceInMilliseconds, parse } from "date-fns";
import { map, shareReplay } from "rxjs/operators";

@Injectable({ providedIn: "root" })
export class TimeClockService {
	maxDailyPaidBreak$ = this.http.get("/api/admin/setting/find/MaxDailyBreakTime").pipe(
		map((res: any) => Number(res.admin_setting)),
		shareReplay(),
	);

	punchTypes$ = this.http.post("/api/statement/GetTimePunchTypes", {}).pipe(
		map((res) =>
			iter((res as IStmtResults<IGetTimePunchTypes>).results!)
				.map((row) =>
					tuple(Number(row.time_punch_typeid), {
						name: row.time_punch_type,
						status: row.status,
						from: row.from_time_punch_typeids
							? row.from_time_punch_typeids.split(",").map((id) => Number(id))
							: [],
						color: row.lbl_color && `#${row.lbl_color}`,
					}),
				)
				.toMap(),
		),
		shareReplay(),
	);

	constructor(private http: HttpClient) {}

	buildPunchObj(punch: any, previousTime: Date | null, currentTime: Date, remBreak: number): [any, number] {
		let timeDiff: number = 0;
		let workTime: number = 0;
		let breakTime: number = 0;

		if (previousTime) {
			timeDiff = differenceInMilliseconds(currentTime, previousTime) / (1000 * 60 * 60);
			timeDiff = round(timeDiff, 2);
		}

		const punchType = punch.time_punch_typeid;
		if (punchType === TimePunchType.ReturnFromBreak) {
			const paidBreak = Math.min(remBreak, timeDiff);
			workTime = paidBreak;
			breakTime = timeDiff - paidBreak;
			remBreak -= paidBreak;
		} else if (punchType === TimePunchType.ReturnFromLunch) {
			breakTime = timeDiff;
		} else if (punchType !== TimePunchType.ClockIn) {
			workTime = timeDiff;
		}

		const item = { ...punch, workTime, breakTime, punchType: punch.time_punch_type };
		item.change = { original: cloneDeep(item), change: null };
		return [item, remBreak];
	}

	buildDayObj(date: string, punches: Iter<any>, maxDailyPaidBreak: number) {
		const items = punches
			.scan(tuple(null as Date | null, maxDailyPaidBreak), ([previousTime, remBreak], punch) => {
				const currentTime = parse(punch.punch_at);

				let item;
				[item, remBreak] = this.buildPunchObj(punch, previousTime, currentTime, remBreak);

				return [tuple(currentTime, remBreak), some(item)];
			})
			.toArray();

		const dayTotal = iter(items)
			.map((x) => x.workTime)
			.sum();

		return { date, items: { items, dayTotal } };
	}
}
