import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { FlatpickrModule } from "angularx-flatpickr";
import { FormUtilModule, ModalModule } from "shared";
import { CalendarModule } from "../calendar/calendar.module";
import { CrmEmailModule } from "../crm/components/email/email.module";
import { FormModule } from "../form/form.module";
import { ImageModule } from "../image/image.module";
import { LayoutModule } from "../layout/layout.module";
import { NgModelChangeDebouncedDirective } from "../ng-model-debounce.directive";
import { UtilModule } from "../util/util.module";
import { EditAutocompleteComponent } from "./components/autocomplete/autocomplete.component";
import { BoostingComponent } from "./components/boosting/boosting.component";
import { PostTypeAssignComponent } from "./components/boosting/post-type-assign.component";
import { ClientAddComponent } from "./components/client-add/client-add.component";
import { ClientApprovalComponent } from "./components/client-approval/client-approval.component";
import { ClientBoardComponent } from "./components/client-board/client-board.component";
import { ClientContactComponent } from "./components/client-contact/client-contact.component";
import { ClientEditAffiliatesComponent } from "./components/client-edit-affiliates/client-edit-affiliates.component";
import { ClientEditContractOptionsComponent } from "./components/client-edit-contract-options/client-edit-contract-options.component";
import { ClientEditContractComponent } from "./components/client-edit-contract/client-edit-contract.component";
import { ClientEditDomainInfoComponent } from "./components/client-edit-domain-info/client-edit-domain-info.component";
import { ClientEditDomainComponent } from "./components/client-edit-domain/client-edit-domain.component";
import { ClientEditHolidayHoursComponent } from "./components/client-edit-holiday-hours/client-edit-holiday-hours.component";
import { ClientEditPostCountComponent } from "./components/client-edit-post-count/client-edit-post-count.component";
import { ClientEditPromotionsComponent } from "./components/client-edit-promotions/client-edit-promotions.component";
import { ClientEditStoreHourLocationsComponent } from "./components/client-edit-store-hours-locations/client-edit-store-hours-locations.component";
import { ClientEditStoreHoursComponent } from "./components/client-edit-store-hours/client-edit-store-hours.component";
import { ClientItemApprovalComponent } from "./components/client-item-approval/client-item-approval.component";
import { ClientItemsComponent } from "./components/client-items/client-items.component";
import { ClientOnBoardingComponent } from "./components/client-onboarding/client-onboarding.component";
import { ClientPostsComponent } from "./components/client-posts/client-posts.component";
import { ClientReviewApprovalComponent } from "./components/client-review-approval/client-review-approval.component";
import { ClientReviewPostsComponent } from "./components/client-review-posts/client-review-posts.component";
import { ClientUpdateComponent } from "./components/client-update/client-update.component";
import { CustomerAffiliateComponent } from "./components/customer-affiliate/customer-affiliate.component";
import { CustomerApprovedBoardComponent } from "./components/customer-approved-board/customer-approved-board.component";
import { CustomerColorsComponent } from "./components/customer-color/customer-color.component";
import { CustomerFontComponent } from "./components/customer-font/customer-font.component";
import { CustomerHashtagComponent } from "./components/customer-hashtag/customer-hashtag.component";
import { CustomerInfoBasicComponent } from "./components/customer-info-basic/customer-info-basic.component";
import { CustomerInfoComponent } from "./components/customer-info/customer-info.component";
import { CustomerLogoComponent } from "./components/customer-logo/customer-logo.component";
import { CustomerMoodBoardComponent } from "./components/customer-mood-board/customer-mood-board.component";
import { CustomerProfileAdminComponent } from "./components/customer-profile-admin/customer-profile-admin.component";
import { CustomerReqComponent } from "./components/customer-req/customer-req.component";
import { OnBoardingTableComponent } from "./components/onboarding-table/onboarding-table.component";
import { PostAddComponent } from "./components/post-add/post-add.component";
import { PostQueueComponent } from "./components/post-queue/post-queue.component";
import { ProjectAssetsComponent } from "./components/project-assets/project-assets.component";
import { ProjectNotesComponent } from "./components/project-notes/project-notes.component";
import { RejectionReportComponent } from "./components/rejection-report/rejection-report.component";
import { SocialPostActionComponent } from "./components/social-post-action/social-post-action.component";
import { SocialPostBoardComponent } from "./components/social-post-board/social-post-board.component";
import { SocialPostCalendarComponent } from "./components/social-post-calendar/social-post-calendar.component";
import { SocialPostChangesComponent } from "./components/social-post-changes/social-post-changes.component";
import { SocialPostDateTimeComponent } from "./components/social-post-datetime/social-post-datetime.component";
import { SocialPostOrderComponent } from "./components/social-post-order/social-post-order.component";
import { SocialPostWeekReviewComponent } from "./components/social-post-week-review/social-post-week-review.component";
import { SocialPostBoardLegendComponent } from "./components/social-post/components/board-legend.component";
import { SocialPostMainScreenComponent } from "./components/social-post/components/main-screen.component";
import { VideoLibraryComponent } from "./components/video-library/video-library.component";
import { SocialPostBoardComponent as newBoardComponent } from "./components/social-post/components/board.component";
import { SocialPostBoardCustomerComponent } from "./components/social-post/components/board-customer.component";
import { ClientEditAIComponent } from "./components/client-edit-ai/client-edit-ai.component";
import { SocialPostReviewComponent } from "./components/social-post/components/social-post-review/social-post-review.component";
import { DesignForceStateComponent } from "./components/social-post/components/state.component";

@NgModule({
	declarations: [
		ClientBoardComponent,
		SocialPostBoardComponent,
		SocialPostOrderComponent,
		SocialPostActionComponent,
		CustomerReqComponent,
		CustomerColorsComponent,
		CustomerLogoComponent,
		CustomerFontComponent,
		CustomerMoodBoardComponent,
		ProjectAssetsComponent,
		ProjectNotesComponent,
		ClientApprovalComponent,
		ClientPostsComponent,
		CustomerApprovedBoardComponent,
		ClientReviewApprovalComponent,
		ClientReviewPostsComponent,
		SocialPostWeekReviewComponent,
		SocialPostCalendarComponent,
		ClientAddComponent,
		ClientUpdateComponent,
		PostAddComponent,
		CustomerInfoComponent,
		CustomerAffiliateComponent,
		PostQueueComponent,
		ClientItemApprovalComponent,
		ClientItemsComponent,
		SocialPostDateTimeComponent,
		RejectionReportComponent,
		VideoLibraryComponent,
		ClientContactComponent,
		CustomerProfileAdminComponent,
		ClientEditStoreHoursComponent,
		ClientEditHolidayHoursComponent,
		ClientEditStoreHourLocationsComponent,
		ClientEditContractOptionsComponent,
		CustomerInfoBasicComponent,
		ClientEditContractComponent,
		NgModelChangeDebouncedDirective,
		ClientEditPostCountComponent,
		ClientEditPromotionsComponent,
		ClientEditAffiliatesComponent,
		ClientEditDomainInfoComponent,
		ClientEditDomainComponent,
		ClientOnBoardingComponent,
		OnBoardingTableComponent,
		CustomerHashtagComponent,
		SocialPostChangesComponent,
		EditAutocompleteComponent,
		BoostingComponent,
		PostTypeAssignComponent,
		SocialPostMainScreenComponent,
		SocialPostBoardLegendComponent,
		newBoardComponent,
		SocialPostBoardCustomerComponent,
		ClientEditAIComponent,
		SocialPostReviewComponent,
		DesignForceStateComponent,
	],

	imports: [
		CommonModule,
		FontAwesomeModule,
		LayoutModule,
		ImageModule,
		FormsModule,
		ReactiveFormsModule,
		FormModule,
		NgbModule,
		CalendarModule,
		FlatpickrModule.forRoot(),
		UtilModule,
		CrmEmailModule,
		FormUtilModule,
		ModalModule,
		CrmEmailModule,
	],
	exports: [
		ClientBoardComponent,
		CustomerMoodBoardComponent,
		SocialPostBoardComponent,
		SocialPostOrderComponent,
		ClientApprovalComponent,
		ClientReviewPostsComponent,
		CustomerApprovedBoardComponent,
		SocialPostActionComponent,
		ClientReviewApprovalComponent,
		ClientItemApprovalComponent,
		CustomerInfoComponent,
		CustomerProfileAdminComponent,
		OnBoardingTableComponent,
		CustomerHashtagComponent,
		CustomerLogoComponent,
		SocialPostMainScreenComponent,
		SocialPostReviewComponent,
		NgModelChangeDebouncedDirective,
	],
})
export class DesignForceModule {}
