// dependencies
import { HttpClientModule } from "@angular/common/http";
import { ModuleWithProviders, NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { RouterModule } from "@angular/router";
import { ApolloClientOptions, InMemoryCache, split } from "@apollo/client/core";
import { WebSocketLink } from "@apollo/client/link/ws";
import { getMainDefinition } from "@apollo/client/utilities";
import { SharedModule } from "@core/app/shared/shared.module";
import { config } from "@fortawesome/fontawesome-svg-core";
import { NgbModal, NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { AngularDraggableModule } from "angular2-draggable";
import { APOLLO_NAMED_OPTIONS, APOLLO_OPTIONS, NamedOptions } from "apollo-angular";
import { HttpLink } from "apollo-angular/http";
import { createUploadLink } from "apollo-upload-client";
import { Ng2UiAuthModule, StorageType } from "ng2-ui-auth";
import { CalculatorComponent } from "./calculator/calculator.component";
import { EqualValidatorDirective } from "./equal-validator.directive";
import { FooterDesignForceComponent } from "./footer/components/footer-design-force/footer-design-force.component";
// our code
import { HeaderDesignForceComponent } from "./header-meta/header-design-force/header-design-force.component";
import { ImageModule } from "./image/image.module";
import { RestService } from "./rest.service";
import { RouteResolver } from "./route-resolver.service";
import { SliderModule } from "./slider/slider.module";
import { UserWidgetComponent } from "./user-widget/user-widget.component";

const auth = {
	storageType: StorageType.LOCAL_STORAGE,
};

@NgModule({
	declarations: [
		CalculatorComponent,
		EqualValidatorDirective,
		UserWidgetComponent,
		FooterDesignForceComponent,
		HeaderDesignForceComponent,
	],
	imports: [
		AngularDraggableModule,
		BrowserModule,
		HttpClientModule,
		NgbModule,
		Ng2UiAuthModule.forRoot(auth),
		RouterModule.forChild([]),
		SharedModule,
		SliderModule,
		ImageModule,
		BrowserAnimationsModule,
	],
	exports: [
		EqualValidatorDirective,
		NgbModule,
		SharedModule,
		UserWidgetComponent,
		SliderModule,
		ImageModule,
		FooterDesignForceComponent,
		HeaderDesignForceComponent,
	],
	providers: [
		RestService,
		NgbModal,
		{
			provide: APOLLO_OPTIONS,
			useFactory: (httpLink: HttpLink): ApolloClientOptions<any> => {
				// const http = httpLink.create({ uri: "/graphql-node" });
				const http = createUploadLink({ uri: "/graphql-node" });

				const ws = new WebSocketLink({
					uri: `wss://${location.host}/graphql-node`,
					options: { reconnect: true, lazy: true },
				});

				const link = split(
					// split based on operation type
					({ query }) => {
						const def = getMainDefinition(query);
						return def.kind === "OperationDefinition" && def.operation === "subscription";
					},
					ws,
					http,
				);

				return {
					cache: new InMemoryCache(),
					link,
					defaultOptions: { query: { fetchPolicy: "no-cache" }, watchQuery: { fetchPolicy: "no-cache" } },
				};
			},
			deps: [HttpLink],
		},
	],
})
export class CoreModule {
	static forRoot(): ModuleWithProviders<CoreModule> {
		return { ngModule: CoreModule, providers: [RouteResolver] };
	}
	constructor() {
		config.autoAddCss = false;
	}
}
