import { HttpClient } from "@angular/common/http";
import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from "@angular/core";
import { iterObj } from "@common/iter";
import { SMap } from "@common/map2";
import { cloneDeep } from "@common/util";
import { faPencil, faPlusCircle } from "@fortawesome/pro-solid-svg-icons";
import { IStmtResults } from "@model/stmt-results";
import { BehaviorSubject, combineLatest, Observable } from "rxjs";
import { map, shareReplay, switchMap, tap } from "rxjs/operators";

@Component({
	selector: "cm-design-force-customer-info",
	template: `
		<ng-container *ngIf="info$ | async as info">
			<div class="container-fluid py-1">
				<div class="row">
					<div class="col-2">
						<img *ngIf="logo$ | async as logo" [src]="logo.logo | image : 't'" class="img-fluid my-1" />
						<button class="btn btn-sm btn-info m-1" *ngIf="!nested" (click)="showProfileAdmin = true">
							Master Admin
						</button>
						<button class="btn btn-sm btn-danger m-1" *ngIf="nested" (click)="hideCustomer()">
							Hide Customer
						</button>
					</div>
					<div class="col-10 text-left">
						<div><strong class="mr-1">Client Name:</strong>{{ info.company_org }}</div>
						<div>
							<strong class="mr-1">Contacts</strong
							><fa-icon
								[icon]="faPlusCircle"
								(click)="currentContactId = null; showContact = true"
							></fa-icon>
							<div class="row" *ngFor="let c of contacts$ | async">
								<div class="col-4">
									<strong class="mr-1">Name:</strong>{{ c.first_name }} {{ c.last_name }}
									<fa-icon
										class="mx-1"
										[icon]="faPencil"
										(click)="currentContactId = c.contactid; showContact = true"
									></fa-icon>
								</div>
								<div class="col-4">
									<strong class="mr-1">Phone:</strong>{{ c.contact_phone_number }}
								</div>
								<div class="col-4"><strong class="mr-1">Email:</strong>{{ c.contact_email }}</div>
							</div>
							<ng-container *ngIf="(contacts$ | async)?.length < 1">
								<div class="row" *ngFor="let e of emails$ | async">
									<div class="col-12"><strong class="mr-1">Email:</strong>{{ e.customer_email }}</div>
								</div>
							</ng-container>
						</div>
					</div>
				</div>
				<div class="row mb-2">
					<div class="col-5">
						<strong class="mr-1">Company #:</strong>
						<div *ngFor="let l of locations$ | async">{{ l.org_phone_number }} ({{ l.location }})</div>
						<ng-container *ngIf="customerProjectsContract$ | async as contact">
							<br /><strong class="mr-1">Contract Type: </strong
							><fa-icon [icon]="faPencil" class="mx-1" (click)="showContract = true"></fa-icon>
							<ng-container *ngFor="let projectCat of contractProjectCats">
								<div *ngIf="(customerProjectsContract$ | async)?.has(projectCat.project_catid)">
									<label>{{ projectCat.project_cat }}</label>
								</div>
							</ng-container>
							<ng-container *ngIf="contact.entries().next().value as c">
								<label for="starts">Starts: </label>
								{{ c[1].start_date | date : "shortDate" }}<br />
								<label for="ends">Ends: </label>
								{{ c[1].end_date | date : "shortDate" }}<br />
							</ng-container>
						</ng-container>
					</div>
					<div class="col-7">
						<div class="text-left">
							<strong class="mr-1">Website:</strong
							><a href="{{ info.primary_url }}">{{ info.primary_url }}</a>
						</div>
						<div class="row">
							<div class="col-12 col-lg-6 px-1" *ngFor="let l of locations$ | async">
								<strong>{{ l.location }} Hours</strong>
								<fa-icon
									class="mx-1"
									[icon]="faPencil"
									(click)="currentLocationId = l.locationid; showHours = true"
								></fa-icon
								><br />
								<div *ngFor="let hourType of l.hours$ | async" class="hours-wrapper">
									<div
										*ngFor="let hourLine of hourType[1] | keyval"
										class="hour d-flex align-items-center"
									>
										<span class="col-4 day-of-week pr-1 pl-0">{{ hourLine.key }}</span>
										<span class="col-8 day-hours pr-0 pl-1">{{ hourLine.val }}</span>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<ul ngbNav #nav="ngbNav" class="nav-tabs" [(activeId)]="activeId">
					<li ngbNavItem="HolidayLocations">
						<a ngbNavLink>Holidays/Hour Locations</a>
						<ng-template ngbNavContent>
							<div class="container-fluid py-1">
								<div class="row">
									<div class="col-12 col-lg-6">
										<p>
											<strong>Holiday Hours:</strong>
											<fa-icon
												[icon]="faPencil"
												class="mx-1"
												(click)="showHoliday = true"
											></fa-icon>
										</p>
										<div *ngIf="holidays$ | async as holidays" class="container-fluid my-2">
											<ng-container *ngFor="let row of holidays | keyval">
												<div
													*ngFor="let holiday of row.val.holidays; index as i"
													class="w-100 mb-2 d-flex align-items-center"
												>
													<div class="col-6">{{ holiday.holiday }}</div>
													<div class="col-6">{{ holiday.notes }}</div>
												</div>
											</ng-container>
										</div>
									</div>
									<div class="col-12 col-lg-6">
										<p>
											<strong>Hours Locations:</strong>
											<fa-icon
												[icon]="faPencil"
												class="mx-1"
												(click)="showSites = true"
											></fa-icon>
										</p>
										<div class="row">
											<ng-container *ngFor="let projectCat of projectCats">
												<div
													class="col-12 col-lg-6"
													*ngIf="
														(customerProjectsSites$ | async)?.has(projectCat.project_catid)
													"
												>
													{{ projectCat.project_cat }}
												</div>
											</ng-container>
										</div>
										<div *ngIf="customerNote$ | async as customerNote">
											<label for="banner" class="mr-1 font-weight-bold"
												>Current Website Banner:
												<fa-icon
													[icon]="faPencil"
													class="mx-1"
													(click)="showSites = true"
												></fa-icon
											></label>
											{{ customerNote.customer_note }}
										</div>
									</div>
								</div>
							</div>
						</ng-template>
					</li>
					<li ngbNavItem="ContractIncludes">
						<a ngbNavLink>Contract Includes</a>
						<ng-template ngbNavContent>
							<div class="container-fluid py-1">
								<div class="row">
									<div class="col-12 col-lg-6">
										<strong>Includes:</strong>
										<fa-icon [icon]="faPencil" class="mx-1" (click)="showIncludes = true"></fa-icon>
									</div>
									<div class="col-12">
										<div class="row">
											<ng-container *ngFor="let projectCat of includesProjectCats">
												<div
													class="col-12 col-lg-6"
													*ngIf="
														(customerProjectsIncludes$ | async)?.has(
															projectCat.project_catid
														)
													"
												>
													<label>{{ projectCat.project_cat }}</label>
												</div>
											</ng-container>
										</div>
									</div>
								</div>
							</div>
						</ng-template>
					</li>
					<li ngbNavItem="PostsPromotions">
						<a ngbNavLink>Posts/Promotions</a>
						<ng-template ngbNavContent>
							<div class="container-fluid py-1">
								<div class="row">
									<div class="col-12 col-lg-6">
										<p>
											<strong>Social Posts:</strong>
											<fa-icon
												[icon]="faPencil"
												class="mx-1"
												(click)="showSocial = true"
											></fa-icon>
										</p>
										<div *ngIf="premiumNote$ | async as premiumNote">
											{{ premiumNote.customer_note }}
											<label for="premium-posts">Premium Posts/mo</label>
										</div>
										<div *ngIf="regularNote$ | async as regularNote">
											{{ regularNote.customer_note }}
											<label for="regular-posts">Regular Posts/mo</label>
										</div>
									</div>
									<div class="col-12 col-lg-6">
										<p>
											<strong>Promotions:</strong>
											<fa-icon
												[icon]="faPencil"
												class="mx-1"
												(click)="showPromotion = true"
											></fa-icon>
										</p>
										<div *ngIf="promotionNote$ | async as promotionNote">
											{{ promotionNote.customer_note }}
											<label for="promotionNote">Promotions/year</label>
										</div>
										<ng-container *ngFor="let promotion of promotions$ | async; index as i">
											<div class="row align-items-center">
												<div class="col-12 my-1">
													<label for="event{{ i }}">Event: </label> {{ promotion.promotion }}
												</div>
												<div class="col-12 col-lg-6 my-1">
													<label for="start">Start:</label>
													{{ promotion.promotion_start | date : "shortDate" }}
												</div>
												<div class="col-12 col-lg-6 my-1">
													<label for="end">End:</label>
													{{ promotion.promotion_end | date : "shortDate" }}
												</div>
												<hr class="col-11 border-dark" />
											</div>
										</ng-container>
									</div>
								</div>
							</div>
						</ng-template>
					</li>
					<li ngbNavItem="AI">
						<a ngbNavLink>AI</a>
						<ng-template ngbNavContent>
							<div class="container-fluid py-1">
								<div class="row">
									<div class="col-12">
										<p>
											<strong>Client Details:</strong>
											<fa-icon [icon]="faPencil" class="mx-1" (click)="showAI = true"></fa-icon>
										</p>
										<div *ngIf="aiClient$ | async as aiClient">
											<label for="premium-posts">Basic</label><br />
											{{ aiClient.customer_note }}
										</div>
										<div *ngIf="aiClientDetail$ | async as aiClientDetail">
											<label for="premium-posts">Detailed</label><br />
											{{ aiClientDetail.customer_note }}
										</div>
										<div *ngIf="aiFacebook$ | async as aiFacebook">
											<label for="premium-posts">Facebook Details</label><br />
											{{ aiFacebook.customer_note }}
										</div>
										<div *ngIf="aiInstrgram$ | async as aiInstrgram">
											<label for="premium-posts">Instagram Details</label><br />
											{{ aiInstrgram.customer_note }}
										</div>
										<div *ngIf="aiReview$ | async as aiReview">
											<label for="premium-posts">Review Details</label><br />
											{{ aiReview.customer_note }}
										</div>
										<div *ngIf="aiComment$ | async as aiComment">
											<label for="premium-posts">Comment Details</label><br />
											{{ aiComment.customer_note }}
										</div>
									</div>
								</div>
							</div>
						</ng-template>
					</li>
					<li ngbNavItem="Affiliates">
						<a ngbNavLink>Affiliates</a>
						<ng-template ngbNavContent>
							<div class="container-fluid py-1">
								<div class="row">
									<div class="col-12">
										<p>
											<strong>Account Access:</strong>
											<fa-icon
												[icon]="faPencil"
												class="mx-1"
												(click)="showAffiliates = true"
											></fa-icon>
										</p>
										<div class="row">
											<ng-container
												*ngFor="let service of services$ | async | keyval; index as serviceIdx"
											>
												<div class="col-12 col-lg-6 mt-2" *ngIf="service.val.value">
													<div class="form-group form-check">
														<label
															class="form-check-label"
															for="service-{{ serviceIdx }}"
															>{{ service.key }}</label
														>
													</div>
													<div class="mt-n2 pl-1 pt-2">
														<ng-container
															*ngFor="
																let field of service.val.fields | keyval;
																index as fieldIdx
															"
														>
															<div
																*ngIf="field.val.type === 'bool' && field.val.value"
																class="form-group form-check"
															>
																<label
																	class="form-check-label"
																	for="service-field-{{ fieldIdx }}"
																>
																	{{ field.key }}
																</label>
															</div>
															<div
																*ngIf="field.val.type === 'text' && field.val.value"
																class="form-group d-flex align-items-center"
															>
																<label
																	for="service-field-{{ fieldIdx }}"
																	class="mr-2"
																	>{{ field.key }}</label
																>
																{{ field.val.value }}
															</div>
															<div
																*ngIf="field.val.type === 'textarea' && field.val.value"
																class="form-group d-flex align-items-center"
															>
																<label
																	for="service-field-{{ fieldIdx }}"
																	class="mr-2"
																	>{{ field.key }}</label
																>
																{{ field.val.value }}
															</div>
														</ng-container>
													</div>
												</div>
											</ng-container>
										</div>
									</div>
								</div>
							</div>
						</ng-template>
					</li>
					<li ngbNavItem="DomainAccess">
						<a ngbNavLink>Domain Access</a>
						<ng-template ngbNavContent>
							<div class="container-fluid py-1">
								<div class="row">
									<div class="col-12">
										<p>
											<strong>Domain Access:</strong>
											<fa-icon
												[icon]="faPencil"
												class="mx-1"
												(click)="showDomains = true"
											></fa-icon>
										</p>
										<ng-container *ngIf="domainInfo$ | async as domainInfo">
											<div class="row">
												<div class="col-12 col-lg-4">
													<ng-container *ngFor="let domain of domainInfo">
														<div *ngIf="domain.domain_cat === 'Primary Domain'">
															<strong>Website Domain: </strong>{{ domain.domain_name
															}}<br />
															<strong>Website Domain Password: </strong
															>{{ domain.password }}
														</div>
													</ng-container>
												</div>
												<div class="col-12 col-lg-4">
													<ng-container *ngFor="let domain of domainInfo">
														<div *ngIf="domain.domain_cat === 'Temp Domain'">
															<strong>Temporary Domain: </strong>{{ domain.domain_name
															}}<br />
															<strong>Temporary Password: </strong>{{ domain.password }}
														</div>
													</ng-container>
												</div>
												<div class="col-12 col-lg-4">
													<ng-container *ngFor="let domain of domainInfo">
														<div *ngIf="domain.domain_cat === 'DMS Domain'">
															<strong>DMS Site: </strong>{{ domain.domain_name }}<br />
															<strong>DMS Site Password: </strong>{{ domain.password }}
														</div>
													</ng-container>
												</div>
												<hr class="col-11" />
												<p class="col-12"><strong>Domains:</strong></p>
												<div class="col-12 table-responsive">
													<table class="table table-sm table-light table-striped table-hover">
														<thead class="thead-dark">
															<tr>
																<th>Domain</th>
																<th>Registrar</th>
																<th>Username</th>
																<th>Password</th>
																<th>Expires</th>
																<th>DNS Provider</th>
																<th>DNSSEC</th>
																<th>MX/DMARC/SPF</th>
															</tr>
														</thead>
														<tbody>
															<tr *ngFor="let domain of domainInfo">
																<td>
																	{{ domain.domain_name }}
																</td>
																<td>{{ domain.registrar }}</td>
																<td></td>
																<td></td>
																<td>{{ domain.expires }}</td>
																<td>{{ domain.dns_provider }}</td>
																<td>{{ domain.dnssec }}</td>
																<td>{{ domain.mx_dmarc_spf }}</td>
															</tr>
														</tbody>
													</table>
												</div>
											</div>
										</ng-container>
									</div>
								</div>
							</div>
						</ng-template>
					</li>
					<li ngbNavItem="Onboarding">
						<a ngbNavLink>Onboarding</a>
						<ng-template ngbNavContent>
							<cm-client-onboarding [customerid]="customerIdBS | async"></cm-client-onboarding>
						</ng-template>
					</li>
				</ul>
				<div [ngbNavOutlet]="nav" class="border-left border-right border-bottom border-warning"></div>
			</div>
		</ng-container>
		<cm-design-force-client-contact
			[show]="showContact"
			[customerid]="customerIdBS | async"
			[contactid]="currentContactId"
			(showChange)="showContact = $event; currentContactId = null; updateBS.next(null); updateChange.emit(true)"
		></cm-design-force-client-contact>
		<cm-client-edit-store-hours
			[show]="showHours"
			[customerid]="customerIdBS | async"
			[locationid]="currentLocationId"
			[org_deptid]="12"
			(showChange)="showHours = $event; currentLocationId = null; updateBS.next(null); updateChange.emit(true)"
		></cm-client-edit-store-hours>
		<cm-design-force-customer-profile-admin
			[customerId]="customerIdBS | async"
			[show]="showProfileAdmin"
			(showChange)="showProfileAdmin = false; updateChange.emit(true)"
		></cm-design-force-customer-profile-admin>
		<cm-modal [show]="showHoliday" (showChange)="customerIdBS.next(customerIdBS.value); showHoliday = $event">
			<ng-template cmModal>
				<div class="row">
					<div
						class="h1 col-12 bg-primary text-white text-uppercase flex-center px-sm-5 px-3 pt-5 pb-sm-5 pb-3 mb-0"
					>
						Holiday Hours
					</div>
				</div>
				<cm-client-edit-holiday-hours
					class="py-2"
					[customerid]="customerIdBS | async"
					(showChange)="customerIdBS.next(customerIdBS.value); showHoliday = false"
				></cm-client-edit-holiday-hours>
			</ng-template>
		</cm-modal>
		<cm-modal [show]="showSites" (showChange)="customerIdBS.next(customerIdBS.value); showSites = $event">
			<ng-template cmModal>
				<div class="row">
					<div
						class="h1 col-12 bg-primary text-white text-uppercase flex-center px-sm-5 px-3 pt-5 pb-sm-5 pb-3 mb-0"
					>
						Hours Locations
					</div>
				</div>
				<cm-client-edit-store-hour-locations
					class="py-2"
					[customerid]="customerIdBS | async"
				></cm-client-edit-store-hour-locations>
			</ng-template>
		</cm-modal>
		<cm-modal [show]="showContract" (showChange)="customerIdBS.next(customerIdBS.value); showContract = $event">
			<ng-template cmModal>
				<div class="row">
					<div
						class="h1 col-12 bg-primary text-white text-uppercase flex-center px-sm-5 px-3 pt-5 pb-sm-5 pb-3 mb-0"
					>
						Contract
					</div>
				</div>
				<cm-client-edit-contract class="py-2" [customerid]="customerIdBS | async"></cm-client-edit-contract>
			</ng-template>
		</cm-modal>
		<cm-modal [show]="showIncludes" (showChange)="customerIdBS.next(customerIdBS.value); showIncludes = $event">
			<ng-template cmModal>
				<div class="row">
					<div
						class="h1 col-12 bg-primary text-white text-uppercase flex-center px-sm-5 px-3 pt-5 pb-sm-5 pb-3 mb-0"
					>
						Contract Includes
					</div>
				</div>
				<cm-client-edit-contract-options
					class="py-2"
					[customerid]="customerIdBS | async"
				></cm-client-edit-contract-options>
			</ng-template>
		</cm-modal>
		<cm-modal [show]="showSocial" (showChange)="customerIdBS.next(customerIdBS.value); showSocial = $event">
			<ng-template cmModal>
				<div class="row">
					<div
						class="h1 col-12 bg-primary text-white text-uppercase flex-center px-sm-5 px-3 pt-5 pb-sm-5 pb-3 mb-0"
					>
						Post(s) Count
					</div>
				</div>
				<cm-client-edit-post-count class="py-2" [customerid]="customerIdBS | async"></cm-client-edit-post-count>
			</ng-template>
		</cm-modal>
		<cm-modal [show]="showAI" (showChange)="customerIdBS.next(customerIdBS.value); showAI = $event">
			<ng-template cmModal>
				<div class="row">
					<div
						class="h1 col-12 bg-primary text-white text-uppercase flex-center px-sm-5 px-3 pt-5 pb-sm-5 pb-3 mb-0"
					>
						AI Content
					</div>
				</div>
				<cm-client-edit-ai class="py-2" [customerid]="customerIdBS | async"></cm-client-edit-ai>
			</ng-template>
		</cm-modal>
		<cm-modal [show]="showPromotion" (showChange)="customerIdBS.next(customerIdBS.value); showPromotion = $event">
			<ng-template cmModal>
				<div class="row">
					<div
						class="h1 col-12 bg-primary text-white text-uppercase flex-center px-sm-5 px-3 pt-5 pb-sm-5 pb-3 mb-0"
					>
						Promotions
					</div>
				</div>
				<cm-client-edit-promotions class="py-2" [customerid]="customerIdBS | async"></cm-client-edit-promotions>
			</ng-template>
		</cm-modal>
		<cm-modal [show]="showAffiliates" (showChange)="customerIdBS.next(customerIdBS.value); showAffiliates = $event">
			<ng-template cmModal>
				<div class="row">
					<div
						class="h1 col-12 bg-primary text-white text-uppercase flex-center px-sm-5 px-3 pt-5 pb-sm-5 pb-3 mb-0"
					>
						Affiliates
					</div>
				</div>
				<cm-client-edit-affiliates class="py-2" [customerid]="customerIdBS | async"></cm-client-edit-affiliates>
			</ng-template>
		</cm-modal>
		<cm-modal [show]="showDomains" (showChange)="customerIdBS.next(customerIdBS.value); showDomains = $event">
			<ng-template cmModal>
				<div class="row">
					<div
						class="h1 col-12 bg-primary text-white text-uppercase flex-center px-sm-5 px-3 pt-5 pb-sm-5 pb-3 mb-0"
					>
						Domains
					</div>
				</div>
				<cm-client-edit-domain-info
					class="py-2"
					[customerid]="customerIdBS | async"
				></cm-client-edit-domain-info>
			</ng-template>
		</cm-modal>
	`,
	styles: [
		`
			:host ::ng-deep .card-header {
				padding: 0;
			}
			.green-top-border {
				border-top: solid 2px green;
				padding-top: 10px;
				padding-bottom: 10px;
			}

			:host ::ng-deep .nav-tabs .nav-link:not(.active) {
				border-color: #ffc107;
			}
			:host ::ng-deep .nav-tabs {
				border-color: #ffc107;
			}
			:host ::ng-deep .nav-tabs .nav-link.active {
				background-color: #ffc107;
			}
		`,
	],
})
export class CustomerInfoComponent implements OnChanges {
	@Input() customerId!: number;
	@Input() qbId!: number;
	@Input() nested: boolean = false;
	@Output() updateChange = new EventEmitter();
	currentContactId: null | number = null;
	showContact: boolean = false;
	showHours: boolean = false;
	showMore: boolean = false;
	showHoliday: boolean = false;
	showSites: boolean = false;
	showProfileAdmin: boolean = false;
	showIncludes: boolean = false;
	showSocial: boolean = false;
	showContract: boolean = false;
	showPromotion: boolean = false;
	showAffiliates: boolean = false;
	showDomains: boolean = false;
	showAI: boolean = false;
	currentLocationId: null | number = null;
	customerIdBS = new BehaviorSubject(0);
	qbIdBS = new BehaviorSubject(0);
	updateBS = new BehaviorSubject(null);
	faPencil = faPencil;
	faPlusCircle = faPlusCircle;
	projectCats: any[] = [];
	contractProjectCats: any[] = [];
	includesProjectCats: any[] = [];
	activeId = "HolidayLocations";

	customerNoteAffiliates$ = this.customerIdBS.pipe(
		switchMap((customerid) =>
			this.http.post("/api/statement/GetCustomerNote", { vars: { customerid, note_titleid: 17 } }),
		),
		map((res: any) => res.results[0]),
		shareReplay(1),
	);

	services$ = this.customerNoteAffiliates$.pipe(
		map((row: any) => deepMerge(serviceDefaults, row ? JSON.parse(row.customer_note) : {})),
		shareReplay(1),
	);

	info$: Observable<any> = combineLatest([this.customerIdBS, this.qbIdBS]).pipe(
		switchMap(([customerId, qbId]) =>
			this.http.post("/api/statement/GetSocialPostCustomerInfo", {
				vars: {
					customerid: customerId !== 0 ? customerId : undefined,
					qbId: qbId !== 0 ? qbId : undefined,
				},
			}),
		),
		map((response: IStmtResults<any>) => response.results![0] || null),
		tap((res) => (this.customerId = res ? res.customerid : null)),
		shareReplay(1),
	);
	premiumNote$ = this.customerIdBS.pipe(
		switchMap((customerid) =>
			this.http.post("/api/statement/GetCustomerNotes", {
				vars: { customerid, note_titleid: 14 },
			}),
		),
		map((response: IStmtResults<any>) =>
			response.results && response.results[0]
				? response.results[0]
				: { customer_note: "", customer_noteid: null },
		),
	);

	domainInfo$ = this.customerIdBS.pipe(
		switchMap((customerid) =>
			this.http.post("/api/statement/GetDomainInfo", {
				vars: { customerid },
			}),
		),
		map((response: IStmtResults<any>) => response.results),
		shareReplay(1),
	);

	regularNote$ = this.customerIdBS.pipe(
		switchMap((customerid) =>
			this.http.post("/api/statement/GetCustomerNotes", {
				vars: { customerid, note_titleid: 15 },
			}),
		),
		map((response: IStmtResults<any>) =>
			response.results && response.results[0]
				? response.results[0]
				: { customer_note: "", customer_noteid: null },
		),
	);
	contacts$ = this.updateBS.pipe(
		switchMap(() =>
			this.http.post("/api/statement/GetCustomerContacts", {
				vars: {
					customerid: this.customerId,
				},
			}),
		),
		map((response: IStmtResults<any>) => response.results),
		shareReplay(1),
	);

	emails$ = this.updateBS.pipe(
		switchMap(() =>
			this.http.post("/api/statement/GetCustEmails", {
				vars: {
					customerid: this.customerId,
				},
			}),
		),
		map((response: IStmtResults<any>) => response.results),
		shareReplay(1),
	);

	customerProjectsSites$ = this.customerIdBS.pipe(
		switchMap((customerid) =>
			this.http.post("/api/statement/GetCustomerProjects", {
				vars: { customerid, project_cat_ofid: 74, groupByProject: false },
			}),
		),
		map((response: IStmtResults<any>) => {
			const ret = new Map();

			response.results?.forEach((row) => {
				ret.set(row.project_catid, row.customer_project);
			});
			return ret;
		}),
		shareReplay(1),
	);

	customerProjectsContract$ = this.customerIdBS.pipe(
		switchMap((customerid) =>
			this.http.post("/api/statement/GetCustomerProjects", {
				vars: { customerid, project_cat_ofid: 113, groupByProject: false },
			}),
		),
		map((response: IStmtResults<any>) => {
			const ret = new Map();
			if (response.results && response.results[0]) {
				ret.set(response.results![0].project_catid, response.results![0]);
			}
			return ret;
		}),
		shareReplay(1),
	);

	customerProjectsIncludes$ = this.customerIdBS.pipe(
		switchMap((customerid) =>
			this.http.post("/api/statement/GetCustomerProjects", {
				vars: { customerid, project_cat_ofid: 83, groupByProject: false },
			}),
		),
		map((response: IStmtResults<any>) => {
			const ret = new Map();

			response.results?.forEach((row) => {
				ret.set(row.project_catid, row.customer_project);
			});
			return ret;
		}),
		shareReplay(1),
	);

	customerNote$ = this.customerIdBS.pipe(
		switchMap((customerid) =>
			this.http.post("/api/statement/GetCustomerNotes", {
				vars: { customerid, note_titleid: 13 },
			}),
		),
		map((response: IStmtResults<any>) =>
			response.results && response.results[0]
				? response.results[0]
				: { customer_note: "", customer_noteid: null },
		),
	);

	promotionNote$ = this.customerIdBS.pipe(
		switchMap((customerid) =>
			this.http.post("/api/statement/GetCustomerNotes", {
				vars: { customerid, note_titleid: 16 },
			}),
		),
		map((response: IStmtResults<any>) =>
			response.results && response.results[0]
				? response.results[0]
				: { customer_note: "", customer_noteid: null },
		),
	);
	promotions$ = this.customerIdBS.pipe(
		switchMap((customerid) =>
			this.http.post("/api/statement/GetCustomerPromotions", {
				vars: { customerid },
			}),
		),
		map((response: IStmtResults<any>) =>
			response.results
				? response.results.map((row) => ({
						...row,
						promotion_start: row.promotion_start ? row.promotion_start.split(" ")[0] : "",
						promotion_end: row.promotion_end ? row.promotion_end.split(" ")[0] : "",
				  }))
				: [],
		),
	);

	logo$ = combineLatest([this.customerIdBS, this.qbIdBS]).pipe(
		switchMap(([customerId, qbId]) =>
			this.http.post("/api/statement/GetCustomerLogo", {
				vars: {
					customerid: customerId !== 0 ? customerId : undefined,
					qbId: qbId !== 0 ? qbId : undefined,
				},
			}),
		),
		map((response: any) => response.results[0] || null),
		shareReplay(1),
	);

	holidays: IHoliday[] = [];

	holidays$ = this.customerIdBS.pipe(
		tap((customerid) => (this.customerId = customerid)),
		switchMap((customerid) => this.http.post("/api/statement/GetCustomerHolidays", { vars: { customerid } })),
		map((res: any) => {
			const holidays = new Map();
			this.holidays.forEach((h) => {
				holidays.set(h.holiday, h.holidayid);
			});

			// dept -> day -> row
			const ret = new SMap<any, any>();

			for (const row of res.results) {
				const obj = ret.getOrInsertWith(row.customerid, () => ({
					holidays: this.createModel(),
				}));
				const model = this.search(row.holiday, obj.holidays);
				model.notes = row.notes;
			}

			if (!res.results || (res.results && res.results.length < 1)) {
				ret.getOrInsertWith(this.customerId, () => ({
					holidays: this.createModel(),
				}));
			}

			return ret;
		}),
	);

	locations$: Observable<any> = combineLatest([this.customerIdBS, this.qbIdBS]).pipe(
		switchMap(([customerId, qbId]) =>
			this.http
				.post("/api/statement/GetLocations", {
					vars: {
						customerid: customerId !== 0 ? customerId : undefined,
						qbId: qbId !== 0 ? qbId : undefined,
					},
				})
				.pipe(
					map((response: IStmtResults<any>) =>
						response.results!.map((row: any) => ({
							hours$: this.updateBS.pipe(
								switchMap(() =>
									this.http.post("/api/statement/GetLocationDeptHours", {
										vars: { locationid: row.locationid, org_deptid: 12 },
									}),
								),
								map((response: IStmtResults<any>) => {
									const hours: SMap<string, SMap<string, string>> = new SMap();
									for (const row of response.results!) {
										let from = row.time_from;
										if (from && from.startsWith("0")) {
											from = from.substring(1);
										}

										let to = row.time_to;
										if (to && to.startsWith("0")) {
											to = to.substring(1);
										}

										const hourRange = from && to ? `${from} - ${to}` : "Closed";

										hours
											.getOrInsertWith(row.org_dept, () => new SMap())
											.getOrInsert(row.day_of_week, hourRange);
									}
									return hours;
								}),
								shareReplay(1),
							),
							...row,
						})),
					),
					shareReplay(1),
				),
		),
	);

	aiClient$ = this.customerIdBS.pipe(
		switchMap((customerid) =>
			this.http.post("/api/statement/GetCustomerNotes", {
				vars: { customerid, note_titleid: 21 },
			}),
		),
		map((response: IStmtResults<any>) =>
			response.results && response.results[0]
				? response.results[0]
				: { customer_note: "", customer_noteid: null },
		),
	);

	aiClientDetail$ = this.customerIdBS.pipe(
		switchMap((customerid) =>
			this.http.post("/api/statement/GetCustomerNotes", {
				vars: { customerid, note_titleid: 26 },
			}),
		),
		map((response: IStmtResults<any>) =>
			response.results && response.results[0]
				? response.results[0]
				: { customer_note: "", customer_noteid: null },
		),
	);

	aiFacebook$ = this.customerIdBS.pipe(
		switchMap((customerid) =>
			this.http.post("/api/statement/GetCustomerNotes", {
				vars: { customerid, note_titleid: 22 },
			}),
		),
		map((response: IStmtResults<any>) =>
			response.results && response.results[0]
				? response.results[0]
				: { customer_note: "", customer_noteid: null },
		),
	);

	aiInstrgram$ = this.customerIdBS.pipe(
		switchMap((customerid) =>
			this.http.post("/api/statement/GetCustomerNotes", {
				vars: { customerid, note_titleid: 23 },
			}),
		),
		map((response: IStmtResults<any>) =>
			response.results && response.results[0]
				? response.results[0]
				: { customer_note: "", customer_noteid: null },
		),
	);

	aiReview$ = this.customerIdBS.pipe(
		switchMap((customerid) =>
			this.http.post("/api/statement/GetCustomerNotes", {
				vars: { customerid, note_titleid: 24 },
			}),
		),
		map((response: IStmtResults<any>) =>
			response.results && response.results[0]
				? response.results[0]
				: { customer_note: "", customer_noteid: null },
		),
	);

	aiComment$ = this.customerIdBS.pipe(
		switchMap((customerid) =>
			this.http.post("/api/statement/GetCustomerNotes", {
				vars: { customerid, note_titleid: 25 },
			}),
		),
		map((response: IStmtResults<any>) =>
			response.results && response.results[0]
				? response.results[0]
				: { customer_note: "", customer_noteid: null },
		),
	);

	constructor(private http: HttpClient) {
		this.http
			.post("/api/statement/GetProjectCats", { vars: { project_cat_ofid: 74 } })
			.subscribe((response: IStmtResults<any>) => (this.projectCats = response.results!));
		this.http
			.post("/api/statement/GetProjectCats", { vars: { project_cat_ofid: 113 } })
			.subscribe((response: IStmtResults<any>) => (this.contractProjectCats = response.results!));
		this.http
			.post("/api/statement/GetProjectCats", { vars: { project_cat_ofid: 83 } })
			.subscribe((response: IStmtResults<any>) => (this.includesProjectCats = response.results!));
		this.http
			.post("/api/statement/GetHolidays", {})
			.subscribe((response: IStmtResults<IHoliday>) => (this.holidays = response.results!));
	}

	ngOnChanges(changes: SimpleChanges): void {
		if (changes.customerId) {
			this.customerIdBS.next(changes.customerId.currentValue);
		}
		if (changes.qbId) {
			this.qbIdBS.next(changes.qbId.currentValue);
		}
	}

	hideCustomer() {
		this.http
			.post("/api/statement/UpdateCustomerHide", { vars: { customerid: this.customerId, hide: 1 } })
			.subscribe(() => this.updateChange.emit(true));
	}

	createModel() {
		const model: any[] = [];
		this.holidays.forEach((h) => {
			model.push({ holiday: h.holiday, holidayid: h.holidayid, notes: "" });
		});
		return model;
	}

	search(nameKey: string, myArray: any) {
		for (let i = 0; i < myArray.length; i++) {
			if (myArray[i].holiday === nameKey) {
				return myArray[i];
			}
		}
	}
}

interface IHoliday {
	holiday: string;
	holidayid: number;
}

interface ServiceFields {
	[name: string]: {
		value: boolean;
		fields: {
			[name: string]: {
				type: "bool" | "text" | "textarea";
				value?: any;
			};
		};
	};
}

const serviceDefaults: ServiceFields = {
	Facebook: {
		value: false,
		fields: {
			"Agency Request Accepted": { type: "bool", value: false },
			"Profile Created by DFM": { type: "bool", value: false },
		},
	},
	Instagram: {
		value: false,
		fields: {
			"Agency Request Accepted": { type: "bool", value: false },
			"Profile Created by DFM": { type: "bool", value: false },
		},
	},
	"Trip Advisor": {
		value: false,
		fields: { "Profile Created by DFM": { type: "bool", value: false } },
	},
	Yelp: {
		value: false,
		fields: { "Profile Created by DFM": { type: "bool", value: false } },
	},
	"Google My Business": {
		value: false,
		fields: { "Profile Created by DFM": { type: "bool", value: false } },
	},
	Bing: {
		value: false,
		fields: { "Profile Created by DFM": { type: "bool", value: false } },
	},
	"Payment Processor": {
		value: false,
		fields: { Company: { type: "text", value: "" }, "Profile Created by DFM": { type: "bool", value: false } },
	},
	POS: {
		value: false,
		fields: { Company: { type: "text", value: "" } },
	},
	Shipping: {
		value: false,
		fields: {
			Company: { type: "text", value: "" },
			"Delivery Offered": { type: "text", value: "" },
			"Pickup Offered": { type: "text", value: "" },
		},
	},
	Quickbooks: {
		value: false,
		fields: {
			"What we are integrating": { type: "textarea", value: "" },
		},
	},
};

function deepMerge(lhs: any, rhs: any) {
	const ret = cloneDeep(lhs);
	for (const [key, val] of iterObj(rhs)) {
		if (typeof lhs[key] === "object") {
			ret[key] = deepMerge(lhs[key], val);
		} else {
			ret[key] = val;
		}
	}
	return ret;
}
