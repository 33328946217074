import { Frozen, Immutable } from "./decorators";
import { iter } from "./iter";

export function slice(str: string, start: number = 0, length: number = -1) {
	return new StringSlice(str, start, length);
}

@Frozen
@Immutable
export class StringSlice {
	public str: string;
	public start: number;
	public length: number;

	constructor(str: string, start: number = 0, length: number = -1) {
		if (start < 0 || length < -1 || start + length > str.length) {
			throw new Error("index out of bounds");
		}

		if (length === -1) {
			length = str.length - start;
		}

		this.str = str;
		this.start = start;
		this.length = length;
	}

	charAt(index: number): string {
		return this.str.charAt(this.start + index);
	}

	eq(other: StringSlice | string) {
		if (this.length !== other.length) {
			return false;
		}

		for (const [ch1, ch2] of iter(this).zip(other)) {
			if (ch1 !== ch2) {
				return false;
			}
		}

		return true;
	}

	match(matcher: { [Symbol.match](string: string): RegExpMatchArray | null }): RegExpMatchArray | null;
	// tslint:disable-next-line:unified-signatures
	match(regexp: string | RegExp): RegExpMatchArray | null;
	match(regexp: any): RegExpMatchArray | null {
		return this.toString().match(regexp);
	}

	startsWith(str: StringSlice | string): boolean {
		if (this.length < str.length) {
			return false;
		}

		for (const [ch1, ch2] of iter(this).zip(str)) {
			if (ch1 !== ch2) {
				return false;
			}
		}

		return true;
	}

	substring(start: number, end: number = -1) {
		if (end === -1) {
			end = this.length;
		}
		if (start < 0 || end < 0 || end > this.length) {
			throw new Error("index out of bounds");
		}

		const newStart = this.start + start;
		return new StringSlice(this.str, newStart, end - start);
	}

	toString(): string {
		return this.str.substring(this.start, this.start + this.length);
	}

	toUpperCase(): string {
		return this.toString().toUpperCase();
	}

	*[Symbol.iterator](): Iterator<string> {
		for (let i = 0; i < this.length; i++) {
			yield this.charAt(i);
		}
	}
}
