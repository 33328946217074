import { Injectable, TemplateRef } from "@angular/core";
import { BehaviorSubject } from "rxjs";

let nextId = 0;

@Injectable({ providedIn: "root" })
export class ModalService {
	private templatesBS = new BehaviorSubject(new Map<number, TemplateRef<any>>());
	templates$ = this.templatesBS.asObservable();

	pushTemplate(val: TemplateRef<any>): number {
		const id = nextId++;
		this.templatesBS.value.set(id, val);
		this.templatesBS.next(this.templatesBS.value);
		return id;
	}

	delTemplate(id: number) {
		this.templatesBS.value.delete(id);
		this.templatesBS.next(this.templatesBS.value);
	}
}
