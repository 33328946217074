import { HttpClient } from "@angular/common/http";
import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from "@angular/core";
import { iter } from "@common/iter";
import { SMap } from "@common/map2";
import { IStmtResults } from "@model/stmt-results";
import { ToastrService } from "ngx-toastr";
import { BehaviorSubject } from "rxjs";
import { map, switchMap, tap } from "rxjs/operators";

@Component({
	selector: "cm-client-edit-holiday-hours[customerid]",
	template: `
		<ng-container *ngIf="holidays.length > 0">
			<form *ngIf="holidays$ | async as holidays" class="container-fluid my-2" (ngSubmit)="save(holidays)">
				<p><strong>Holiday Hours:</strong></p>
				<ng-container *ngFor="let row of holidays | keyval">
					<div
						*ngFor="let holiday of row.val.holidays; index as i"
						class="w-100 mb-2 d-flex align-items-center"
					>
						<div class="col-6">{{ holiday.holiday }}</div>
						<div class="col-6">
							<input name="{{ i }}-notes" [(ngModel)]="holiday.notes" class="form-control rounded" />
						</div>
					</div>
					<hr />
				</ng-container>
				<p><strong>Where to Update:</strong></p>
				<div class="form-check w-100 w-lg-auto">
					<div class="row">
						<ng-container *ngFor="let projectCat of projectCats; index as i">
							<div class="col-12 col-lg-4">
								<input
									class="mr-1"
									type="checkbox"
									id="option{{ i }}"
									name="option{{ i }}"
									[ngModel]="customerProjectCats.has(projectCat.project_catid)"
									(ngModelChange)="updateProjects($event, projectCat.project_catid)"
								/>
								<label for="option{{ i }}">{{ projectCat.project_cat }}</label>
							</div>
						</ng-container>
					</div>
				</div>
				<div class="text-right">
					<button class="btn btn-primary" [disabled]="saving">Submit</button>
				</div>
			</form>
		</ng-container>
	`,
	styles: [
		`
			:host {
				display: block;
			}
		`,
	],
})
export class ClientEditHolidayHoursComponent implements OnChanges {
	@Input() customerid!: number;
	@Input() show: boolean = false;

	@Output() showChange = new EventEmitter();

	customeridBS = new BehaviorSubject(0);

	holidays: IHoliday[] = [];

	holidays$ = this.customeridBS.pipe(
		tap((customerid) => (this.customerid = customerid)),
		switchMap((customerid) => this.http.post("/api/statement/GetCustomerHolidays", { vars: { customerid } })),
		map((res: any) => {
			const holidays = new Map();
			this.holidays.forEach((h) => {
				holidays.set(h.holiday, h.holidayid);
			});

			// dept -> day -> row
			const ret = new SMap<any, any>();

			for (const row of res.results) {
				const obj = ret.getOrInsertWith(row.customerid, () => ({
					holidays: this.createModel(),
				}));
				const model = this.search(row.holiday, obj.holidays);
				model.notes = row.notes;
			}

			if (!res.results || (res.results && res.results.length < 1)) {
				ret.getOrInsertWith(this.customerid, () => ({
					holidays: this.createModel(),
				}));
			}

			return ret;
		}),
	);

	saving = false;

	projectCats: any[] = [];
	customerProjectCats: Set<number> = new Set();

	constructor(private http: HttpClient, private toastr: ToastrService) {
		this.http
			.post("/api/statement/GetHolidays", {})
			.subscribe((response: IStmtResults<IHoliday>) => (this.holidays = response.results!));
		this.http
			.post("/api/statement/GetProjectCats", { vars: { project_cat_ofid: 74 } })
			.subscribe((response: IStmtResults<any>) => (this.projectCats = response.results!));
	}

	save(holidays: SMap<any, any[]>) {
		this.saving = true;
		this.http
			.post("/api/store-holidays", {
				holidays: iter(holidays).toObject(),
				notifyChanges: true,
				placesToUpdate: Array.from(this.customerProjectCats),
			})
			.subscribe(
				() => {
					this.toastr.success("Saved Customer Holidays");
					this.saving = false;
					this.showChange.emit(false);
				},
				() => {
					this.toastr.error("Failed to save Customer Holidays");
					this.saving = false;
				},
			);
	}

	ngOnChanges(changes: SimpleChanges): void {
		if (changes.customerid) {
			this.customeridBS.next(changes.customerid.currentValue);
			this.http
				.post("/api/statement/GetCustomerProjects", {
					vars: { customerid: this.customerid, project_cat_ofid: 74, groupByProject: false },
				})
				.subscribe((response: IStmtResults<any>) => {
					this.customerProjectCats = new Set();

					response.results?.forEach((row) => {
						this.customerProjectCats.add(row.project_catid);
					});
				});
		}
	}

	updateProjects(enable: boolean, project_catid: number) {
		if (enable) {
			this.customerProjectCats.add(project_catid);
		} else {
			this.customerProjectCats.delete(project_catid);
		}
	}

	createModel() {
		const model: any[] = [];
		this.holidays.forEach((h) => {
			model.push({ holiday: h.holiday, holidayid: h.holidayid, notes: "" });
		});
		return model;
	}

	search(nameKey: string, myArray: any) {
		for (let i = 0; i < myArray.length; i++) {
			if (myArray[i].holiday === nameKey) {
				return myArray[i];
			}
		}
	}
}

interface IHoliday {
	holiday: string;
	holidayid: number;
}
