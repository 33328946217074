import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { filter } from "rxjs/operators";

@Injectable({
	providedIn: "root",
})
export class UserWidgetService {
	private userWidgetData = {};
	private updateWidgetSubject = new BehaviorSubject({} as any);
	private updateWidget$ = this.updateWidgetSubject.asObservable();

	routeDataBS = new BehaviorSubject(null);
	routeData$ = this.routeDataBS.asObservable().pipe(filter((data) => !!data));

	showCalcBS = new BehaviorSubject(false);
	showCalc$ = this.showCalcBS.asObservable();

	onUpdateWidget() {
		return this.updateWidget$;
	}

	updateWidget(update: any) {
		if (update.reset === true) {
			this.userWidgetData = {};
		} else {
			this.userWidgetData = { ...this.userWidgetData, ...update };
		}
		this.updateWidgetSubject.next(this.userWidgetData);
	}

	setRouteData(data: any) {
		this.routeDataBS.next(data);
	}

	setShowCalc(show = false) {
		this.showCalcBS.next(show);
	}
}
