<header class="header">
	<div ngbDropdown class="remove">
		<button class="ngb-dropdown" [ngClass]="{ 'selected-route': mainRoute === '/industries' }" ngbDropdownToggle>
			INDUSTRIES
		</button>
		<div ngbDropdownMenu aria-labelledby="dropdownBasic1">
			<button
				[ngClass]="{ 'selected-route': fullRoute === '/industries' }"
				[routerLink]="['/industries']"
				routerLinkActive="router-link-active"
				ngbDropdownItem
			>
				INDUSTRIES PAGE
			</button>
			<button
				[ngClass]="{ 'selected-route': fullRoute === '/industries/rv-dealers' }"
				[routerLink]="['/industries/rv-dealers']"
				routerLinkActive="router-link-active"
				ngbDropdownItem
			>
				RV DEALERS
			</button>
			<button
				[ngClass]="{ 'selected-route': fullRoute === '/industries/auto-dealers' }"
				[routerLink]="['/industries/auto-dealers']"
				routerLinkActive="router-link-active"
				ngbDropdownItem
			>
				AUTO DEALERS
			</button>
			<button
				[ngClass]="{ 'selected-route': fullRoute === '/industries/boat-dealers' }"
				[routerLink]="['/industries/boat-dealers']"
				routerLinkActive="router-link-active"
				ngbDropdownItem
			>
				BOAT & MARINE DEALERS
			</button>
			<button
				[ngClass]="{ 'selected-route': fullRoute === '/industries/real-estate' }"
				[routerLink]="['/industries/real-estate']"
				routerLinkActive="router-link-active"
				ngbDropdownItem
			>
				REAL ESTATE
			</button>
			<button
				[ngClass]="{ 'selected-route': fullRoute === '/industries/food-drink' }"
				[routerLink]="['/industries/food-drink']"
				routerLinkActive="router-link-active"
				ngbDropdownItem
			>
				FOOD & DRINK
			</button>
			<button
				[ngClass]="{ 'selected-route': fullRoute === '/industries/ecommerce' }"
				[routerLink]="['/industries/ecommerce']"
				routerLinkActive="router-link-active"
				ngbDropdownItem
			>
				ECOMMERCE
			</button>
			<button
				[ngClass]="{ 'selected-route': fullRoute === '/industries/medical' }"
				[routerLink]="['/industries/medical']"
				routerLinkActive="router-link-active"
				ngbDropdownItem
			>
				MEDICAL
			</button>
			<button
				[ngClass]="{ 'selected-route': fullRoute === '/industries/trades' }"
				[routerLink]="['/industries/trades']"
				routerLinkActive="router-link-active"
				ngbDropdownItem
			>
				TRADES
			</button>
			<button
				[ngClass]="{ 'selected-route': fullRoute === '/industries/personal-care' }"
				[routerLink]="['/industries/personal-care']"
				routerLinkActive="router-link-active"
				ngbDropdownItem
			>
				PERSONAL CARE
			</button>
			<button
				[ngClass]="{ 'selected-route': fullRoute === '/industries/housing' }"
				[routerLink]="['/industries/housing']"
				routerLinkActive="router-link-active"
				ngbDropdownItem
			>
				HOUSING
			</button>
		</div>
	</div>

	<div ngbDropdown class="remove">
		<button class="ngb-dropdown" [ngClass]="{ 'selected-route': mainRoute === '/services' }" ngbDropdownToggle>
			SERVICES
		</button>
		<div ngbDropdownMenu aria-labelledby="dropdownBasic1">
			<button
				[ngClass]="{ 'selected-route': fullRoute === '/services' }"
				[routerLink]="['/services']"
				routerLinkActive="router-link-active"
				ngbDropdownItem
			>
				SERVICES PAGE
			</button>
			<button
				[ngClass]="{ 'selected-route': fullRoute === '/services/technical-solutions' }"
				[routerLink]="['/services/technical-solutions']"
				routerLinkActive="router-link-active"
				ngbDropdownItem
			>
				TECHNICAL SOLUTIONS
			</button>
			<button
				[ngClass]="{ 'selected-route': fullRoute === '/services/online-marketing' }"
				[routerLink]="['/services/online-marketing']"
				routerLinkActive="router-link-active"
				ngbDropdownItem
			>
				ONLINE MARKETING
			</button>
			<button
				[ngClass]="{ 'selected-route': fullRoute === '/services/digital-content' }"
				[routerLink]="['/services/digital-content']"
				routerLinkActive="router-link-active"
				ngbDropdownItem
			>
				DIGITAL CONTENT
			</button>
		</div>
	</div>

	<div class="logo">
		<button routerLink="/">
			<img src="https://dfm-cdn.com/static/12/long-blue-001-s.png" alt="{{ dealer }}" class="logo-img" />
			<ng-template #noLogo
				><span>{{ dealer }}</span></ng-template
			>
		</button>
	</div>

	<div class="remove">
		<button
			[ngClass]="{ 'selected-route': fullRoute === '/free-consultation' }"
			[routerLink]="['/free-consultation']"
			routerLinkActive="router-link-active"
		>
			FREE CONSULTATION
		</button>
	</div>

	<div class="remove">
		<a href="tel:{{ location.org_phone_number }}"
			><strong>{{ location.org_phone_number | tel }}</strong></a
		>
	</div>

	<div class="remove d-none">
		<button [ngClass]="{ 'selected-route': fullRoute === '/client-portal' }" href="">CLIENT PORTAL</button>
	</div>

	<button class="openbtn" (click)="toggleNav()">
		<fa-icon [icon]="faBars"></fa-icon>
	</button>
</header>

<div [ngClass]="{ sidepanel: mobileMenu }" class="closed">
	<a class="closebtn" (click)="toggleNav()">
		<fa-icon style="color: white" [icon]="faTimesCircle"></fa-icon>
	</a>
	<a href="tel:{{ location.org_phone_number }}" class="text-white pl-4" style="font-size: 25px"
		><strong>{{ location.org_phone_number | tel }}</strong></a
	>
	<div class="dropdown">
		<span class="nav-titles" [ngClass]="{ 'selected-route': mainRoute === '/industries' }">INDUSTRIES</span>
		<div class="dropdown-content">
			<a
				[ngClass]="{ 'selected-route': fullRoute === '/industries' }"
				[routerLink]="['/industries']"
				routerLinkActive="router-link-active"
				>INDUSTRIES PAGE</a
			>
			<a
				[ngClass]="{ 'selected-route': fullRoute === '/industries/rv-dealers' }"
				[routerLink]="['/industries/rv-dealers']"
				routerLinkActive="router-link-active"
				>RV DEALERS</a
			>
			<a
				[ngClass]="{ 'selected-route': fullRoute === '/industries/auto-dealers' }"
				[routerLink]="['/industries/auto-dealers']"
				routerLinkActive="router-link-active"
				>AUTO DEALERS</a
			>
			<a
				[ngClass]="{ 'selected-route': fullRoute === '/industries/boat-dealers' }"
				[routerLink]="['/industries/boat-dealers']"
				routerLinkActive="router-link-active"
				>BOAT & MARINE DEALERS</a
			>
			<a
				[ngClass]="{ 'selected-route': fullRoute === '/industries/real-estate' }"
				[routerLink]="['/industries/real-estate']"
				routerLinkActive="router-link-active"
				>REAL ESTATE</a
			>
			<a
				[ngClass]="{ 'selected-route': fullRoute === '/industries/food-drink' }"
				[routerLink]="['/industries/food-drink']"
				routerLinkActive="router-link-active"
				>FOOD & DRINK</a
			>
			<a
				[ngClass]="{ 'selected-route': fullRoute === '/industries/ecommerce' }"
				[routerLink]="['/industries/ecommerce']"
				routerLinkActive="router-link-active"
				>ECOMMERCE</a
			>
			<a
				[ngClass]="{ 'selected-route': fullRoute === '/industries/medical' }"
				[routerLink]="['/industries/medical']"
				routerLinkActive="router-link-active"
				>MEDICAL</a
			>
			<a
				[ngClass]="{ 'selected-route': fullRoute === '/industries/trades' }"
				[routerLink]="['/industries/trades']"
				routerLinkActive="router-link-active"
				>TRADES</a
			>
			<a
				[ngClass]="{ 'selected-route': fullRoute === '/industries/personal-care' }"
				[routerLink]="['/industries/personal-care']"
				routerLinkActive="router-link-active"
				>PERSONAL Care</a
			>
			<a
				[ngClass]="{ 'selected-route': fullRoute === '/industries/housing' }"
				[routerLink]="['/industries/housing']"
				routerLinkActive="router-link-active"
				>HOUSING</a
			>
		</div>
	</div>

	<div class="dropdown">
		<span class="nav-titles" [ngClass]="{ 'selected-route': mainRoute === '/services' }">SERVICES</span>
		<div class="dropdown-content">
			<a
				[ngClass]="{ 'selected-route': fullRoute === '/services' }"
				[routerLink]="['/services']"
				routerLinkActive="router-link-active"
				>SERVICES PAGE</a
			>
			<a
				[ngClass]="{ 'selected-route': fullRoute === '/services/technical-solutions' }"
				[routerLink]="['/services/technical-solutions']"
				routerLinkActive="router-link-active"
				>TECHNICAL SOLUTIONS</a
			>
			<a
				[ngClass]="{ 'selected-route': fullRoute === '/services/online-marketing' }"
				[routerLink]="['/services/online-marketing']"
				routerLinkActive="router-link-active"
				>ONLINE MARKETING</a
			>
			<a
				[ngClass]="{ 'selected-route': fullRoute === '/services/digital-content' }"
				[routerLink]="['/services/digital-content']"
				routerLinkActive="router-link-active"
				>DIGITAL CONTENT</a
			>
		</div>
	</div>

	<a
		class="nav-titles"
		[ngClass]="{ 'selected-route': fullRoute === '/free-consultation' }"
		[routerLink]="['/free-consultation']"
		routerLinkActive="router-link-active"
		>FREE CONSULTATION</a
	>
	<a
		class="nav-titles"
		[ngClass]="{ 'selected-route': fullRoute === '/client-portal' }"
		[routerLink]="['/']"
		routerLinkActive="router-link-active"
		>CLIENT PORTAL</a
	>
</div>
<div style="height: 7rem"></div>
