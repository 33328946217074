import { Component, Input } from "@angular/core";

@Component({
	selector: "cm-label",
	template:
		'<label class="control-label" [for]="forid">{{text}}<span *ngIf="required" class="required"> (Required)</span></label>',
	styles: [
		".required { font-weight: normal; font-style: italic; font-size: 0.8rem; } label{font-weight: bold;} .control-label{padding-right: 5px;}",
	],
})
export class LabelComponent {
	@Input() text!: string;
	@Input() forid: string | null = null;
	@Input() required: boolean = false;
}
