import { NgModule } from "@angular/core";
import { DropzoneComponent } from "./components/dropzone.component";
import { JsonLdComponent } from "./components/json-ld.component";
import { AutofocusDirective } from "./directives/autofocus.directive";
import { ImgDirective } from "./directives/img.directive";
import { VarDirective } from "./directives/var.directive";
import { DatePipe } from "./pipes/date.pipe";
import { ImagePipe } from "./pipes/image.pipe";
import { PhonePipe } from "./pipes/phone.pipe";

@NgModule({
	declarations: [
		JsonLdComponent,
		VarDirective,
		DatePipe,
		AutofocusDirective,
		DropzoneComponent,
		ImgDirective,
		ImagePipe,
		PhonePipe,
	],
	exports: [
		JsonLdComponent,
		VarDirective,
		DatePipe,
		AutofocusDirective,
		DropzoneComponent,
		ImgDirective,
		ImagePipe,
		PhonePipe,
	],
})
export class UtilModule {}
