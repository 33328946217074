export const months = [
	"January",
	"February",
	"March",
	"April",
	"May",
	"June",
	"July",
	"August",
	"September",
	"October",
	"November",
	"December",
];
export const monthsShort = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
export const weekdays = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];

export function parseDate(input: string, format: string = "YYYY-MM-DD"): Date {
	let year: number | undefined;
	let monthIndex: number | undefined;
	let day: number | undefined;
	let hours: number | undefined;
	let minutes: number | undefined;
	let seconds: number | undefined;
	// let milliseconds: number | undefined;

	function advance(inputLength: number, formatLength: number) {
		input = input.slice(inputLength);
		format = format.slice(formatLength);
	}

	const dateParsers: [string, () => void][] = [
		[
			"YYYY",
			() => {
				const match = /^\d\d\d\d/.exec(input);
				if (!match) {
					return parseError();
				}
				year = parseInt(match[0]);
				advance(4, 4);
			},
		],
		[
			"YY",
			() => {
				const match = /^\d\d/.exec(input);
				if (!match) {
					return parseError();
				}
				year = parseInt(match[0]);
				advance(2, 2);
			},
		],
		[
			"MMMM",
			() => {
				monthIndex = getStartsWithIndex(input, months);
				if (monthIndex < 0) {
					return parseError();
				}
				advance(months[monthIndex].length, 4);
			},
		],
		[
			"MMM",
			() => {
				monthIndex = getStartsWithIndex(input, monthsShort);
				if (monthIndex < 0) {
					return parseError();
				}
				advance(3, 3);
			},
		],
		[
			"MM",
			() => {
				const match = /^\d\d/.exec(input);
				if (!match) {
					return parseError();
				}
				monthIndex = parseInt(match[0]) - 1;
				advance(2, 2);
			},
		],
		[
			"M",
			() => {
				const match = /^\d\d?/.exec(input);
				if (!match) {
					return parseError();
				}
				monthIndex = parseInt(match[0]) - 1;
				advance(match[0].length, 1);
			},
		],
		[
			"DD",
			() => {
				const match = /^\d\d/.exec(input);
				if (!match) {
					return parseError();
				}
				day = parseInt(match[0]);
				advance(2, 2);
			},
		],
		[
			"D",
			() => {
				const match = /^\d\d?/.exec(input);
				if (!match) {
					return parseError();
				}
				day = parseInt(match[0]);
				advance(match[0].length, 1);
			},
		],
		[
			"HH",
			() => {
				const match = /^\d\d/.exec(input);
				if (!match) {
					return parseError();
				}
				hours = parseInt(match[0]);
				advance(2, 2);
			},
		],
		[
			"H",
			() => {
				const match = /^\d\d?/.exec(input);
				if (!match) {
					return parseError();
				}
				hours = parseInt(match[0]);
				advance(match[0].length, 1);
			},
		],
		[
			"hh",
			() => {
				const match = /^\d\d/.exec(input);
				if (!match) {
					return parseError();
				}
				hours = parseInt(match[0]);
				advance(2, 2);
			},
		],
		[
			"h",
			() => {
				const match = /^\d\d?/.exec(input);
				if (!match) {
					return parseError();
				}
				hours = parseInt(match[0]);
				advance(match[0].length, 1);
			},
		],
		[
			"mm",
			() => {
				const match = /^\d\d/.exec(input);
				if (!match) {
					return parseError();
				}
				minutes = parseInt(match[0]);
				advance(2, 2);
			},
		],
		[
			"m",
			() => {
				const match = /^\d\d?/.exec(input);
				if (!match) {
					return parseError();
				}
				minutes = parseInt(match[0]);
				advance(match[0].length, 1);
			},
		],
		[
			"ss",
			() => {
				const match = /^\d\d/.exec(input);
				if (!match) {
					return parseError();
				}
				seconds = parseInt(match[0]);
				advance(2, 2);
			},
		],
		[
			"s",
			() => {
				const match = /^\d\d?/.exec(input);
				if (!match) {
					return parseError();
				}
				seconds = parseInt(match[0]);
				advance(match[0].length, 1);
			},
		],
	];

	outer: while (format.length > 0) {
		if ((format[0] >= "A" && format[0] <= "Z") || (format[0] >= "a" && format[0] <= "z")) {
			for (const [key, parser] of dateParsers) {
				if (format.startsWith(key)) {
					parser();
					continue outer;
				}
			}
		}

		if (input[0] === format[0]) {
			advance(1, 1);
		} else {
			return parseError();
		}
	}

	const now = new Date();
	return new Date(
		year || now.getFullYear(),
		monthIndex || now.getMonth(),
		day || 1,
		hours || 0,
		minutes || 0,
		seconds || 0,
	);
}

function getStartsWithIndex(input: string, strings: string[]) {
	// tslint:disable-next-line:prefer-for-of
	for (let i = 0; i < strings.length; i++) {
		const str = strings[i];

		if (input.startsWith(str)) {
			return i;
		}
	}

	return -1;
}

function parseError(): never {
	throw new Error("failed to parse date");
}
