import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { CacheService, Service } from "@core/app/cache.service";
import { FieldOption } from "@core/app/form/field";
import { UserService } from "@core/app/user.service";
import { format, parseISO } from "date-fns-2";
import { ToastrService } from "ngx-toastr";
import { BehaviorSubject, combineLatest, Observable } from "rxjs";
import { debounceTime, filter, first, map, shareReplay, switchMap, tap } from "rxjs/operators";

@Injectable({
	providedIn: "root",
})
export class CrmQueuesService {
	cache: Service;
	constructor(
		private http: HttpClient,
		private toastrService: ToastrService,
		cacheService: CacheService,
		private userService: UserService,
	) {
		this.cache = cacheService.init("localStorage");
	}

	updateBS = new BehaviorSubject(null);
	queueCatBS = new BehaviorSubject<number | null>(null);
	completedBS = new BehaviorSubject(false);
	completedCustomerIdBS = new BehaviorSubject<any>(null);
	completedStartBS = new BehaviorSubject<any>(null);
	completedEndBS = new BehaviorSubject<any>(null);
	cancelledBS = new BehaviorSubject(false);
	cancelledCustomerIdBS = new BehaviorSubject<any>(null);
	cancelledStartBS = new BehaviorSubject<any>(null);
	cancelledEndBS = new BehaviorSubject<any>(null);
	customerIdBS = new BehaviorSubject<any>(null);
	queueItemOfIdBS = new BehaviorSubject<any>(null);
	empIdBS = new BehaviorSubject<any>("");
	changeDateBS = new BehaviorSubject(false);
	sentToClientBS = new BehaviorSubject(false);
	approvalBS = new BehaviorSubject(false);
	tagUpdateBS = new BehaviorSubject<null>(null);

	readonly pageSize = 25;
	pageBS = new BehaviorSubject<number>(1);
	page$: Observable<number> = this.pageBS.asObservable();

	admin$ = this.userService.permissions$.pipe(
		map((perms) => perms.hasPermission(["queue", "admin"])),
		shareReplay(1),
	);

	queueCats$ = this.http.post("/api/statement/GetQueueTags", {}).pipe(
		tap((response: any) => {
			let queueTagId: number | null;
			if (this.cache.has("queueTagId")) {
				queueTagId = this.cache.get("queueTagId");
			} else if (response.results[0]) {
				queueTagId = response.results[0].queue_tagid;
				this.cache.set("queueTagId", queueTagId);
			} else {
				queueTagId = null;
			}
			this.queueCatBS.next(queueTagId);
		}),
		map((response: any) => {
			const queueCategories = new Map<number, string>();
			response.results.forEach((row: any) => {
				queueCategories.set(row.queue_tagid, row.queue_tag);
			});
			return queueCategories;
		}),
		shareReplay(1),
	);

	queueTags$ = this.http.post("/api/statement/GetQueueTags", {}).pipe(
		map((response: any) => response.results.map((row: any) => new FieldOption(row.queue_tagid, row.queue_tag))),
		shareReplay(1),
	);

	itemQueueTagsField$ = this.http.post("/api/statement/GetItemQueueTags", {}).pipe(
		map((response: any) => response.results.map((row: any) => new FieldOption(row.queue_tagid, row.queue_tag))),
		shareReplay(1),
	);

	stringQueueTags$ = this.http.post("/api/statement/GetQueueTags", {}).pipe(
		map((response: any) =>
			response.results.map((row: any) => new FieldOption(row.queue_tagid.toString(), row.queue_tag)),
		),
		shareReplay(1),
	);

	queuePriorities$ = this.http.post("/api/statement/GetQueuePriorities", {}).pipe(
		map((response: any) => {
			const ret = new Map<number, string>();
			response.results.map((row: any) => {
				ret.set(row.queue_item_priorityid, row.queue_item_priority);
			});
			return ret;
		}),
		shareReplay(1),
	);

	queueTagCount$ = combineLatest([
		this.queueCatBS,
		this.updateBS,
		this.completedBS,
		this.completedStartBS,
		this.completedEndBS,
		this.cancelledBS,
		this.cancelledStartBS,
		this.cancelledEndBS,
		this.customerIdBS,
	]).pipe(
		filter(([queue_tagid]: any) => queue_tagid),
		tap(([queue_tagid]: any) => {
			this.cache.set("queueTagId", queue_tagid);
		}),
		switchMap(([queue_tagid, _, completed, start, end, cancelled, cancelStart, cancelEnd, customerid]: any) =>
			this.http
				.post("/api/statement/GetQueueItemCounts", {
					vars: {
						queue_tagid,
						completed: completed ? true : undefined,
						cancelled: cancelled ? true : undefined,
						customerid: customerid ? customerid : undefined,
						start: completed && start ? start : cancelled && cancelStart ? cancelStart : undefined,
						end: completed && end ? end : cancelled && cancelEnd ? cancelEnd : undefined,
					},
				})
				.pipe(shareReplay(1)),
		),
		map((response: any) => {
			const ret = new Map<number, string>();
			response.results.map((row: any) => {
				ret.set(parseInt(row.queue_tagid), row.itemCount);
			});
			return ret;
		}),
		shareReplay(1),
	);

	empQueueItemCount$ = combineLatest([
		this.queueCatBS,
		this.updateBS,
		this.completedBS,
		this.completedStartBS,
		this.completedEndBS,
		this.cancelledBS,
		this.cancelledStartBS,
		this.cancelledEndBS,
		this.customerIdBS,
	]).pipe(
		filter(([queue_tagid]: any) => queue_tagid),
		tap(([queue_tagid]: any) => {
			this.cache.set("queueTagId", queue_tagid);
		}),
		switchMap(([queue_tagid, _, completed, start, end, cancelled, cancelStart, cancelEnd, customerid]: any) =>
			this.http
				.post("/api/statement/GetEmpQueueItemCount", {
					vars: {
						queue_tagid,
						completed: completed ? true : undefined,
						cancelled: cancelled ? true : undefined,
						customerid: customerid ? customerid : undefined,
						start: completed && start ? start : cancelled && cancelStart ? cancelStart : undefined,
						end: completed && end ? end : cancelled && cancelEnd ? cancelEnd : undefined,
					},
				})
				.pipe(shareReplay(1)),
		),
		map((response: any) => {
			const ret = new Map<number, number>();
			let all = 0;
			response.results.map((row: any) => {
				all += parseInt(row.itemCount);
				ret.set(row.empid ? parseInt(row.empid) : 0, parseInt(row.itemCount));
			});
			ret.set(-1, all);
			return ret;
		}),
		shareReplay(1),
	);

	sentToClientCount$ = combineLatest([this.queueCatBS, this.updateBS, this.customerIdBS]).pipe(
		filter(([queue_tagid]: any) => queue_tagid),
		tap(([queue_tagid]: any) => {
			this.cache.set("queueTagId", queue_tagid);
		}),
		switchMap(([queue_tagid, _, customerid]: any) =>
			this.http
				.post("/api/statement/GetSentToClient", {
					vars: {
						queue_tagid,
						customerid: customerid ? customerid : undefined,
					},
				})
				.pipe(shareReplay(1)),
		),
		map((response: any) => (response.results[0] ? response.results[0].itemCount : 0)),
		shareReplay(1),
	);

	approvalCount$ = combineLatest([this.queueCatBS, this.updateBS, this.customerIdBS]).pipe(
		filter(([queue_tagid]: any) => queue_tagid),
		tap(([queue_tagid]: any) => {
			this.cache.set("queueTagId", queue_tagid);
		}),
		switchMap(([queue_tagid, _, customerid]: any) =>
			this.http
				.post("/api/statement/GetClientApprovalCount", {
					vars: {
						queue_tagid,
						customerid: customerid ? customerid : undefined,
					},
				})
				.pipe(shareReplay(1)),
		),
		map((response: any) => (response.results[0] ? response.results[0].itemCount : 0)),
		shareReplay(1),
	);

	queueStatuses$ = this.queueCatBS.pipe(
		switchMap((queue_tagid) =>
			this.http.post("/api/statement/GetQueueStatuses", { vars: { queue_tagid } }).pipe(
				map((response: any) => {
					const ret = new Map<number, string>();
					response.results.map((row: any) => {
						ret.set(row.queue_item_statusid, row.queue_item_status);
					});
					return ret;
				}),
				shareReplay(1),
			),
		),
		shareReplay(1),
	);

	emps$ = this.queueCatBS.pipe(
		switchMap((queue_tagid) =>
			this.http.post("/api/statement/GetQueueStaff", { vars: { queue_tagid } }).pipe(
				map((response: any) => {
					const ret = new Map<number, string>();
					response.results.map((row: any) => {
						ret.set(row.empid, row.first_name + " " + row.last_name);
					});
					return ret;
				}),
				shareReplay(1),
			),
		),
		shareReplay(1),
	);

	getEmps(queue_tagid: number) {
		return this.http.post("/api/statement/GetQueueStaff", { vars: { queue_tagid } }).pipe(
			map((response: any) => {
				const ret = new Map<number, string>();
				response.results.map((row: any) => {
					ret.set(row.empid, row.first_name + " " + row.last_name);
				});
				return ret;
			}),
			shareReplay(1),
		);
	}

	queueEmps$ = this.queueCatBS.pipe(
		switchMap((queue_tagid) =>
			this.http.post("/api/statement/GetQueueStaff", { vars: { queue_tagid } }).pipe(
				map((response: any) =>
					response.results.map(
						(row: any) => new FieldOption(row.empid, row.first_name + " " + row.last_name),
					),
				),
				shareReplay(1),
			),
		),
		shareReplay(1),
	);

	empList$ = this.http.post("/api/statement/GetQueueStaff", {}).pipe(
		map((response: any) => {
			const ret = new Map<number, string>();
			response.results.map((row: any) => {
				ret.set(row.empid, row.first_name + " " + row.last_name);
			});
			return ret;
		}),
		shareReplay(1),
	);

	customers$ = this.updateBS.pipe(
		switchMap(() =>
			this.http.post("/api/statement/GetCustomers", { vars: { accounting: true } }).pipe(
				map((response: any) => {
					const ret = new Map<number, string>();
					response.results.map((row: any) => {
						ret.set(row.customerid, row.customerName);
					});
					return ret;
				}),
				shareReplay(1),
			),
		),
		shareReplay(1),
	);

	custs$ = this.http.post("/api/statement/GetCustomers", { vars: { accounting: true } }).pipe(
		map((response: any) => response.results),
		shareReplay(1),
	);

	hiddenCusts$ = this.http.post("/api/statement/GetCustomers", { vars: { accounting: true, hide: 1 } }).pipe(
		map((response: any) => response.results),
		shareReplay(1),
	);

	queueCusts$ = this.http.post("/api/statement/GetCustomers", { vars: { queueItems: true, accounting: true } }).pipe(
		map((response: any) => response.results),
		shareReplay(1),
	);

	vendors$ = this.updateBS.pipe(
		switchMap(() =>
			this.http.post("/api/statement/GetVendors", {}).pipe(
				map((response: any) => {
					const ret = new Map<number, string>();
					response.results.map((row: any) => {
						ret.set(row.vendorid, row.vendor);
					});
					return ret;
				}),
				shareReplay(1),
			),
		),
		shareReplay(1),
	);

	queueItems$: Observable<any> = combineLatest([
		this.queueCatBS,
		this.updateBS,
		this.completedBS,
		this.completedStartBS,
		this.completedEndBS,
		this.cancelledBS,
		this.cancelledStartBS,
		this.cancelledEndBS,
		this.customerIdBS,
		this.empIdBS,
		this.sentToClientBS,
		this.approvalBS,
	]).pipe(
		debounceTime(0),
		filter(([queue_tagid]: any) => queue_tagid),
		tap(([queue_tagid]: any) => {
			this.cache.set("queueTagId", queue_tagid);
		}),
		switchMap(
			([
				queue_tagid,
				_,
				completed,
				start,
				end,
				cancelled,
				cancelStart,
				cancelEnd,
				customerid,
				empid,
				sentToClient,
				approval,
			]: any) =>
				this.http.post("/api/statement/GetQueueItems", {
					vars: {
						queue_tagid,
						completed: completed ? true : undefined,
						approval: approval ? true : undefined,
						cancelled: cancelled ? true : undefined,
						sentToClient: sentToClient ? true : undefined,
						customerid: customerid ? customerid : undefined,
						empid: empid !== "" && empid !== "all" ? empid : undefined,
						seeAll: empid === "all" ? true : undefined,
						start: completed && start ? start : cancelled && cancelStart ? cancelStart : undefined,
						end: completed && end ? end : cancelled && cancelEnd ? cancelEnd : undefined,
					},
				}),
		),
		map((response: any) => response.results.map((row: any) => this.processQueueItem(row))),
		shareReplay(1),
	);
	queueSubItems$: Observable<any> = combineLatest([
		this.queueCatBS,
		this.updateBS,
		this.completedBS,
		this.completedStartBS,
		this.completedEndBS,
		this.cancelledBS,
		this.cancelledStartBS,
		this.cancelledEndBS,
		this.customerIdBS,
		this.empIdBS,
		this.sentToClientBS,
		this.approvalBS,
		this.queueItemOfIdBS,
	]).pipe(
		filter(([queue_tagid]: any) => queue_tagid),
		tap(([queue_tagid]: any) => {
			this.cache.set("queueTagId", queue_tagid);
		}),
		switchMap(
			([
				queue_tagid,
				_,
				completed,
				start,
				end,
				cancelled,
				cancelStart,
				cancelEnd,
				customerid,
				empid,
				sentToClient,
				approval,
				queueItemOfId,
			]: any) =>
				this.http.post("/api/statement/GetQueueItems", {
					vars: {
						queue_tagid,
						completed: completed ? true : undefined,
						approval: approval ? true : undefined,
						cancelled: cancelled ? true : undefined,
						sentToClient: sentToClient ? true : undefined,
						customerid: customerid ? customerid : undefined,
						empid: empid !== "" && empid !== "all" ? empid : undefined,
						seeAll: empid === "all" ? true : undefined,
						start: completed && start ? start : cancelled && cancelStart ? cancelStart : undefined,
						end: completed && end ? end : cancelled && cancelEnd ? cancelEnd : undefined,
						queue_item_ofid: queueItemOfId,
					},
				}),
		),
		map((response: any) => response.results.map((row: any) => this.processQueueItem(row))),
		shareReplay(1),
	);

	private processQueueItem(row: any) {
		const queues = row.queues.split("|");
		const map = new Map<number, boolean>();
		for (const queue of queues) {
			const item = queue.split("~");
			map.set(parseInt(item[0]), parseInt(item[1]) === 8);
		}
		return {
			...row,
			item_cost: parseFloat(row.item_cost),
			standard_markup: parseFloat(row.standard_markup),
			additional_markup: parseFloat(row.additional_markup),
			shipping: parseFloat(row.shipping),
			final_amount: parseFloat(row.final_amount),
			commission_amount: parseFloat(row.commission_amount),
			trade: row.trade === 1,
			added_at: row.added_at ? row.added_at.replace(" ", "T") + "Z" : null,
			note_added_at: row.note_added_at ? row.note_added_at.replace(" ", "T") + "Z" : null,
			needed_by: row.needed_by ? row.needed_by.replace(" ", "T") : null,
			scheduled_for: row.scheduled_for ? row.scheduled_for.replace(" ", "T") : null,
			task_start: row.task_start ? row.task_start.replace(" ", "T") : null,
			task_end: row.task_end ? row.task_end.replace(" ", "T") : null,
			project_start: row.project_start ? row.project_start.replace(" ", "T") : null,
			project_end: row.project_end ? row.project_end.replace(" ", "T") : null,
			queues: map,
		};
	}

	queueCalItems$: Observable<any> = combineLatest([
		this.queueCatBS,
		this.updateBS,
		this.completedBS,
		this.cancelledBS,
		this.customerIdBS,
		this.empIdBS,
		this.sentToClientBS,
	]).pipe(
		filter(([queue_tagid]: any) => queue_tagid),
		tap(([queue_tagid]: any) => {
			this.cache.set("queueTagId", queue_tagid);
		}),
		switchMap(([queue_tagid, _, completed, cancelled, customerid, empid, sentToClient]: any) =>
			this.http.post("/api/statement/GetQueueItems", {
				vars: {
					queue_tagid,
					calendar: true,
					completed: completed ? true : undefined,
					cancelled: cancelled ? true : undefined,
					sentToClient: sentToClient ? true : undefined,
					customerid: customerid ? customerid : undefined,
					empid: empid !== "" && empid !== "all" ? empid : undefined,
					seeAll: empid === "all" ? true : undefined,
				},
			}),
		),
		map((response: any) => response.results.map((row: any) => this.processQueueItem(row))),
		shareReplay(1),
	);

	taxCodes$ = this.http.post("/api/quickbooks/taxCodes", {}).pipe(
		map((response: any) => {
			const ret = new Map<number, string>();
			response.taxCodes.map((row: any) => {
				ret.set(row.Id, row.Name);
			});
			return ret;
		}),
		shareReplay(1),
	);

	itemQueueTags$ = this.tagUpdateBS.pipe(
		switchMap(() => this.http.post("/api/statement/GetItemQueueTags", {})),
		map((response: any) => response.results),
		shareReplay(1),
	);

	updateEmp(empid: string, queue_item_queue_tagid: number) {
		this.http
			.post("/api/statement/UpdateQueueItemQueueTagEmp", {
				vars: { empid: empid !== "" ? empid : null, queue_item_queue_tagid },
			})
			.subscribe(() => {
				this.http
					.post("/api/quickbooks/index/queue-item", { queue_item_queue_tagids: [queue_item_queue_tagid] })
					.subscribe();

				this.toastrService.success("Employee Updated");
				this.updateBS.next(null);
			});
	}

	updatePriority(queue_item_priorityid: number, queue_itemid: number) {
		this.http
			.post("/api/statement/UpdateQueueItemPriority", {
				vars: { queue_item_priorityid, queue_itemid },
			})
			.subscribe(() => {
				this.http.post("/api/quickbooks/index/queue-item", { queue_itemids: [queue_itemid] }).subscribe();

				this.toastrService.success("Priority Updated");
				this.updateBS.next(null);
			});
	}

	updateStatus(queue_item_statusid: string, row: any) {
		this.queueCats$.pipe(first()).subscribe((queueCats) => {
			this.http
				.post("/api/statement/UpdateQueueItemQueueTagStatus", {
					vars: { queue_item_statusid, queue_item_queue_tagid: row.queue_item_queue_tagid },
				})
				.subscribe(() => {
					this.http
						.post("/api/quickbooks/index/queue-item", { queue_itemids: [row.queue_itemid] })
						.subscribe();

					if (parseInt(queue_item_statusid) === 4) {
						this.http
							.post("/api/quickbooks/notifyQC", {
								queue_item_queue_tagid: row.queue_item_queue_tagid,
							})
							.subscribe();
					}
					if (parseInt(queue_item_statusid) === 18) {
						this.http
							.post("/api/quickbooks/notifyReview", {
								queue_item_queue_tagid: row.queue_item_queue_tagid,
							})
							.subscribe();
					}
					this.queueStatuses$.pipe(first()).subscribe((statuses) => {
						this.http
							.post("/api/quickbooks/create/queue-note", {
								queue_itemid: row.queue_itemid,
								note:
									queueCats.get(row.queue_tagid) +
									" status changed to " +
									statuses.get(parseInt(queue_item_statusid)),
								forClient: false,
								dontSend: true,
								statusNote: true,
								files: [],
								images: [],
							})
							.subscribe();
					});
					this.toastrService.success("Status Updated");
					this.updateBS.next(null);
				});
		});
	}

	updateCategory(queue_tagid: number, queue_item_queue_tagid: number) {
		const res = this.http.post("/api/statement/UpdateQueueItemQueueTagTag", {
			vars: { queue_tagid, queue_item_queue_tagid },
		});

		res.subscribe(() =>
			this.http
				.post("/api/quickbooks/index/queue-item", { queue_item_queue_tagids: [queue_item_queue_tagid] })
				.subscribe(),
		);

		return res;
	}

	addCategory(queue_tagid: number | string, queue_item_queue_tagid: number) {
		const res = this.http.post("/api/statement/InsertQueueItemQueueTagTag", {
			vars: { queue_tagid, queue_item_queue_tagid },
		});

		res.subscribe(() =>
			this.http
				.post("/api/quickbooks/index/queue-item", { queue_item_queue_tagids: [queue_item_queue_tagid] })
				.subscribe(),
		);

		return res;
	}

	addEmp(empid: number | string, queue_itemid: number) {
		const res = this.http.post("/api/statement/InsertQueueItemAddEmp", {
			vars: { empid, queue_itemid },
		});

		res.subscribe(() =>
			this.http.post("/api/quickbooks/index/queue-item", { queue_itemids: [queue_itemid] }).subscribe(),
		);

		return res;
	}

	addQueueItem(
		customerid: any,
		contactName: any,
		item: any,
		note: any,
		neededBy: any,
		scheduledFor: any,
		queue_tagid: any,
		queue_item_priorityid: any,
		files: any,
		images: any,
		notUrgent: boolean = false,
		emps: any = {},
		queue_item_tagid: any = null,
	) {
		return this.http
			.post("/api/quickbooks/create/queue-item", {
				customerid,
				contactName,
				item,
				note,
				neededBy,
				scheduledFor,
				queue_tagid,
				queue_item_priorityid,
				files,
				images,
				notUrgent,
				emps,
				queue_item_tagid,
			})
			.pipe(
				tap(() => {
					this.updateBS.next(null);
				}),
			);
	}

	addQueueSubItem(
		queue_itemid: any,
		item: any,
		note: any,
		neededBy: any,
		scheduledFor: any,
		queue_tagid: any,
		queue_item_priorityid: any,
		files: any,
		images: any,
	) {
		return this.http
			.post("/api/quickbooks/create/queue-sub-item", {
				queue_itemid,
				item,
				note,
				neededBy,
				scheduledFor,
				queue_tagid,
				queue_item_priorityid,
				files,
				images,
			})
			.pipe(
				tap(() => {
					this.updateBS.next(null);
				}),
			);
	}
	addQueueDefaultItem(item: any, note: any, queue_item_priorityid: any, files: any, images: any, items: any) {
		return this.http
			.post("/api/quickbooks/create/queue-default-item", {
				item,
				note,
				queue_item_priorityid,
				files,
				images,
				items,
			})
			.pipe(
				tap(() => {
					this.updateBS.next(null);
				}),
			);
	}
	updateQueueDefaultItem(item: any) {
		return this.http.post("/api/quickbooks/save/default-task", item).pipe(
			tap(() => {
				this.updateBS.next(null);
			}),
		);
	}
	addQueueTasks(projectStart: any, customerid: any, defaultid: any) {
		return this.http
			.post("/api/quickbooks/create/queue-tasks", {
				projectStart,
				customerid,
				defaultid,
			})
			.pipe(
				tap(() => {
					this.updateBS.next(null);
				}),
			);
	}

	addVendor(vendor: any) {
		return this.http
			.post("/api/statement/InsertVendor", {
				vars: {
					vendor,
				},
			})
			.pipe(
				tap(() => {
					this.updateBS.next(null);
				}),
			);
	}

	addExtOrder(data: any) {
		return this.http
			.post("/api/statement/InsertQueueItemOrder", {
				vars: {
					queue_itemid: data.queue_itemid,
					vendorid: data.vendorid,
					order_id: data.order_id ? data.order_id.trim() : null,
					order_link: data.order_link ? data.order_link.trim() : null,
					cost: data.cost,
					order_date: data.order_date,
					qty: data.qty,
				},
			})
			.pipe(
				tap(() => {
					this.updateBS.next(null);
				}),
			);
	}

	updateExtOrder(data: any) {
		return this.http
			.post("/api/statement/UpdateQueueItemOrder", {
				vars: {
					queue_item_orderid: data.queue_item_orderid,
					vendorid: data.vendorid,
					order_id: data.order_id ? data.order_id.trim() : null,
					order_link: data.order_link ? data.order_link.trim() : null,
					cost: data.cost,
					order_date: data.order_date,
					qty: data.qty,
				},
			})
			.pipe(
				tap(() => {
					this.updateBS.next(null);
				}),
			);
	}

	updateQueueItem(data: any) {
		return this.http
			.post("/api/quickbooks/update", {
				queue_itemid: data.queue_itemid,
				needed_by: data.needed_by,
				scheduled_for: data.scheduled_for,
				notify: data.notify,
				review_by_empid: data.review_by_empid,
			})
			.pipe(
				tap(() => {
					this.http
						.post("/api/quickbooks/index/queue-item", { queue_itemids: [data.queue_itemid] })
						.subscribe();

					this.updateBS.next(null);
					if (
						(!data.original_scheduled_for ||
							data.scheduled_for !== data.original_scheduled_for.replace("T", " ")) &&
						data.scheduled_for
					) {
						this.http
							.post("/api/quickbooks/create/queue-note", {
								queue_itemid: data.queue_itemid,
								note:
									"Scheduled For Changed to <span class='text-danger'>" +
									format(parseISO(data.scheduled_for), "M/d/yy h:mm a") +
									"</span>",
								forClient: false,
								files: [],
								images: [],
							})
							.subscribe();
						this.http
							.post("/api/quickbooks/notifyDemo", {
								queue_item_queue_tagid: data.queue_item_queue_tagid,
							})
							.subscribe();
					}
					if (
						(!data.original_needed_by || data.needed_by !== data.original_needed_by.replace("T", " ")) &&
						data.needed_by
					) {
						this.http
							.post("/api/quickbooks/create/queue-note", {
								queue_itemid: data.queue_itemid,
								note:
									"Needed By Changed to <span class='text-danger'>" +
									format(parseISO(data.needed_by), "M/d/yy h:mm a") +
									"</span>",
								forClient: false,
								files: [],
								images: [],
							})
							.subscribe();
					}
				}),
			);
	}

	addQueueRecurringItem(
		customerid: any,
		contactName: any,
		item: any,
		note: any,
		queue_item_priorityid: any,
		queue_tagid: any,
		recurring_start: any,
		taskLength: any,
		recurringSchedule: any,
		notify: any,
	) {
		recurringSchedule = JSON.stringify(recurringSchedule);
		return this.http
			.post("/api/quickbooks/create/recurring-task", {
				customerid,
				contactName,
				item,
				note,
				recurring_start,
				taskLength,
				queue_tagid,
				queue_item_priorityid,
				recurringSchedule,
				notify,
			})
			.pipe(
				tap(() => {
					this.updateBS.next(null);
				}),
			);
	}

	updateQueueRecurringItem(
		queue_itemid: any,
		customerid: any,
		contactName: any,
		item: any,
		note: any,
		queue_item_priorityid: any,
		queue_tagid: any,
		recurring_start: any,
		taskLength: any,
		recurringSchedule: any,
		notify: any,
	) {
		recurringSchedule = JSON.stringify(recurringSchedule);
		return this.http
			.post("/api/quickbooks/update/recurring-task", {
				queue_itemid,
				customerid,
				contactName,
				item,
				note,
				recurring_start,
				taskLength,
				queue_tagid,
				queue_item_priorityid,
				recurringSchedule,
				notify,
			})
			.pipe(
				tap(() => {
					this.updateBS.next(null);
				}),
			);
	}

	createTextLinks(text: string) {
		const urlPattern = /((https?|ftps?):\/\/[^"<\s]+)(?![^<>]*>|[^"]*?<\/a)/gi;
		const urlPattern2 = /<a\s+(?![^<>]*target=)[^<>]*href="((https?|ftps?):\/\/[^"<\s]+)"[^<>]*>(.*?)<\/a>/gi;
		return (text || "")
			.replace(urlPattern, (match: string) => {
				return `<a href="${match}" target="_blank" class="text-info">${match}</a>`;
			})
			.replace(urlPattern2, (match: string, link: string, _: string, linkText: string) => {
				return `<a href="${link}" target="_blank" class="text-info">${linkText}</a>`;
			});
	}

	clipExcessText(text: string, limit: number) {
		if (text.length <= limit) {
			return text;
		}
		const validText = text.slice(0, limit);
		const excessText = text.slice(limit);
		if (excessText.charAt(0) === " ") {
			return validText + "...";
		} else {
			return validText.slice(0, validText.lastIndexOf(" ")) + "...";
		}
	}

	sortClick(column: string, sortColumnBS: BehaviorSubject<null | string>, sortDirectionBS: BehaviorSubject<boolean>) {
		if (sortColumnBS.value === column) {
			sortDirectionBS.next(!sortDirectionBS.value);
		} else {
			sortColumnBS.next(column);
			sortDirectionBS.next(false);
		}
		this.pageBS.next(1);
	}

	insertQueueTag(queue_tag: string) {
		if (!queue_tag) {
			this.toastrService.error("Queue Tag Is Empty. Please Enter A Queue Tag.");
			return;
		}
		this.http.post("/api/statement/InsertQueueTag", { vars: { queue_tag } }).subscribe(
			() => {
				this.toastrService.success("Queue Tag Added");
				this.tagUpdateBS.next(null);
			},
			() => this.toastrService.error("Queue Tag Failed To Add"),
		);
	}

	updateTag(queue_tagid: string, item: any, queue_itemid: any, updateBS: any) {
		console.log(queue_itemid, item, queue_tagid);
		if (queue_tagid && queue_tagid !== "" && queue_tagid !== null && queue_tagid !== "null") {
			if (!item.queue_item_queue_tagid) {
				this.http
					.post("/api/statement/InsertQueueTagQueueItem", {
						vars: { queue_itemid, queue_tagid },
					})
					.subscribe(() => updateBS.next(null));
			} else {
				this.http
					.post("/api/statement/UpdateQueueItemQueueTag", {
						vars: { queue_itemid, queue_item_queue_tagid: item.queue_item_queue_tagid },
					})
					.subscribe(() => updateBS.next(null));
			}
		} else {
			if (item.queue_item_queue_tagid) {
				this.http
					.post("/api/statement/DeleteQueueItemQueueTag", {
						vars: { queue_item_queue_tagid: item.queue_item_queue_tagid },
					})
					.subscribe(() => updateBS.next(null));
			}
		}
	}
}
