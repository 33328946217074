import { Component, ElementRef, EventEmitter, Inject, Output } from "@angular/core";
import { faArrows } from "@fortawesome/pro-solid-svg-icons";

@Component({
	selector: "cm-calculator",
	templateUrl: "./calculator.component.html",
	styleUrls: ["./calculator.component.scss"],
})
export class CalculatorComponent {
	screen = "";
	decimalAdded = false;
	equalsPressed = false;
	moving = false;
	operators = ["+", "-", "x", "÷"];

	faArrows = faArrows;

	@Output() closeCalcEvent = new EventEmitter<boolean>();

	constructor(@Inject(ElementRef) private el: ElementRef) {}

	calculate(input: any) {
		const f: any = { add: "+", sub: "-", div: "/", mlt: "*", mod: "%", exp: "^" };
		let output;

		// Create array for Order of Operation and precedence
		f.ooo = [[[f.mlt], [f.div], [f.mod], [f.exp]], [[f.add], [f.sub]]];
		input = input.replace(/[^0-9%^*/()\-+.]/g, "");

		function calc_internal(a: any, op: any, b: any) {
			a = a * 1;
			b = b * 1;
			switch (op) {
				case f.add:
					return a + b;
				case f.sub:
					return a - b;
				case f.div:
					return a / b;
				case f.mlt:
					return a * b;
				case f.mod:
					return a % b;
				case f.exp:
					return Math.pow(a, b);
				default:
					break;
			}
		}

		for (let i = 0, n = f.ooo.length; i < n; i++) {
			// Regular Expression to look for operators between floating
			// numbers or integers
			const re = new RegExp("(\\d+\\.?\\d*)([\\" + f.ooo[i].join("\\") + "])(\\d+\\.?\\d*)");
			re.lastIndex = 0;
			// Loop while there is still calculation for level of precedence
			while (re.test(input)) {
				output = calc_internal(RegExp.$1, RegExp.$2, RegExp.$3);
				if (isNaN(output) || !isFinite(output)) {
					return output;
				} // exit early if not a number
				input = input.replace(re, output);
			}
		}

		return output;
	}

	calculator(btnVal: any) {
		// Get the input and button values
		this.decimalAdded = false;

		if (btnVal === "C") {
			this.screen = "";
			this.equalsPressed = false;
		} else if (btnVal === "=") {
			let equation = this.screen;
			const lastChar = equation[equation.length - 1];

			// Replace all instances of x and ÷ with * and / respectively. This
			// can be done easily using regex and the 'g' tag which will
			// replace all instances of the matched character/substring
			equation = equation.replace(/x/g, "*").replace(/÷/g, "/");

			// Final thing left to do is checking the last character of the
			// equation. If it's an operator or a decimal, remove it
			if (this.operators.indexOf(lastChar) > -1 || lastChar === ".") {
				equation = equation.replace(/.$/, "");
			}

			if (equation) {
				this.screen = this.calculate(equation);
			}

			this.equalsPressed = true;
		} else if (this.operators.indexOf(btnVal) > -1) {
			// Operator is clicked
			// Get the last character from the equation
			const lastChara = this.screen[this.screen.length - 1];

			// Only add operator if input is not empty and there is no operator
			// at the last
			if (this.screen !== "" && this.operators.indexOf(lastChara) === -1) {
				this.screen = this.screen + btnVal;
			} else if (this.screen === "" && btnVal === "-") {
				this.screen = this.screen + btnVal;
			}
			// Replace the last operator (if exists) with the newly pressed
			// operator
			if (this.operators.indexOf(lastChara) > -1 && this.screen.length > 1) {
				// Here, '.' matches any character while $ denotes the end of
				// string, so anything (will be an operator in this case) at
				// the end of string will get replaced by new operator
				this.screen = this.screen.replace(/.$/, btnVal);
			}
			this.equalsPressed = false;
		} else if (btnVal === ".") {
			if (!this.decimalAdded) {
				this.screen = this.screen + btnVal;
				this.decimalAdded = true;
			}
			this.equalsPressed = false;
		} else {
			if (this.equalsPressed) {
				this.screen = btnVal.toString();
			} else {
				this.screen = this.screen + btnVal.toString();
			}
			this.equalsPressed = false;
		}
	}

	closeCalc() {
		this.closeCalcEvent.next(false);
	}
}
