import { Component, ContentChildren, forwardRef, Input, OnChanges, QueryList, SimpleChanges } from "@angular/core";
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from "@angular/forms";
import { RadioBlockDirective } from "./radio-block.directive";

let nextId = 0;

@Component({
	selector: "cm-radio-blocks",
	template: `
		<label
			*ngFor="let item of items"
			class="label"
			class="radio-item {{ item.class }}"
			[ngClass]="{ active: formControl.value === item.value }"
		>
			<input type="radio" name="radio-blocks-{{ id }}" [formControl]="formControl" [value]="item.value" hidden />
			<ng-container
				*ngTemplateOutlet="item.templateRef; context: { active: formControl.value === item.value }"
			></ng-container>
		</label>
	`,
	styles: [
		`
			:host {
				display: block;
			}

			.label {
				display: block;
				cursor: pointer;
			}
		`,
	],
	providers: [{ provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => RadioBlocksComponent), multi: true }],
})
export class RadioBlocksComponent implements ControlValueAccessor, OnChanges {
	@Input() value: any;

	@ContentChildren(RadioBlockDirective) items?: QueryList<RadioBlockDirective>;

	formControl = new FormControl();
	id = nextId++;

	// tslint:disable-next-line: no-empty
	onChange = (_val: any) => {};
	// tslint:disable-next-line: no-empty
	onTouched = () => {};

	constructor() {
		this.formControl.valueChanges.subscribe((val) => this.onChange(val));
	}

	ngOnChanges(changes: SimpleChanges): void {
		if (changes.value) {
			this.formControl.setValue(changes.value.currentValue, { emitEvent: false });
		}
	}

	writeValue(obj: any): void {
		this.formControl.setValue(obj, { emitEvent: false });
	}

	registerOnChange(fn: any): void {
		this.onChange = fn;
	}

	registerOnTouched(fn: any): void {
		this.onTouched = fn;
	}
}
