import { HttpClient } from "@angular/common/http";
import { Component, Input, OnChanges, SimpleChanges } from "@angular/core";
import { cloneDeep } from "@common/util";
import { faPlusCircle } from "@fortawesome/pro-solid-svg-icons";
import { IStmtResults } from "@model/stmt-results";
import { ToastrService } from "ngx-toastr";
import { BehaviorSubject } from "rxjs";
import { map, switchMap } from "rxjs/operators";

@Component({
	selector: "cm-client-edit-promotions[customerid]",
	template: `
		<p><strong>Promotions:</strong></p>
		<input
			*ngIf="promotionNote$ | async as promotionNote"
			type="number"
			id="promotions-year"
			min="0"
			max="10"
			class="mr-1"
			[ngModel]="promotionNote.customer_note"
			(ngModelChangeDebounced)="saveNote($event, promotionNote.customer_noteid, 16)"
		/>
		<label for="promotions-year">Promotions/year</label><br />
		<div *ngIf="promotions$ | async as promotions">
			<div class="pointer-cursor" (click)="addNew(promotions)">
				<fa-icon [icon]="faPlusCircle"></fa-icon> Add New
			</div>
			<ng-container *ngFor="let promotion of promotions; index as i">
				<div class="row align-items-center">
					<div class="col-12 my-1">
						<label for="event{{ i }}">Event: </label>
						<input type="text" id="event{{ i }}" class="form-control" [(ngModel)]="promotion.promotion" />
					</div>
					<div class="col-12 col-lg-6 my-1">
						<label for="start">Start:</label>
						<input
							type="date"
							id="start{{ i }}"
							class="form-control"
							[(ngModel)]="promotion.promotion_start"
						/>
					</div>
					<div class="col-12 col-lg-6 my-1">
						<label for="end">End:</label>
						<input
							type="date"
							id="end{{ i }}"
							class="form-control"
							[(ngModel)]="promotion.promotion_end"
							name="end"
						/>
					</div>
					<div class="col-12 text-center my-1">
						<button class="btn btn-primary" (click)="savePromotion(promotion)">Save</button>
					</div>
					<hr class="col-11 border-dark" />
				</div>
			</ng-container>
		</div>
	`,
	styles: [
		`
			:host {
				display: block;
			}
		`,
	],
})
export class ClientEditPromotionsComponent implements OnChanges {
	@Input() customerid!: number;

	customeridBS = new BehaviorSubject(0);

	saving = false;

	emptyPromotion = { promotion: "", promotion_start: "", promotion_end: "", promotionid: null };

	faPlusCircle = faPlusCircle;

	promotionNote$ = this.customeridBS.pipe(
		switchMap((customerid) =>
			this.http.post("/api/statement/GetCustomerNotes", {
				vars: { customerid, note_titleid: 16 },
			}),
		),
		map((response: IStmtResults<any>) =>
			response.results && response.results[0]
				? response.results[0]
				: { customer_note: "", customer_noteid: null },
		),
	);
	promotions$ = this.customeridBS.pipe(
		switchMap((customerid) =>
			this.http.post("/api/statement/GetCustomerPromotions", {
				vars: { customerid },
			}),
		),
		map((response: IStmtResults<any>) =>
			response.results
				? response.results.map((row) => ({
						...row,
						promotion_start: row.promotion_start ? row.promotion_start.split(" ")[0] : "",
						promotion_end: row.promotion_end ? row.promotion_end.split(" ")[0] : "",
				  }))
				: [cloneDeep(this.emptyPromotion)],
		),
	);

	constructor(private http: HttpClient, private toastr: ToastrService) {}

	saveNote(note: string, customerNoteId: null | number, note_titleid: number) {
		if (note) {
			if (customerNoteId) {
				this.http
					.post("/api/statement/UpdateCustomerNote", {
						vars: { customer_noteid: customerNoteId, customer_note: note },
					})
					.subscribe(() => this.customeridBS.next(this.customerid));
			} else {
				this.http
					.post("/api/statement/AddCustomerNote", {
						vars: { customerid: this.customerid, note_titleid, customer_note: note },
					})
					.subscribe(() => this.customeridBS.next(this.customerid));
			}
		} else if (customerNoteId) {
			this.http
				.post("/api/statement/DeleteCustomerNote", {
					vars: { customer_noteid: customerNoteId },
				})
				.subscribe(() => this.customeridBS.next(this.customerid));
		}
	}

	savePromotion(promotion: any) {
		if (promotion.promotionid) {
			this.http
				.post("/api/statement/UpdateCustomerPromotion", {
					vars: { promotion },
				})
				.subscribe(() => this.customeridBS.next(this.customerid));
		} else {
			this.http
				.post("/api/statement/AddCustomerPromotion", {
					vars: { customerid: this.customerid, ...promotion },
				})
				.subscribe(() => this.customeridBS.next(this.customerid));
		}
	}

	addNew(promotions: any[]) {
		promotions.push(cloneDeep(this.emptyPromotion));
	}

	ngOnChanges(changes: SimpleChanges): void {
		if (changes.customerid) {
			this.customeridBS.next(changes.customerid.currentValue);
		}
	}
}
