import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { BAccordion } from "./components/b-accordion/b-accordion.component";
import {
	BAccordionPanel,
	BAccordionHeader,
	BAccordionBody,
} from "./components/b-accordion-panel/b-accordion-panel.component";

@NgModule({
	declarations: [BAccordion, BAccordionHeader, BAccordionBody, BAccordionPanel],
	imports: [CommonModule],
	exports: [BAccordion, BAccordionHeader, BAccordionBody, BAccordionPanel],
})
export class BootstrapModule {}
