import { Component, HostListener, Output, EventEmitter, HostBinding } from "@angular/core";
import { iterFiles } from "../common/iter";

@Component({
	selector: "cm-dropzone",
	templateUrl: "./dropzone.component.html",
	styleUrls: ["./dropzone.component.scss"],
})
export class DropzoneComponent {
	@Output() filesDropped = new EventEmitter<File[] | null>();

	@HostBinding("class.dragging") dragging: boolean = false;

	@HostListener("dragover", ["$event"])
	onDragover(event: Event) {
		event.preventDefault();

		this.dragging = true;
	}

	@HostListener("dragend")
	@HostListener("dragleave")
	onDragend() {
		this.dragging = false;
	}

	@HostListener("drop", ["$event"])
	onDrop(event: any) {
		event.preventDefault();

		this.dragging = false;

		let files;
		if (event.dataTransfer!.items) {
			// Use DataTransferItemList interface to access the file(s)

			files = [];

			// tslint:disable-next-line:prefer-for-of
			for (let i = 0; i < event.dataTransfer!.items.length; i++) {
				// If dropped items aren't files, reject them
				if (event.dataTransfer!.items[i].kind === "file") {
					files.push(event.dataTransfer!.items[i].getAsFile()!);
				}
			}
		} else {
			// Use DataTransfer interface to access the file(s)

			files = iterFiles(event.dataTransfer!.files).toArray();
		}

		this.filesDropped.emit(files);
	}
}
