import { Compiler, Component, Inject, Injector, Input, OnDestroy } from "@angular/core";
import { ActivatedRoute, NavigationEnd, NavigationStart, Router } from "@angular/router";
import { iterObj, tuple } from "@core/app/common/iter";
import { UserWidgetService } from "@core/app/user-widget.service";
import { UserService } from "@core/app/user.service";
import { faCogs } from "@fortawesome/pro-solid-svg-icons";
import { IPageData } from "@model/page-data";
import { BehaviorSubject, combineLatest, from, Subscription } from "rxjs";
import { filter, map, switchMap } from "rxjs/operators";

@Component({
	selector: "cm-user-widget",
	templateUrl: "./user-widget.component.html",
	styleUrls: ["./user-widget.component.scss"],
})
export class UserWidgetComponent implements OnDestroy {
	@Input() data: any;

	activeBS = new BehaviorSubject(false);
	showTimeClock: boolean = false;
	initialized: boolean = false;
	record: any = null;
	routeData: any = null;
	faCogs = faCogs;

	contents$ = combineLatest([this.userService.loggedIn$, this.activeBS]).pipe(
		filter(([loggedIn]) => loggedIn),
		switchMap(() =>
			from(
				(async () => {
					const module = await import("../user-widget-contents/user-widget-contents.module");
					const factory = await this.compiler.compileModuleAsync(module.UserWidgetContentsModule);
					factory.create(this.injector);
					return module.UserWidgetContentsModule.entry;
				})(),
			),
		),
	);

	onUpdateWidget: Subscription | null = null;

	constructor(
		public userService: UserService,
		public userWidgetService: UserWidgetService,
		private router: Router,
		private route: ActivatedRoute,
		private compiler: Compiler,
		private injector: Injector,
		@Inject("PAGE_DATA") pageData: IPageData,
	) {
		this.showTimeClock =
			pageData.settings.timeClockSidebar !== "false" &&
			pageData.settings.timeClockSidebar !== "null" &&
			pageData.settings.timeClockSidebar !== null;
	}

	ngOnInit() {
		this.router.events
			.pipe(
				filter((event) => event instanceof NavigationEnd),
				map((event) => {
					let child = this.route.firstChild;
					while (child) {
						if (child.firstChild) {
							child = child.firstChild;
						} else if (child.snapshot.data && child.snapshot.data.routeData) {
							return tuple(event as NavigationEnd, child.snapshot.data);
						} else {
							return tuple(event as NavigationEnd, null);
						}
					}
					return tuple(event as NavigationEnd, null);
				}),
			)
			.subscribe(([_event, data]) => {
				this.userWidgetService.setRouteData(data);
				this.routeData = data;
				this._setupMenu();
			});
		// Reset the user widget nav stuff
		this.router.events
			.pipe(filter((event) => event instanceof NavigationStart))
			.subscribe(() => this.userWidgetService.updateWidget({ reset: true }));
		this.onUpdateWidget = this.userWidgetService
			.onUpdateWidget()
			.subscribe((update: any) => this._onUpdateSpecialRecord(update));
	}

	ngOnDestroy() {
		if (null !== this.onUpdateWidget) {
			this.onUpdateWidget!.unsubscribe();
			this.onUpdateWidget = null;
		}
	}

	/**
	 * Sets up the User Widget Menu
	 *
	 * @returns {Promise<void>}
	 * @private
	 */
	async _setupMenu() {
		if (this.activeBS.value) {
			this.toggleWidget();
		}

		if (!this.record) {
			this.record = {};
		}
	}

	/**
	 * Toggles opening and closing the user widget
	 */
	toggleWidget() {
		this.activeBS.next(!this.activeBS.value);
		this.initialized = true;
		// if (this.active) {
		// 	this.renderer.addClass(this.document.body, "overflow-hidden");
		// } else {
		// 	this.renderer.removeClass(this.document.body, "overflow-hidden");
		// }
	}

	/**
	 * @param event
	 * @param record
	 * @private
	 */
	_onUpdateSpecialRecord(record: any) {
		if (!this.record) {
			this.record = {};
		}

		for (const [k, v] of iterObj(record)) {
			this.record[k] = v;
		}
	}
}
