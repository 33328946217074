<div class="footer-content">
	<div class="left">
		<p>MONDAY - FRIDAY 9 A - 5 P</p>

		<div class="d-flex align-items-center">
			<div>
				<p>Follow us on:</p>
				<div class="social-links">
					<a class="facebook" href="https://www.facebook.com/designforcemarketing/">
						<fa-icon class="social-icon" [icon]="faFacebook"></fa-icon>
					</a>
					<a class="insta" href="https://www.instagram.com/designforce_marketing/">
						<fa-icon class="social-icon" [icon]="faInstagram"></fa-icon>
					</a>
					<a class="linkedin" href="https://www.linkedin.com/company/design-force-marketing/">
						<fa-icon class="social-icon" [icon]="faLinkedinIn"></fa-icon>
					</a>
				</div>
			</div>
			<img
				src="https://dfm-cdn.com/static/12/digital-chamber-logo-2023-2024-t.png"
				alt="Chamber of Commerce"
				class="h-auto"
			/>
		</div>
	</div>
	<div class="center">
		<img src="https://dfm-cdn.com/static/12/long-blue-001-s.png" alt="Design Force Marketing" />
		<a class="links" href="mailto:Sales@DesignForceMarketing.com">Sales@DesignForceMarketing.com</a>
	</div>
	<div class="right">
		<span>
			<a class="links" routerLink="/privacy-policy">PRIVACY POLICY</a> |
			<a class="links" routerLink="/terms-of-use">TERMS OF SERVICE</a>
		</span>
		<meta itemprop="copyrightYear" [content]="copyrightYear" />
		<meta itemprop="copyrightHolder" [content]="dealer" />
		<div>&copy; {{ copyrightYear }} {{ dealer }}</div>
		<p>Powered by <span class="dfm">Design Force Marketing</span></p>
	</div>
</div>
