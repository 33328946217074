import { HttpClient } from "@angular/common/http";
import { Injectable, NgZone } from "@angular/core";
import { NavigationStart, Router } from "@angular/router";
import { BehaviorSubject, EMPTY, Observable, of, Subject } from "rxjs";
import { catchError, filter, map, skip, switchMap, tap } from "rxjs/operators";

(window as any).__NAVIGATE_TO = new Subject<string>();

@Injectable({ providedIn: "root" })
export class PrerenderService {
	transferBS = new BehaviorSubject<{ [key: string]: any } | null>((window as any).__TRANSFER || {});

	constructor(http: HttpClient, router: Router, zone: NgZone) {
		(window as any).__TRANSFER = this.transferBS.value;

		const navStart$ = router.events.pipe(
			filter((e) => e instanceof NavigationStart),
			skip(1),
		);
		if (isPrerendering()) {
			((window as any).__NAVIGATE_TO as Subject<string>).subscribe((url) =>
				zone.run(() => router.navigateByUrl(url)),
			);
			navStart$.subscribe(() => this.transferBS.next({}));
		} else {
			navStart$
				.pipe(
					tap(() => this.transferBS.next(null)),
					switchMap((e) => {
						const url = (e as NavigationStart).url.split("?");
						if (url[1]) {
							return of({});
						}
						return http.get(`${url[0] !== "/" ? url[0] : ""}/data.json`).pipe(catchError(() => of({})));
					}),
				)
				.subscribe((res) => {
					this.transferBS.next(res);
				});
		}
	}

	setState<T>(key: string, val: T) {
		this.transferBS.value![key] = val;
	}

	getState<T>(key: string): Observable<T | undefined> {
		return this.transferBS.pipe(
			filter((transfer) => !!transfer),
			map((transfer) => {
				const ret = transfer![key] as T | undefined;
				delete transfer![key];
				return ret;
			}),
		);
	}

	/// Only for use by the prerenderer
	_serializeToScript() {
		const script = document.getElementById("__transfer") || document.createElement("script");
		script.id = "__transfer";
		script.textContent = `window.__TRANSFER = ${JSON.stringify(this.transferBS.value)};`;
		document.head.append(script);
		(window as any).__TRANSFER = this.transferBS.value;
	}
}

export function isPrerendering(): boolean {
	// return true;
	return navigator.webdriver;
}
