export function Frozen(constructor: Function) {
	Object.freeze(constructor);
	// TODO: https://github.com/Microsoft/TypeScript/issues/14069
	// Object.freeze(constructor.prototype);
}

export function Immutable<T extends { new (...args: any[]): {} }>(constructor: T) {
	return class ImmT extends constructor {
		constructor(...args: any[]) {
			super(...args);
			if (new.target === ImmT) {
				Object.freeze(this);
			}
		}
	};
}

export function StaticImplements<T>() {
	return (constructor: T) => undefined;
}
