import { DOCUMENT } from "@angular/common";
import { Component, Inject, OnInit, Renderer2, RendererFactory2, ViewChild } from "@angular/core";
import { Meta, MetaDefinition, Title } from "@angular/platform-browser";
import { ActivatedRoute, NavigationEnd, Router } from "@angular/router";
import { ImagePipe } from "@common/pipes/image";
import { CacheService } from "@core/app/cache.service";
import { tuple } from "@core/app/common/iter";
import { TrackingService } from "@core/app/tracking.service";
import { IPageData } from "@model/page-data";
import { ToastContainerDirective, ToastrService } from "ngx-toastr";
import { filter, first, map, skip } from "rxjs/operators";
import { environment } from "../../../environments/environment";
import { isPrerendering } from "shared";

@Component({ selector: "cm-root", templateUrl: "./root.component.html", providers: [ImagePipe] })
export class RootComponent implements OnInit {
	bodyClasses: string[] = [];
	siteLogo: any = null;
	pageBodyClass: string | null = null;
	urlBodyClass: string | null = null;
	webPageSchema: string = "http://schema.org/WebPage";

	private renderer: Renderer2;
	private prevUrl: string | null = null;

	@ViewChild(ToastContainerDirective, { static: false }) toastContainer!: ToastContainerDirective;

	constructor(
		private cacheService: CacheService,
		private trackingService: TrackingService,
		private imagePipe: ImagePipe,
		private toastrService: ToastrService,
		private route: ActivatedRoute,
		private meta: Meta,
		private title: Title,
		rendererFactor: RendererFactory2,
		router: Router,
		@Inject(DOCUMENT) private document: Document,
		@Inject("PAGE_DATA") private pageData: IPageData,
	) {
		this.renderer = rendererFactor.createRenderer(null, null);
		this.siteLogo = this.pageData.appInfo.data.siteLogo;
		const siteName = this.pageData.appInfo.data.siteName;

		this.meta.addTags([
			{ property: "og:locale", content: "en_US" },
			{ property: "og:site_name", content: siteName },
			{ property: "fb:app_id", content: this.pageData.fbAppId },
			{ name: "twitter:card", content: "summary_large_image" },
			{ name: "twitter:site", content: "@" + siteName },
			{ name: "twitter:creator", content: "@" + siteName },
			{ name: "msapplication-TileColor", content: "#2B5797" },
			{ name: "msapplication-TileImage", content: "/mstile-144x144.png" },
			{ name: "msapplication-config", content: "/browserconfig.xml" },
		]);

		router.events
			.pipe(
				filter((event) => event instanceof NavigationEnd),
				map((event) => {
					event = event as NavigationEnd;

					let child = this.route.firstChild;
					while (child) {
						if (child.firstChild) {
							child = child.firstChild;
						} else if (child.snapshot.data && child.snapshot.data.routeData) {
							return tuple(event, child.snapshot.data);
						} else {
							return tuple(event, null);
						}
					}
					return tuple(event, null);
				}),
			)
			.subscribe(([_event, data]) => {
				const metaInfo = data!.routeData.stmt ? data!.routeData.stmt.results[0] : null;
				if (metaInfo) {
					const newUrl = metaInfo.content_page_url;
					this.trackingService.trackRouteChange(this.prevUrl, newUrl);
					this.prevUrl = newUrl;
				}

				this.setupMetaInfo(data, metaInfo);
				this.setupAddThis(metaInfo);
				this.setPageBodyClass(data!.bodyClass);
			});

		if (this.pageData.settings.pushwooshAppId) {
			router.events
				.pipe(
					filter((event) => event instanceof NavigationEnd),
					skip(1),
					first(),
				)
				.subscribe(() => this.loadPushwoosh());
		}

		this.setupThemeClass();

		const localCache = this.cacheService.init("localStorage");
		const notify = localCache.get("notify");
		if (notify !== null) {
			setTimeout(() => this.toastrService.info(notify), 2000);
			localCache.remove("notify");
		}
		if (!isPrerendering()) {
			(function (w: any, d, s, l, i) {
				w[l] = w[l] || [];
				w[l].push({ "gtm.start": new Date().getTime(), event: "gtm.js" });
				const f = d.getElementsByTagName(s)[0];
				const j: any = d.createElement(s);
				const dl = l != "dataLayer" ? "&l=" + l : "";
				j.async = true;
				j.src = "https://www.googletagmanager.com/gtm.js?id=" + i + dl;
				f.parentNode!.insertBefore(j, f);
			})(window, document, "script", "dataLayer", this.pageData.appInfo.data.googleAnalyticsCode);
		}
	}

	setBodyClass(className: any, enabled?: boolean) {
		for (const cssClass of this.bodyClasses) {
			this.document.body.classList.remove(cssClass);
		}
		const index = this.bodyClasses.indexOf(className);

		if (!enabled && index !== -1) {
			this.bodyClasses.splice(index, 1);
		} else if (enabled && index === -1 && className.trim() !== "") {
			this.bodyClasses.push(className);
		}

		for (const cssClass of this.bodyClasses) {
			this.document.body.classList.add(cssClass);
		}
	}

	setPageBodyClass(newBodyClass: any) {
		if (this.pageBodyClass) {
			this.setBodyClass(this.pageBodyClass, false);
		}
		this.setBodyClass(newBodyClass, true);
		this.pageBodyClass = newBodyClass;
	}

	setUrlBodyClass(newBodyClass: any) {
		if (this.urlBodyClass) {
			this.setBodyClass(this.urlBodyClass, false);
		}
		this.setBodyClass(newBodyClass, true);
		this.urlBodyClass = newBodyClass;
	}

	setupAddThis(metaInfo: any) {
		if (metaInfo) {
			(window as any).addthis_share = {
				description: metaInfo.meta_desc,
				title: metaInfo.title,
				url: metaInfo.content_page_url,
			};
		}
	}

	setupMetaInfo(data: any, metaInfo: any) {
		const metaTags: { [key: string]: MetaDefinition } = {
			robots: { name: "robots", content: "" },
			ogType: { property: "og:type", content: "" },
			ogTitle: { property: "og:title", content: "" },
			ogDescription: { property: "og:description", content: "" },
			ogUrl: { property: "og:url", content: "" },
			ogImage: { property: "og:image", content: "" },
			ogImageType: { property: "og:image:type", content: "" },
			ogImageAlt: { property: "og:image:alt", content: "" },
			twitterDescription: { name: "twitter:description", content: "" },
			twitterTitle: { name: "twitter:title", content: "" },
			twitterImage: { name: "twitter:image", content: "" },
			keywords: { name: "keywords", content: "" },
			description: { name: "description", content: "" },
		};

		if (metaInfo) {
			const follow = metaInfo.meta_robots_nofollow === 1 ? "nofollow" : "follow";
			const index = metaInfo.meta_robots_noindex === 1 || data.routeData.params.page ? "noindex" : "index";
			metaTags.robots.content = `${follow}, ${index}`;

			// TODO: generate title and page_desc correctly in the database so the year doesn't need to be stripped out
			// here
			if (metaInfo.hide_year && metaInfo.hide_year === 1) {
				metaInfo.title = metaInfo.title.replace(metaInfo.model_year, "");
				metaInfo.page_desc = metaInfo.page_desc.replace(metaInfo.model_year, "");
			}

			if (data.routeData.params.page) {
				if (data.routeData.params.page > 1) {
					metaInfo.title += " p. " + data.routeData.params.page;
				}

				metaInfo.meta_desc = "";
				metaInfo.meta_kywd = "";
			}

			this.title.setTitle(metaInfo.title);

			const pageImage = this.imagePipe.transform(metaInfo.img_dir + metaInfo.img_file, "l");
			let fileName = pageImage ? pageImage : this.siteLogo!.url;

			if (fileName.indexOf("-l.") === -1) {
				fileName = fileName.replace(/-i\.|-t\.|-s\.|-m\.|-o\.|-lc\./gi, ".");
				const index = fileName.lastIndexOf(".");
				if (-1 !== index) {
					fileName = fileName.substr(0, index) + "-l" + fileName.substr(index);
				}
			}

			metaTags.ogType.content = metaInfo.og_type || "article";
			metaTags.ogTitle.content = metaInfo.title;
			metaTags.ogDescription.content = metaInfo.meta_desc;
			metaTags.ogUrl.content = `https://${this.pageData.host}${metaInfo.canonical}`;
			metaTags.ogImage.content = fileName;
			metaTags.ogImageAlt.content = metaInfo.img_alt;
			metaTags.twitterDescription.content = metaInfo.meta_desc;
			metaTags.twitterTitle.content = metaInfo.title;
			metaTags.twitterImage.content = fileName;
			metaTags.keywords.content = metaInfo.meta_kywd || "";
			metaTags.description.content = metaInfo.meta_desc || "";

			this.setImageType(fileName, metaTags);

			// The WebPage structured data item needs to change depending on the page
			if (metaInfo.content_page_url === "/about") {
				this.webPageSchema = "http://schema.org/AboutPage";
			} else if (metaInfo.content_page_url === "/contact") {
				this.webPageSchema = "http://schema.org/ContactPage";
			} else {
				this.webPageSchema = "http://schema.org/WebPage";
			}

			if (metaInfo.canonical !== null) {
				this.setUrlBodyClass(metaInfo.canonical.split("/").join(""));
				const canonicalUrl = `https://${this.pageData.host}${metaInfo.canonical}`;
				try {
					const alternate = this.renderer.selectRootElement("link.alternate");
					alternate.setAttribute("href", canonicalUrl);
				} catch (e) {
					const head = this.document.getElementsByTagName("head")[0];
					const link = this.document.createElement("link");
					link.rel = "alternate";
					link.href = canonicalUrl;
					link.setAttribute("class", "alternate");
					head.appendChild(link);
				}
				try {
					const canonical = this.renderer.selectRootElement("link.canonical");
					canonical.setAttribute("href", canonicalUrl);
				} catch (e) {
					const head = this.document.getElementsByTagName("head")[0];
					const link = this.document.createElement("link");
					link.rel = "canonical";
					link.href = canonicalUrl;
					link.setAttribute("class", "canonical");
					head.appendChild(link);
				}
			} else {
				try {
					const item = this.renderer.selectRootElement("link.alternate");
					item.remove();
				} catch (e) {
					// ignore
				}
				try {
					const item = this.renderer.selectRootElement("link.canonical");
					item.remove();
				} catch (e) {
					// ignore
				}
			}

			if (metaInfo.enable_amp === 1 && this.pageData.enableAmp) {
				metaInfo.ampUrl = `https://amp.${this.pageData.host}${metaInfo.canonical}`;
				try {
					const amp = this.renderer.selectRootElement("link.ampUrl");
					amp.setAttribute("href", metaInfo.ampUrl);
				} catch (e) {
					const head = this.document.getElementsByTagName("head")[0];
					const link = this.document.createElement("link");
					link.rel = "amphtml";
					link.href = metaInfo.ampUrl;
					link.setAttribute("class", "ampUrl");
					head.appendChild(link);
				}
			} else {
				try {
					const item = this.renderer.selectRootElement("link.ampUrl");
					item.remove();
				} catch (e) {
					// ignore
				}
			}
		}

		for (const tag of Object.values(metaTags)) {
			this.meta.updateTag(tag);
		}
	}

	setupThemeClass() {
		const domainClass = environment.dealerInfo.name.replace(".", "-");

		this.setBodyClass(domainClass, true);

		const theme = environment.dealerInfo.themeData;
		if (theme && theme.dealerAccent && theme.dealerAccent !== null) {
			this.meta.addTag({ name: "theme-color", content: theme.dealerAccent.bg });

			const css = `.theme-base, .theme.theme-base {
                background-color: ${theme.dealerAccent.base.bg};
                color: ${theme.dealerAccent.base.fg};
            }
            .theme-base-light, .theme.theme-base-light {
                background-color: ${theme.dealerAccent.base.light};
                color: ${theme.dealerAccent.base.fg};
            }
            .theme-base-as-fg, .theme.theme-base-as-fg {
                color: ${theme.dealerAccent.base.bg};
            }
            .theme-bg,.theme.theme-bg,
            .theme-bg-before:before, .theme.theme-bg-before:before,
            .theme-bg-after:after, .theme.theme-bg-after:after {
                background-color: ${theme.dealerAccent.bg} !important;
                border-color: ${theme.dealerAccent.bg} !important;
            }
            .theme-bg-as-fg,.theme.theme-bg-as-fg, .theme-bg-as-fg a,.theme.theme-bg-as-fg a {
                color: ${theme.dealerAccent.bg} !important;
            }
            .theme-bg-hover:hover,.theme.theme-bg-hover:hover {
                background-color: ${theme.dealerAccent.bgHover};
            }
            .theme-bg-link,.theme.theme-bg-link {
                color: ${theme.dealerAccent.fg} !important;
            }
            .theme-bg-link:hover,.theme.theme-bg-link:hover {
                color: ${theme.dealerAccent.fgHover} !important;
                text-decoration: underline;
            }
            .theme-border,.theme.theme-border {
                border-color: ${theme.dealerAccent.bg};
            }
            .theme-border-after:after, .theme.theme-border-after:after,
            .theme-border-before:before, .theme.theme-border-before:before {
                border-color: ${theme.dealerAccent.bg};
            }
            .theme-fg, .theme.theme-fg,
            .theme-fg-before:before, .theme.theme-fg-before:before,
            .theme-fg-after:after, .theme.theme-fg-after:after {
                color: ${theme.dealerAccent.fg} !important;
            }
            .theme-fg-after:after,.theme.theme-fg-after:after,
            .theme-fg-before:before, .theme.theme-fg-before:before {
                color: ${theme.dealerAccent.bg};
            }
            .theme-highlight,.theme.theme-highlight {
                background-color: ${theme.dealerAccent.highlight.bg} !important;
                color: ${theme.dealerAccent.highlight.fg} !important;
            }
            .theme-highlight-as-fg,.theme.theme-highlight-as-fg {
                color: ${theme.dealerAccent.highlight.bg};
            }
            .theme-highlight-border {
                border-color: ${theme.dealerAccent.highlight.bg} !important;
            }
            .theme-link,.theme.theme-link {
                color: ${theme.dealerAccent.bg};
            }
            .theme-link:hover,.theme.theme-link:hover {
                color: ${theme.dealerAccent.bgHover} !important;
            }
            h2, h3, h4, h2 a, h3 a, h4 a {
                color: ${theme.dealerAccent.bg};
            }
            a,a.theme {
                color: #000;
            }
            a:hover,a.theme:hover,a.active:hover,a.theme.active:hover {
                color: ${theme.dealerAccent.bg};
            }
            .background-image {
                background: ${theme.dealerAccent.bg} url(${theme.dealerAccent.homeUrl}) no-repeat center center;
                background-size: cover;
            }
            .btn.btn-accent {
                border: 1px solid transparent;
                background-color: ${theme.dealerAccent.highlight.bg};
                color: ${theme.dealerAccent.highlight.fg};
            }
            .btn.btn-accent:hover {
                background-color: ${theme.dealerAccent.highlight.bgHover};
                color: ${theme.dealerAccent.highlight.fg};
            }
            .crumb-buttons .crumb-link-wrap, .crumb-buttons .crumb-last-element {
                background-color: ${theme.dealerAccent.bg};
                color: ${theme.dealerAccent.fg} !important;
            }
            .card-primary > .card-header {
                background-color: ${theme.dealerAccent.bg} !important;
                border-color: ${theme.dealerAccent.bg} !important;
            }
            .blog-nav .fa-bars {
                color: ${theme.dealerAccent.bg};
            }
            .theme-primary-review-card .rating.theme-primary-review-card-stars{
                color: ${theme.dealerAccent.bg};
            }
            .theme-primary-review-card .cm-star-listing-block{
                text-align:center;
            }
            .theme-primary-review-card .rating-wrapper{
                display:inline-block;
            }
            .theme-primary-review-card .cm-star-listing{
                float:none;
            }
            .border-primary {
                border-color: ${theme.dealerAccent.bg} !important;
            }
            .bg-primary {
                background-color: ${theme.dealerAccent.bg} !important;
            }
            .text-primary {
                color: ${theme.dealerAccent.fg} !important;
            }
            .theme-primary-review-card a, .theme-primary-review-card a:hover{
                color: ${theme.dealerAccent.highlight.bg};
            }`;

			const style = this.document.createElement("style");
			style.type = "text/css";
			style.appendChild(this.document.createTextNode(css));
			this.document.getElementsByTagName("head")[0].appendChild(style);
		}
	}

	setImageType(fileName: string, metaTags: { [key: string]: MetaDefinition }) {
		const ext = fileName.split(".").pop();
		switch (ext) {
			case "jpg":
			case "jpe":
			case "jpeg":
				metaTags.ogImageType.content = "image/jpeg";
				break;
			case "gif":
				metaTags.ogImageType.content = "image/gif";
				break;
			case "png":
				metaTags.ogImageType.content = "image/png";
				break;
			case "webp":
				metaTags.ogImageType.content = "image/webp";
				break;
			case "svg":
				metaTags.ogImageType.content = "image/svg+xml";
				break;
			default:
				metaTags.ogImageType.content = "unknown";
		}
	}

	private loadPushwoosh() {
		const script: any = this.document.createElement("script");
		script.type = "text/javascript";
		script.src = "https://cdn.pushwoosh.com/webpush/v3/pushwoosh-web-notifications.js";
		(window as any).Pushwoosh = (window as any).Pushwoosh || [];
		((window as any).Pushwoosh as any).push([
			"init",
			{
				logLevel: "error",
				applicationCode: this.pageData.settings.pushwooshAppId,
				safariWebsitePushID: "web.push." + this.pageData.host,
				defaultNotificationTitle: this.pageData.appInfo.data.siteName,
				defaultNotificationImage: this.pageData.appInfo.data.siteLogo.url,
				subscribePopup: {
					enable: true, // (boolean) popup activation
					text: "Sign Up For Notifications on Sales and Deals on RVs", // (string) a text to display on the popup
					askLaterButtonText: "Not now", // (string) custom text for the “Ask later” button
					confirmSubscriptionButtonText: "Subscribe", // (string) custom text for the “Subscribe” button
					iconUrl: this.pageData.appInfo.data.siteLogo.url, // (string) custom icon URL
					delay: 30, // (integer) a delay between the page loading and popup appearance
					retryOffset: 604800, // (integer) an offset in seconds to display the popup again
					overlay: false, // (boolean) enables page overlaying when popup is displayed
					position: "top", // (string) position on the page. Possible values: ’top' | 'center' | 'bottom’

					bgColor: "#fff", // (string) popup’s background color
					borderColor: "transparent", // (string) popup’s border color
					boxShadow: "0 3px 6px rgba(0,0,0,0.16)", // (string) popup’s shadow

					textColor: "#000", // (string) popup’s text color
					textSize: "inherit", // (string) popup’s text size
					fontFamily: "inherit", // (string) popup’s text font

					subscribeBtnBgColor: "#4285f4", // (string) “Subscribe” button’s color
					subscribeBtnTextColor: "#fff", // (string) “Subscribe” button text’s color

					askLaterBtnBgColor: "transparent", // (string) “Ask later” button’s color
					askLaterBtnTextColor: "#000", // (string) “Ask later” button text’s color

					theme: "topbar", // or 'topbar'. A popup theme, see the details below
				},
			},
		]);
		this.document.getElementsByTagName("head")[0].appendChild(script);
	}

	ngOnInit() {
		this.toastrService.overlayContainer = this.toastContainer;
	}
}
