import { Observable } from "rxjs";
import { shareReplay } from "rxjs/operators";
import { SMap } from "./smap";

const scripts = new SMap<string, Observable<void>>();

export function loadScript(url: string) {
	return scripts.getOrInsertWith(url, () =>
		new Observable<void>((sub) => {
			const script = document.createElement("script");
			script.onload = () => {
				sub.next();
				sub.complete();
			};
			script.onerror = () => {
				sub.error();
				sub.complete();
			};
			script.src = url;
			document.body.appendChild(script);
		}).pipe(shareReplay(1)),
	);
}
