import { Frozen, Immutable } from "./decorators";

/**
 * Loosely based on [Rust's `Option` type](https://doc.rust-lang.org/1.22.1/std/option/enum.Option.html)
 */
export interface IOption<T> {
	isSome(): this is Some<T>;

	isNone(): this is None<T>;

	asNullable(): T | null;

	/**
	 * If `none`, returns none, otherwise calls `cb` with the wrapped value and returns the result.
	 */
	andThen<U>(cb: (val: T) => IOption<U>): IOption<U>;

	/**
	 * @returns Contained value.
	 * @throws {Error}
	 */
	expect(err: string): T;

	inspect(cb: (val: T) => void): IOption<T>;

	/**
	 * If Some, Maps an Option<T> to Option<U> by applying `cb` to the value, otherwise returns None.
	 */
	map<U>(cb: (val: T) => U): IOption<U>;

	/**
	 * If Some, Maps an Option<T> to Option<U> by applying `cb` to the value, otherwise returns `default`.
	 */
	mapOr<U>(alt: U, cb: (val: T) => U): U;

	/**
	 * If Some, Maps an Option<T> to Option<U> by applying `cb` to the value, otherwise returns `default`.
	 */
	mapOrElse<U>(alt: () => U, cb: (val: T) => U): U;

	/**
	 * If `some`, calls `matcher.some(value)`, otherwise calls `matcher.none()`.
	 *
	 * @returns The result of whichever function it calls.
	 */
	match<U>(matcher: IMatcher<T, U>): U;

	/**
	 * @returns `this` if some, otherwise returns `other`.
	 * @throws {Error}
	 */
	or(other: IOption<T>): IOption<T>;

	/**
	 * @returns `this` if some, otherwise returns the result of `cb`.
	 * @throws {Error}
	 */
	orElse(cb: () => IOption<T>): IOption<T>;

	/**
	 * @returns Contained value.
	 * @throws {Error}
	 */
	unwrap(): T;

	/**
	 * @returns Contained value if some, otherwise returns `alt`.
	 * @throws {Error}
	 */
	unwrapOr<U>(alt: U): T | U;

	/**
	 * @returns Contained value if some, otherwise returns the result of `cb`.
	 * @throws {Error}
	 */
	unwrapOrElse(cb: () => T): T;
}

// @dynamic
@Frozen
@Immutable
export class Some<T> implements IOption<T> {
	val: T;

	constructor(val: T) {
		this.val = val;
	}

	isSome(): this is Some<T> {
		return true;
	}

	isNone(): this is None<T> {
		return false;
	}

	asNullable(): T | null {
		return this.val;
	}

	andThen<U>(cb: (val: T) => IOption<U>): IOption<U> {
		return cb(this.val);
	}

	expect(_err: string): T {
		return this.val;
	}

	inspect(cb: (val: T) => void): IOption<T> {
		cb(this.val);
		return this;
	}

	map<U>(cb: (val: T) => U): IOption<U> {
		return some(cb(this.val));
	}

	mapOr<U>(_alt: U, cb: (val: T) => U): U {
		return cb(this.val);
	}

	mapOrElse<U>(_alt: () => U, cb: (val: T) => U): U {
		return cb(this.val);
	}

	match<U>(matcher: IMatcher<T, U>): U {
		return matcher.some(this.val);
	}

	or(_other: IOption<T>): IOption<T> {
		return this;
	}

	orElse(_cb: () => IOption<T>): IOption<T> {
		return this;
	}

	unwrap(): T {
		return this.val;
	}

	unwrapOr<U>(_alt: U): T {
		return this.val;
	}

	unwrapOrElse(_cb: () => T): T {
		return this.val;
	}
}

@Frozen
@Immutable
export class None<T> implements IOption<T> {
	isSome(): this is Some<T> {
		return false;
	}

	isNone(): this is None<T> {
		return true;
	}

	asNullable(): T | null {
		return null;
	}

	expect(err: string): T {
		throw new Error(err);
	}

	andThen<U>(_cb: (val: T) => IOption<U>): IOption<U> {
		return none();
	}

	inspect(_cb: (val: T) => void): IOption<T> {
		return this;
	}

	map<U>(_cb: (val: T) => U): IOption<U> {
		return none();
	}

	mapOr<U>(alt: U, _cb: (val: T) => U): U {
		return alt;
	}

	mapOrElse<U>(alt: () => U, _cb: (val: T) => U): U {
		return alt();
	}

	match<U>(matcher: IMatcher<T, U>): U {
		return matcher.none();
	}

	or(other: IOption<T>): IOption<T> {
		return other;
	}

	orElse(cb: () => IOption<T>): IOption<T> {
		return cb();
	}

	unwrap(): T {
		throw new Error("Cannot unwrap a None.");
	}

	unwrapOr<U>(alt: U): U {
		return alt;
	}

	unwrapOrElse<T>(cb: () => T): T {
		return cb();
	}
}

export interface IMatcher<T, U> {
	some: (value: T) => U;
	none: () => U;
}

export function option<T>(val: T | null | undefined): IOption<T> {
	return val === null || val === undefined ? none() : some(val);
}

export function some<T>(val: T): Some<T> {
	return new Some(val);
}

export function none<T>(): None<T> {
	return new None();
}
