import { Component, EventEmitter, Input, Output } from "@angular/core";

@Component({
	selector: "cm-design-force-customer-profile-admin[customerId]",
	template: `
		<cm-modal [show]="show" (showChange)="showChange.emit($event)">
			<ng-template cmModal>
				<div class="row">
					<div
						class="h1 col-12 bg-primary text-white text-uppercase flex-center px-sm-5 px-3 pt-5 pb-sm-5 pb-3 mb-0"
					>
						Master Admin
					</div>
				</div>
				<div class="container-fluid">
					<cm-design-force-customer-info
						*ngIf="customerId"
						[customerId]="customerId"
						[nested]="true"
					></cm-design-force-customer-info>
					<!-- section 2 -->
					<div class="row green-top-border my-1">
						<div class="col-12 col-lg-6">
							<cm-client-edit-holiday-hours [customerid]="customerId"></cm-client-edit-holiday-hours>
						</div>
						<div class="col-12 col-lg-6">
							<cm-client-edit-store-hour-locations
								[customerid]="customerId"
							></cm-client-edit-store-hour-locations>
						</div>
					</div>
					<!-- section 3 -->
					<div class="row green-top-border my-1">
						<div class="col-12">
							<cm-client-edit-contract [customerid]="customerId"></cm-client-edit-contract>
						</div>
						<div class="col-12">
							<cm-client-edit-contract-options
								[customerid]="customerId"
							></cm-client-edit-contract-options>
						</div>
					</div>
					<!-- section 3 -->
					<div class="row green-top-border my-1">
						<div class="col-12 col-lg-6">
							<cm-client-edit-post-count [customerid]="customerId"></cm-client-edit-post-count>
						</div>
						<div class="col-12 col-lg-6">
							<cm-client-edit-promotions [customerid]="customerId"></cm-client-edit-promotions>
						</div>
					</div>
					<div class="row green-top-border my-1">
						<div class="col-12">
							<cm-client-edit-ai [customerid]="customerId"></cm-client-edit-ai>
						</div>
					</div>
					<!-- section 4 -->
					<div class="row green-top-border my-1">
						<div class="col-12">
							<cm-client-edit-affiliates [customerid]="customerId"></cm-client-edit-affiliates>
						</div>
					</div>
					<div class="row green-top-border my-1">
						<div class="col-12">
							<cm-client-edit-domain-info [customerid]="customerId"></cm-client-edit-domain-info>
						</div>
					</div>
				</div>
			</ng-template>
		</cm-modal>
	`,
	styles: [
		`
			.green-top-border {
				border-top: solid 2px green;
				padding-top: 10px;
				padding-bottom: 10px;
			}
			.green-top-border input {
				margin-right: 5px;
			}
			.green-top-border label {
				margin-right: 5px;
			}
		`,
	],
})
export class CustomerProfileAdminComponent {
	@Input() customerId!: number;
	@Input() show: boolean = false;
	@Output() showChange = new EventEmitter();
}
