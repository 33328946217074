import { HttpClient } from "@angular/common/http";
import { ChangeDetectorRef, Component, Input, OnDestroy, TemplateRef, ViewChild } from "@angular/core";
import { TimePunchType } from "@model/enums/time-punch-type";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { format, parse } from "date-fns";
import { ToastrService } from "ngx-toastr";
import { combineLatest, from, Observable, of } from "rxjs";
import { catchError, map, switchMap, tap } from "rxjs/operators";
import { TypeState } from "typestate";
import { iter } from "../common/iter";
import { cloneDeep } from "../common/util";
import { RestService, Service } from "../rest.service";
import { UserService } from "../user.service";
import { TimeClockService } from "./time-clock.service";

@Component({
	selector: "cm-time-clock",
	templateUrl: "time-clock.component.html",
	styleUrls: ["./time-clock.component.scss"],
})
export class TimeClockComponent implements OnDestroy {
	/**
	 * Vars
	 */
	clockMachine: TypeState.FiniteStateMachine<number> | null = null;
	currentPunchState: number = 0;
	loaded: boolean = false;
	userName: string = "";
	userId: any = null;
	verifyDays: any[] = [];
	changeItem: any = null;
	location: any = null;
	refreshInterval: any = null;
	parseInt = parseInt;
	vars: any = {};
	saving: boolean = false;

	@Input() kioskMode?: boolean = false;

	/**
	 * Services
	 */
	statementService: Service;
	settingsService: Service;
	hrService: Service;

	loggedIn$ = this.userService.user$.pipe(
		tap((user) => {
			if (user) {
				this.userName = user.first_name + " " + user.last_name;
				this.userId = user.userid;
				this.setupClock();
			} else {
				this.userName = "";
				this.userId = null;
			}
		}),
		map((user) => !!user),
	);
	punchTypes$ = this.timeClock.punchTypes$;

	/**
	 * View Refs
	 */
	@ViewChild("timeVerify", { static: true }) timeVerify!: TemplateRef<any>;
	@ViewChild("changePunch", { static: true }) changePunch!: TemplateRef<any>;

	constructor(
		private restService: RestService,
		private userService: UserService,
		private modal: NgbModal,
		private cd: ChangeDetectorRef,
		private toastrService: ToastrService,
		private timeClock: TimeClockService,
		private http: HttpClient,
	) {
		this.statementService = this.restService.init("statement");
		this.settingsService = this.restService.init("admin/setting");
		this.hrService = this.restService.init("hr");
		this.refreshInterval = setInterval(() => this.setupClock(), 60000);
	}

	getDateObject(date: any) {
		return parse(date);
	}

	ngOnDestroy() {
		clearInterval(this.refreshInterval);
	}

	/**
	 * Setup the clock on load if logged in or after a login
	 */
	setupClock() {
		this.loaded = false;
		this.statementService
			.post$("GetLastPunchTypeForUser", {
				vars: { userid: this.userId },
			})
			.subscribe({
				next: (response) => {
					if (response.results.length === 1) {
						this.currentPunchState = Number(response.results[0].time_punch_typeid) || 2;
					} else {
						this.currentPunchState = 2;
					}
					this.clockMachine = new TypeState.FiniteStateMachine<number>(this.currentPunchState);
					this.punchTypes$.subscribe((punchTypes) => {
						for (const [id, type] of punchTypes) {
							for (const from of type.from) {
								this.clockMachine!.from(from).to(id);
							}
						}
						this.loaded = true;
					});
				},
				error: (response) => this.toastrService.error(response.message),
			});
	}

	showPosition(position: any) {
		this.location = {
			latitude: position.coords.latitude,
			longitude: position.coords.longitude,
			accuracy: position.coords.accuracy,
		};
		this.sendPunch();
	}

	showError(error: any) {
		console.error(error);
		this.sendPunch();
	}

	/**
	 * Function to record the punch choice to the clock
	 * (possibly call the confirmation code if you are clocking in)
	 * @param typeId - time_punch_typeid clicked
	 */
	switchState(typeId: number) {
		this.vars = {
			previous_time_punch_typeid: this.currentPunchState,
			time_punch_typeid: typeId,
		};

		this.loaded = false;
		this.location = null;

		if (navigator.geolocation) {
			navigator.geolocation.getCurrentPosition(
				(position: any) => this.showPosition(position),
				(error: any) => this.showError(error),
				{
					enableHighAccuracy: true,
					maximumAge: 0,
					timeout: 5000,
				},
			);
		} else {
			this.sendPunch();
		}
	}

	sendPunch() {
		this.saving = true;
		if (this.location !== null) {
			this.vars = Object.assign(this.vars, this.location);
		}

		this.hrService.post$("timepunch", this.vars).subscribe({
			next: (response) => {
				if (response.success) {
					this.currentPunchState = this.vars.time_punch_typeid;
					this.clockMachine!.go(this.vars.time_punch_typeid);
					if (this.currentPunchState === TimePunchType.ClockIn) {
						this.createVerificationScreen();
					} else if (this.kioskMode) {
						this.userService.logOut().subscribe();
					}
					this.loaded = true;
				} else {
					this.toastrService.error(response.message);
				}
				this.saving = false;
			},
			error: (response) => {
				this.toastrService.error(response.message);
				this.saving = false;
			},
		});
	}

	/**
	 * Log out the user
	 */
	logOut() {
		this.userService.logOut().subscribe();
	}

	/**
	 * Get Key of A Map
	 * @param map - js map to get keys from
	 */
	getKeys(map: Map<number, string>) {
		return Array.from(map.keys());
	}

	createVerificationScreen() {
		const punches$: Observable<any> = this.http.post("/api/statement/GetActivePayRollPunchesForUser", {
			vars: { date: format(new Date(), "YYYY-MM-DD"), userid: this.userId },
		});

		combineLatest(this.timeClock.maxDailyPaidBreak$, punches$)
			.pipe(
				map(([maxDailyPaidBreak, punches]) =>
					iter(punches.results)
						.groupBy((r: any) => r.punchDate)
						.map(([date, punches]) => this.timeClock.buildDayObj(date, punches, maxDailyPaidBreak))
						.filter((day) => day.items.items.some((punch: any) => !punch.verified_by_userid))
						.toArray(),
				),
				tap((verifyDays) => {
					this.verifyDays = verifyDays;
				}),
				switchMap(() => {
					if (this.verifyDays.length) {
						return from(
							this.modal.open(this.timeVerify, {
								ariaLabelledBy: "time-verify",
								size: "lg",
								keyboard: false,
							}).result,
						);
					} else {
						return of(null);
					}
				}),
				catchError(() => of(null)),
			)
			.subscribe(() => {
				if (this.kioskMode) {
					this.userService.logOut().subscribe();
				}
			});
	}

	submitVerify(day: any) {
		const goodIds: any[] = [];
		for (const punch of day.items.items) {
			if (null === punch.verified_by_userid) {
				if (punch.change.change) {
					this.statementService
						.post$("m", {
							queries: [
								{ id: "EmployeeTimePunchUpdate1" },
								{ id: "EmployeeTimePunchUpdate2" },
								{ id: "EmployeeTimePunchUpdate3" },
							],
							global: {
								vars: {
									time_punchid: punch.time_punchid,
									punch_change_typeid: 1,
									punch_change_to: punch.change.change.punch_at,
									change_to_time_punch_typeid: punch.change.change.time_punch_typeid,
									punch_change_to_typeid: punch.change.change.time_punch_typeid,
									userid: this.userId,
								},
							},
						})
						.subscribe();
				} else {
					goodIds.push(punch.time_punchid);
				}
			}
		}
		if (goodIds.length > 0) {
			this.statementService
				.post$("EmployeeVerifyTimePunches", {
					vars: {
						time_punchid: goodIds,
						userid: this.userId,
					},
				})
				.subscribe();
		}
		this.verifyDays = this.verifyDays.filter((x) => x !== day);
		if (this.verifyDays.length < 1) {
			this.modal.dismissAll();
		}
	}

	openChangeModal(item: any, day: any) {
		this.changeItem = cloneDeep(item);
		this.changeItem.change.change = cloneDeep(this.changeItem.change.original);
		this.modal.open(this.changePunch, { ariaLabelledBy: "changePunch", keyboard: false }).result.then(
			() => {
				const dayItemIndex = day.items.items.indexOf(item);
				item = cloneDeep(this.changeItem);
				item.punch_at = item.change.change.punch_at;
				item.time_punch_typeid = item.change.change.time_punch_typeid;
				item.punchType = item.change.change.time_punch_type;
				day.items.items[dayItemIndex] = cloneDeep(item);
				this.cd.markForCheck();
			},
			() => {
				this.changeItem = null;
				item.change.change = null;
				item.punch_at = item.change.original.punch_at;
				item.time_punch_typeid = item.change.original.time_punch_typeid;
				item.punchType = item.change.original.time_punch_type;
			},
		);
	}
}
