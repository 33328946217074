<div
	*ngIf="initialized && activeBS | async"
	class="cm-user-widget d-print-none"
	[ngClass]="{ active: activeBS | async, initialized: initialized, 'record-active': record }"
>
	<div *ngIf="userService.loggedIn$ | async" class="cm-user-widget-menu">
		<ng-container *ngComponentOutlet="contents$ | async"></ng-container>
		<cm-time-clock *ngIf="showTimeClock && userService.isEmp()"></cm-time-clock>
	</div>
	<div *ngIf="!(userService.loggedIn$ | async)" class="cm-user-widget-sign-in">
		<div class="cm-user-widget-header">Sign In</div>
		<div class="p-3">
			<cm-login></cm-login>
		</div>
	</div>
</div>
<div class="cm-user-widget-gear d-print-none" [ngClass]="{ active: activeBS | async }" (click)="toggleWidget()">
	<fa-icon [icon]="faCogs"></fa-icon>
</div>
<ng-container *ngIf="userWidgetService.showCalc$ | async">
	<cm-calculator (closeCalcEvent)="userWidgetService.setShowCalc(false)"></cm-calculator>
</ng-container>
