export default class Version {
	static fromString(str: string): Version {
		const split = str.split(".", 4);
		if (split.length !== 3) {
			throw new Error("Invalid version string");
		}

		const major = parseInt(split[0]);
		const minor = parseInt(split[1]);
		const patch = parseInt(split[2]);
		if (isNaN(major) || isNaN(minor) || isNaN(patch)) {
			throw new Error("Invalid version string");
		}

		return new Version(major, minor, patch);
	}

	major: number;
	minor: number;
	patch: number;

	constructor(major: number, minor: number, patch: number) {
		this.major = major;
		this.minor = minor;
		this.patch = patch;
	}

	/**
	 * If `this` is greater than `rhs`, returns a positive number.
	 *
	 * If `this` is equal to `rhs`, returns 0.
	 *
	 * If `this` is less than `rhs`, returns a negative number.
	 */
	compare(rhs: Version): number {
		let ret = this.major - rhs.major;

		if (ret === 0) {
			ret = this.minor - rhs.minor;
		}

		if (ret === 0) {
			ret = this.patch - rhs.patch;
		}

		return ret;
	}

	/**
	 * Less than
	 */
	gt(rhs: Version): boolean {
		return this.compare(rhs) > 0;
	}

	/**
	 * Less than
	 */
	ge(rhs: Version): boolean {
		return this.compare(rhs) >= 0;
	}

	/**
	 * Equals
	 */
	eq(rhs: Version): boolean {
		return this.compare(rhs) === 0;
	}

	/**
	 * Less than or equals
	 */
	le(rhs: Version): boolean {
		return this.compare(rhs) <= 0;
	}

	/**
	 * Less than
	 */
	lt(rhs: Version): boolean {
		return this.compare(rhs) < 0;
	}

	toString(): string {
		return `${this.major}.${this.minor}.${this.patch}`;
	}
}
