import { HttpClient } from "@angular/common/http";
import { Component, Input, OnChanges, SimpleChanges } from "@angular/core";
import { IStmtResults } from "@model/stmt-results";
import { ToastrService } from "ngx-toastr";
import { BehaviorSubject } from "rxjs";
import { map, switchMap } from "rxjs/operators";

@Component({
	selector: "cm-client-edit-post-count[customerid]",
	template: `
		<p><strong>Social Posts:</strong></p>
		<input
			*ngIf="premiumNote$ | async as premiumNote"
			type="number"
			id="premium-posts"
			class="mr-1"
			min="0"
			max="10"
			[ngModel]="premiumNote.customer_note"
			(ngModelChangeDebounced)="saveNote($event, premiumNote.customer_noteid, 14)"
		/>
		<label for="premium-posts">Premium Posts/mo</label><br />

		<input
			*ngIf="regularNote$ | async as regularNote"
			class="mr-1"
			type="number"
			id="regular-posts"
			min="0"
			max="10"
			[ngModel]="regularNote.customer_note"
			(ngModelChangeDebounced)="saveNote($event, regularNote.customer_noteid, 15)"
		/>
		<label for="regular-posts">Regular Posts/mo</label>
	`,
	styles: [
		`
			:host {
				display: block;
			}
		`,
	],
})
export class ClientEditPostCountComponent implements OnChanges {
	@Input() customerid!: number;

	customeridBS = new BehaviorSubject(0);

	saving = false;

	projectCats: any[] = [];

	customerProjectCats = new Map();

	premiumNote$ = this.customeridBS.pipe(
		switchMap((customerid) =>
			this.http.post("/api/statement/GetCustomerNotes", {
				vars: { customerid, note_titleid: 14 },
			}),
		),
		map((response: IStmtResults<any>) =>
			response.results && response.results[0]
				? response.results[0]
				: { customer_note: "", customer_noteid: null },
		),
	);

	regularNote$ = this.customeridBS.pipe(
		switchMap((customerid) =>
			this.http.post("/api/statement/GetCustomerNotes", {
				vars: { customerid, note_titleid: 15 },
			}),
		),
		map((response: IStmtResults<any>) =>
			response.results && response.results[0]
				? response.results[0]
				: { customer_note: "", customer_noteid: null },
		),
	);

	constructor(private http: HttpClient, private toastr: ToastrService) {}

	saveNote(note: string, customerNoteId: null | number, note_titleid: number) {
		if (note) {
			if (customerNoteId) {
				this.http
					.post("/api/statement/UpdateCustomerNote", {
						vars: { customer_noteid: customerNoteId, customer_note: note },
					})
					.subscribe(() => this.customeridBS.next(this.customerid));
			} else {
				this.http
					.post("/api/statement/AddCustomerNote", {
						vars: { customerid: this.customerid, note_titleid, customer_note: note },
					})
					.subscribe(() => this.customeridBS.next(this.customerid));
			}
		} else if (customerNoteId) {
			this.http
				.post("/api/statement/DeleteCustomerNote", {
					vars: { customer_noteid: customerNoteId },
				})
				.subscribe(() => this.customeridBS.next(this.customerid));
		}
	}

	ngOnChanges(changes: SimpleChanges): void {
		if (changes.customerid) {
			this.customeridBS.next(changes.customerid.currentValue);
		}
	}
}
