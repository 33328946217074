import { IEnvironmentPartial } from "./environment-types";

export const environment: IEnvironmentPartial = {
	dealerInfo: {
		name: "designforcemarketing",
		homeBreadcrumb: "Design Force",
		themeData: {
			dealerAccent: {
				bg: "#FF6B00",
				bgHover: "#FF8C1C",
				fg: "#FFF",
				fgHover: "#FFF",
				base: { bg: "#333", fg: "#FFF", light: "#aaa" },
				offset: "#fff",
				highlight: {
					bg: "#003A70",
					bgHover: "#254B7E",
					fg: "#FFF",
				},
				homeUrl: "",
				footerUrl: "",
			},
		},
	},
};
