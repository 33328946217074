import { DOCUMENT } from "@angular/common";
import {
	ChangeDetectionStrategy,
	Component,
	ContentChild,
	EventEmitter,
	Inject,
	Input,
	OnChanges,
	OnDestroy,
	Output,
	Renderer2,
	SimpleChanges,
	TemplateRef,
	ViewChild,
} from "@angular/core";
import { faTimes } from "@fortawesome/pro-solid-svg-icons";
import { ModalDirective } from "./modal.directive";
import { ModalService } from "./modal.service";

let openCount = 0;

@Component({
	selector: "cm-modal",
	template: `
		<ng-container *ngIf="show && !detach && modal">
			<ng-container *ngTemplateOutlet="modalContent"></ng-container>
		</ng-container>

		<ng-template #modalContent>
			<div
				class="modal-wrap"
				[ngStyle]="{ display: show ? 'flex' : 'none' }"
				(mousedown)="clicking = true"
				(click)="onClick()"
			>
				<div class="modal-inner mh-100 w-100">
					<div
						class="container my-5 bg-white shadow position-relative relative"
						(mousedown)="$event.stopPropagation()"
						(click)="$event.stopPropagation()"
					>
						<ng-container *ngTemplateOutlet="modal.templateRef"></ng-container>
						<fa-icon
							role="button"
							[icon]="faTimes"
							class="close-btn rounded-circle flex-center p-3"
							(click)="closeClick.next(false); showChange.next(false)"
						></fa-icon>
					</div>
				</div>
			</div>
		</ng-template>
	`,
	styles: [
		`
			.modal-wrap {
				position: fixed;
				top: 0;
				right: 0;
				bottom: 0;
				left: 0;
				z-index: 1049;
				justify-content: center;
				align-items: center;
				background: rgba(0, 0, 0, 0.7);
			}

			.modal-inner {
				overflow-y: scroll;
			}

			.close-btn {
				position: absolute;
				top: 1rem;
				right: 1rem;
				width: 1em;
				height: 1em;
				background: #fff;
				cursor: pointer;
				font-size: 20px;
				z-index: 5;
			}

			.close-btn:hover {
				background: #ccc;
			}
		`,
	],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ModalComponent implements OnChanges, OnDestroy {
	@Input() show!: boolean;
	/**
	 * create the modal inside the modal-outlet component instead of inside this component
	 *
	 * requires <cm-modal-outlet></cm-modal-outlet> in the root component
	 */
	@Input() detach: boolean = false;

	@Output() showChange = new EventEmitter<boolean>();
	@Output() closeClick = new EventEmitter<boolean>();

	@ContentChild(ModalDirective, { static: true }) modal!: ModalDirective;
	@ViewChild(TemplateRef) content!: TemplateRef<any>;

	faTimes = faTimes;

	// prevent modal from disappearing when mousedown happens inside the modal and mouseup happens outside of it
	clicking = false;
	id = -1;

	constructor(private service: ModalService, private renderer: Renderer2, @Inject(DOCUMENT) private document: any) {}

	ngOnChanges(changes: SimpleChanges): void {
		if (changes.show.currentValue) {
			++openCount;
			this.renderer.addClass(this.document.body, "modal-open");
			if (this.detach) {
				this.id = this.service.pushTemplate(this.content);
			}
		} else if (changes.show.previousValue) {
			--openCount;
			if (!openCount) {
				this.renderer.removeClass(this.document.body, "modal-open");
				if (this.detach) {
					this.service.delTemplate(this.id);
				}
			}
		}
	}

	ngOnDestroy() {
		if (this.show && openCount) {
			--openCount;
			if (!openCount) {
				this.renderer.removeClass(this.document.body, "modal-open");
			}
		}
	}

	onClick() {
		if (this.clicking) {
			this.clicking = false;
			this.showChange.next(false);
		}
	}
}
