import {
	Component,
	ElementRef,
	EventEmitter,
	HostBinding,
	Input,
	OnInit,
	Output,
	ViewChild,
	Inject,
} from "@angular/core";
import { faClock } from "@fortawesome/pro-solid-svg-icons";
import { NgbTimeStruct } from "@ng-bootstrap/ng-bootstrap";
import { fromEvent } from "rxjs";
import { take } from "rxjs/operators";
import { DOCUMENT } from "@angular/common";
import { format } from "date-fns";
import { parseDate } from "@common/date";

let nextIdNum = 0;

@Component({
	selector: "cm-field-time",
	templateUrl: "./field-time.component.html",
	styleUrls: ["./field-time.component.scss"],
})
export class FieldTimeComponent implements OnInit {
	@Input() name!: string;
	@Input() disabled: boolean = false;
	@Input() label?: string;
	@Input() intro?: string;
	@Input() required: boolean = false;
	@Input() value?: string;
	@Input() format: string = "HH:mm:ss";

	@Output() valueChange = new EventEmitter<string>();

	@HostBinding("class.was-validated")
	get hostClassHasSuccess(): boolean {
		return this.validated;
	}

	@ViewChild("input", { static: true }) input!: ElementRef<HTMLInputElement>;

	faClock = faClock;

	idNum: number = 0;
	error: string | null = null;
	ngbTime?: NgbTimeStruct;
	pickerVisible: boolean = false;
	validated: boolean = false;

	constructor(@Inject(DOCUMENT) private document: any) {
		this.idNum = nextIdNum++;
	}

	get id(): string {
		return `field-time-${this.idNum}`;
	}

	ngOnInit(): void {
		if (this.value) {
			this.parseValue();
		} else {
			this.ngbTime = { hour: 0, minute: 0, second: 0 };
		}
	}

	onModelChange() {
		this.parseValue();

		this.valueChange.emit(this.value);
	}

	onPickerToggle(event: Event) {
		event.stopPropagation();

		this.pickerVisible = !this.pickerVisible;

		if (this.pickerVisible) {
			fromEvent(this.document, "click")
				.pipe(take(1))
				.subscribe(() => {
					this.pickerVisible = false;
				});
		}
	}

	onSubmit() {
		this.pickerVisible = false;
		this.value = format(
			new Date(2019, 1, 1, this.ngbTime!.hour, this.ngbTime!.minute, this.ngbTime!.second),
			this.format,
		);
		this.validate();

		this.valueChange.emit(this.value);
	}

	validate() {
		if (!this.input.nativeElement.checkValidity()) {
			this.error = this.input.nativeElement.validationMessage;
		} else {
			this.error = null;
		}

		this.validated = true;
	}

	private parseValue() {
		const time = parseDate(this.value!, this.format);
		this.ngbTime = { hour: time.getHours(), minute: time.getMinutes(), second: time.getSeconds() };
	}
}
