import { HttpClient } from "@angular/common/http";
import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from "@angular/core";
import { UserService } from "@core/app/user.service";
import { faPencil, faPlusCircle } from "@fortawesome/pro-solid-svg-icons";
import { IStmtResults } from "@model/stmt-results";
import { format } from "date-fns-2";
import { BehaviorSubject } from "rxjs";
import { map, shareReplay, switchMap, tap } from "rxjs/operators";

@Component({
	selector: "cm-client-onboarding[customerid]",
	template: `
		<ng-container *ngIf="onboarding$ | async as onboarding">
			<div class="container-fluid">
				<div class="row py-2">
					<div *ngIf="onboarding.length < 1" class="col-12">
						<button class="btn btn-primary" (click)="addOnboarding()">Add Onboarding</button>
					</div>
					<div class="col-12" *ngIf="onboarding.length >= 1">
						<ng-container *ngFor="let o of onboarding; index as i">
							<ngb-accordion #acc="ngbAccordion">
								<ng-container *ngFor="let g of o.phases$ | async; index as ii">
									<ngb-panel
										title="{{ siteOrgDeptMap.get(g.site_org_deptid) }} - {{ g.project_phase }} {{
											g.completedCount
										}} of {{ (g.steps$ | async)?.length }} Completed"
									>
										<ng-template ngbPanelContent>
											<ng-container *ngIf="g.steps$ | async as tasks">
												<table class="table table-sm table-light table-striped table-hover">
													<thead class="thead-dark">
														<tr>
															<th>Employee</th>
															<th>Item</th>
															<th>Completed</th>
															<th>Client</th>
														</tr>
													</thead>
													<tbody>
														<ng-container *ngFor="let item of tasks; index as j">
															<tr>
																<td>
																	{{ empMap.get(Number(item.assigned_empid)) }}
																</td>
																<td>
																	{{ item.project_step }}
																</td>
																<td>
																	<input
																		type="checkbox"
																		id="completed{{ j }}"
																		class="form-control"
																		[(ngModel)]="item.completed"
																		(ngModelChange)="changeCompleted($event, item)"
																		[disabled]="
																			(userService.site_org_deptid$ | async) !==
																			g.site_org_deptid
																		"
																	/>
																</td>
																<td>
																	<input
																		type="checkbox"
																		id="completed{{ j }}"
																		class="form-control"
																		[(ngModel)]="item.customer"
																		[disabled]="true"
																	/>
																</td>
															</tr>
														</ng-container>
													</tbody>
												</table>
												<div
													*ngIf="
														(userService.site_org_deptid$ | async) === g.site_org_deptid &&
														g.completedCount < (g.steps$ | async)?.length
													"
													class="text-center"
												>
													<button
														class="btn btn-primary"
														(click)="
															currentOnboarding = o;
															currentGroup = g;
															currentTasks = tasks;
															showUncompletes = true
														"
													>
														Email Uncompletes
													</button>
												</div>
											</ng-container>
										</ng-template>
									</ngb-panel>
								</ng-container>
							</ngb-accordion>
						</ng-container>
					</div>
				</div>
			</div>
		</ng-container>
		<cm-email-uncomplete
			[tasks]="currentTasks"
			[customerid]="customerid"
			[show]="showUncompletes"
			(showChange)="showUncompletes = $event; customeridBS.next(customeridBS.value)"
		></cm-email-uncomplete>
	`,
	styles: [
		`
			:host {
				display: block;
			}
		`,
	],
})
export class ClientOnBoardingComponent implements OnChanges {
	@Input() customerid!: number;
	@Output() saved = new EventEmitter<void>();

	currentGroup = null;
	currentItem = null;
	currentOnboarding = null;
	currentTasks = null;

	showItem = false;
	showGroup = false;

	customeridBS = new BehaviorSubject(0);
	currentDomainId = null;
	saving = false;
	showUncompletes = false;
	faPlusCircle = faPlusCircle;
	faPencil = faPencil;
	tempOnboardingBS: BehaviorSubject<any> = new BehaviorSubject([]);

	Number = Number;

	empMap = new Map();
	siteOrgDeptMap = new Map();

	onboarding$ = this.customeridBS.pipe(
		switchMap((customerid) =>
			this.http.post("/api/statement/GetCustomerProjects", { vars: { customerid, project_catid: 128 } }),
		),
		map((response: IStmtResults<any>) =>
			response.results?.map((row: any) => ({
				...row,
				phases$: this.http
					.post("/api/statement/GetCustomerProjectPhases", {
						vars: { customer_projectid: row.customer_projectid },
					})
					.pipe(
						map((response: IStmtResults<any>) =>
							response.results?.map((row2: any) => ({
								...row2,
								steps$: this.http
									.post("/api/statement/GetCustomerProjectSteps", {
										vars: {
											customer_projectid: row.customer_projectid,
											project_phaseid: row2.project_phaseid,
										},
									})
									.pipe(
										map((response: IStmtResults<any>) =>
											response.results?.map((row3: any) => ({
												...row3,
												completed: null !== row3.completed_at,
												customer: 1 === row3.involves_customer,
											})),
										),
										shareReplay(1),
									),
							})),
						),
						shareReplay(1),
					),
			})),
		),
		shareReplay(1),
	);

	siteOrgDepts$ = this.http.post("/api/statement/GetSiteOrgDept", {}).pipe(
		map((response: IStmtResults<any>) => response.results),
		tap((results: any) => {
			this.siteOrgDeptMap = new Map();
			results.forEach((element: any) => this.siteOrgDeptMap.set(element.site_org_deptid, element.org_dept));
		}),
		shareReplay(1),
	);

	emps$ = this.http.post("/api/statement/GetEmployees", {}).pipe(
		map((response: IStmtResults<any>) => response.results),
		tap((results: any) => {
			this.empMap = new Map();
			results.forEach((element: any) =>
				this.empMap.set(element.empid, element.first_name + " " + element.last_name),
			);
		}),
		shareReplay(1),
	);

	changeCompleted(enable: boolean, item: any) {
		this.http
			.post("/api/statement/UpdateCustomerProjectTask", {
				vars: {
					customer_project_taskid: item.customer_project_taskid,
					completed_at: !enable ? null : format(new Date(), "yyyy-MM-dd HH:mm:ss"),
				},
			})
			.subscribe(() => this.customeridBS.next(this.customeridBS.value));
	}

	constructor(private http: HttpClient, public userService: UserService) {
		this.http
			.post("/api/statement/GetSiteOrgDept", {})
			.pipe(
				map((response: IStmtResults<any>) => response.results),
				tap((results: any) => {
					this.siteOrgDeptMap = new Map();
					results.forEach((element: any) =>
						this.siteOrgDeptMap.set(element.site_org_deptid, element.org_dept),
					);
				}),
			)
			.subscribe();
		this.http
			.post("/api/statement/GetEmployees", {})
			.pipe(
				map((response: IStmtResults<any>) => response.results),
				tap((results: any) => {
					this.empMap = new Map();
					results.forEach((element: any) =>
						this.empMap.set(element.empid, element.first_name + " " + element.last_name),
					);
				}),
			)
			.subscribe();
	}

	ngOnChanges(changes: SimpleChanges): void {
		if (changes.customerid) {
			this.customeridBS.next(changes.customerid.currentValue);
		}
	}

	addOnboarding() {
		this.http
			.post("/api/onboarding/createNew", { customerid: this.customerid })
			.subscribe(() => this.customeridBS.next(this.customeridBS.value));
	}
}
