export type Breakpoint = "xl" | "lg" | "md" | "sm" | "xs";

export const breakpoints: Breakpoint[] = ["xl", "lg", "md", "sm", "xs"];
export const breakpointMin: { xl: number; lg: number; md: number; sm: number; xs: number; [key: string]: number } = {
	xl: 1200,
	lg: 992,
	md: 768,
	sm: 576,
	xs: 0,
};
export const breakpointMax: { xl: number; lg: number; md: number; sm: number; xs: number; [key: string]: number } = {
	xl: Infinity,
	lg: 1200,
	md: 992,
	sm: 768,
	xs: 576,
};
