import { HttpClient } from "@angular/common/http";
import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from "@angular/core";
import { faPencil, faPlusCircle } from "@fortawesome/pro-solid-svg-icons";
import { IStmtResults } from "@model/stmt-results";
import { ToastrService } from "ngx-toastr";
import { BehaviorSubject } from "rxjs";
import { map, shareReplay, switchMap } from "rxjs/operators";

@Component({
	selector: "cm-client-edit-domain-info[customerid]",
	template: `
		<p>
			<strong>Domain Access:</strong>
		</p>
		<ng-container *ngIf="domainInfo$ | async as domainInfo">
			<div class="row">
				<div class="col-12 col-lg-4">
					<ng-container *ngFor="let domain of domainInfo">
						<div *ngIf="domain.domain_cat === 'Primary Domain'">
							<strong>Website Domain: </strong>{{ domain.domain_name }}<br />
							<strong>Website Domain Password: </strong>{{ domain.password }}
						</div>
					</ng-container>
				</div>
				<div class="col-12 col-lg-4">
					<ng-container *ngFor="let domain of domainInfo">
						<div *ngIf="domain.domain_cat === 'Temp Domain'">
							<strong>Temporary Domain: </strong>{{ domain.domain_name }}<br />
							<strong>Temporary Password: </strong>{{ domain.password }}
						</div>
					</ng-container>
				</div>
				<div class="col-12 col-lg-4">
					<ng-container *ngFor="let domain of domainInfo">
						<div *ngIf="domain.domain_cat === 'DMS Domain'">
							<strong>DMS Site: </strong>{{ domain.domain_name }}<br />
							<strong>DMS Site Password: </strong>{{ domain.password }}
						</div>
					</ng-container>
				</div>
				<hr class="col-11" />
				<p class="col-12">
					<strong>Domains:</strong
					><fa-icon class="mx-1" [icon]="faPlusCircle" (click)="showDomain = true"></fa-icon>
				</p>
				<div class="col-12 table-responsive">
					<table class="table table-sm table-light table-striped table-hover">
						<thead class="thead-dark">
							<tr>
								<th>Domain</th>
								<th>Registrar</th>
								<th>Username</th>
								<th>Password</th>
								<th>Expires</th>
								<th>DNS Provider</th>
								<th>DNSSEC</th>
								<th>MX/DMARC/SPF</th>
							</tr>
						</thead>
						<tbody>
							<tr *ngFor="let domain of domainInfo">
								<td>
									{{ domain.domain_name }}
									<fa-icon
										class="mx-1"
										[icon]="faPencil"
										(click)="currentDomainId = domain.domain_nameid; showDomain = true"
									></fa-icon>
								</td>
								<td>{{ domain.registrar }}</td>
								<td></td>
								<td></td>
								<td>{{ domain.expires }}</td>
								<td>{{ domain.dns_provider }}</td>
								<td>{{ domain.dnssec }}</td>
								<td>{{ domain.mx_dmarc_spf }}</td>
							</tr>
						</tbody>
					</table>
				</div>
			</div>
		</ng-container>
		<cm-client-edit-domain
			[customerid]="customerid"
			[domain_nameid]="currentDomainId"
			[show]="showDomain"
			(showChange)="currentDomainId = null; showDomain = $event"
		></cm-client-edit-domain>
	`,
	styles: [
		`
			:host {
				display: block;
			}
		`,
	],
})
export class ClientEditDomainInfoComponent implements OnChanges {
	@Input() customerid!: number;
	@Output() saved = new EventEmitter<void>();

	customeridBS = new BehaviorSubject(0);
	currentDomainId = null;
	saving = false;
	showDomain = false;
	faPlusCircle = faPlusCircle;
	faPencil = faPencil;

	domainInfo$ = this.customeridBS.pipe(
		switchMap((customerid) =>
			this.http.post("/api/statement/GetDomainInfo", {
				vars: { customerid },
			}),
		),
		map((response: IStmtResults<any>) => response.results),
		shareReplay(1),
	);

	constructor(private http: HttpClient, private toastr: ToastrService) {}

	ngOnChanges(changes: SimpleChanges): void {
		if (changes.customerid) {
			this.customeridBS.next(changes.customerid.currentValue);
		}
	}
}
