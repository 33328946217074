import { ApplicationRef, NgModule, NgZone } from "@angular/core";
import { debounceTime } from "rxjs/operators";
import { PrerenderService, isPrerendering } from "./prerender.service";

@NgModule()
export class PrerenderModule {
	constructor(private zone: NgZone, app: ApplicationRef, transfer: PrerenderService) {
		if (!isPrerendering()) return;

		app.isStable.pipe(debounceTime(10)).subscribe((x) => {
			if (x) transfer._serializeToScript();
			(window as any).isIdle = x;
		});

		// zone.runOutsideAngular(() => setInterval(() => this.logTasks(), 1000));
	}

	private logTasks() {
		var taskTrackingZone = (<any>this.zone)._inner.getZoneWith("TaskTrackingZone");
		if (!taskTrackingZone) {
			throw new Error(
				"'TaskTrackingZone' zone not found! Have you loaded 'node_modules/zone.js/dist/task-tracking.js'?",
			);
		}
		var tasks: any[] = [
			...taskTrackingZone._properties.TaskTrackingZone.getTasksFor("macroTask"),
			...taskTrackingZone._properties.TaskTrackingZone.getTasksFor("microTask"),
		];
	}
}

// const tmp = (window as any).setInterval;
// (window as any).setInterval = (handler: TimerHandler, timeout?: number | undefined, ...args: any[]) => {
// 	return tmp(handler, timeout, ...args);
// };
