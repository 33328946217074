import { isBefore, addDays } from "date-fns-2";
import { Immutable, Frozen } from "./decorators";
import { IOption, some, none } from "./option";
import { SMap } from "./map2";

export function iter<T>(iterable: Iterable<T>): Iter<T> {
	return new BasicIter(iterable);
}

export function iterDate(start: Date, end?: Date): IterDateDispatch {
	return new IterDateDispatch(start, end);
}

export function iterFiles(fileList: FileList): Iter<File> {
	return new FileListIter(fileList);
}

export function iterObj<V>(obj: { [key: string]: V }): Iter<[string, V]> {
	return new ObjIter(obj);
}

export function range(start?: number, end?: number, inclusive?: boolean): Iter<number> {
	return new RangeIter(start, end, inclusive);
}

export function repeat<T>(value: T): Iter<T> {
	return new RepeatIter(value);
}

export function tuple<T1, T2, T3>(arg1: T1, arg2: T2, arg3: T3): [T1, T2, T3];
export function tuple<T1, T2>(arg1: T1, arg2: T2): [T1, T2];
export function tuple<T1>(arg1: T1): [T1];
export function tuple(...args: any[]): any[] {
	return args;
}

/**
 * Loosely based on [Rust's `Iterator` trait](https://doc.rust-lang.org/std/iter/trait.Iterator.html).
 */
@Frozen
export abstract class Iter<T> implements Iterable<T> {
	protected abstract inner(): Iterable<T>;

	// --- OPERATIONS ---

	chain(other: Iterable<T>): Iter<T> {
		return new TransformIter(this.inner(), (iterable) => chain(iterable, other));
	}

	enumerate(): Iter<[number, T]> {
		return new TransformIter(this.inner(), enumerate);
	}

	/**
	 * Calls the closure on each element and only yields the item if the closure returns `true`.
	 */
	filter(cb: (item: T) => boolean): Iter<T> {
		return new TransformIter(this.inner(), (iterable) => filter(iterable, cb));
	}

	/**
	 * @deprecated Use `map` then `flatten` instead
	 */
	flatMap<U>(cb: (item: T) => Iterable<U>): Iter<U> {
		return new TransformIter(this.inner(), (iterable) => flatMap(iterable, cb));
	}

	flatten<U>(this: Iter<Iterable<U>>): Iter<U> {
		return new TransformIter(this.inner(), flatten);
	}

	/**
	 * Groups by *adjacent* items that have the same key and yields an iterator for each group.
	 */
	groupBy<U>(cb: (item: T) => U): Iter<[U, Iter<T>]> {
		return new TransformIter(this.inner(), (iterable) => groupBy(iterable, cb));
	}

	inspect(cb: (item: T) => void): Iter<T> {
		return new TransformIter(this.inner(), (iterable) => inspect(iterable, cb));
	}

	map<U>(cb: (item: T) => U): Iter<U> {
		return new TransformIter(this.inner(), (iter) => map(iter, cb));
	}

	scan<S, U>(initialState: S, cb: (state: S, item: T) => [S, IOption<U>]): Iter<U> {
		return new TransformIter(this.inner(), (iter) => scan(iter, initialState, cb));
	}

	skip(count: number): Iter<T> {
		return new TransformIter(this.inner(), (iter) => skip(iter, count));
	}

	stepBy(num: number): Iter<T> {
		return new TransformIter(this.inner(), (iter) => step(iter, num));
	}

	take(count: number): Iter<T> {
		return new TransformIter(this.inner(), (iter) => take(iter, count));
	}

	skipWhile(cb: (item: T) => boolean): Iter<T> {
		return new TransformIter(this.inner(), (iter) => skipWhile(iter, cb));
	}

	takeWhile(cb: (item: T) => boolean): Iter<T> {
		return new TransformIter(this.inner(), (iter) => takeWhile(iter, cb));
	}

	unique(cb?: (item: T) => any): Iter<T> {
		return new TransformIter(this.inner(), (iter) => unique(iter, cb));
	}

	/**
	 * @deprecated Use `unique` instead
	 */
	uniqueBy(cb: (item: T) => any): Iter<T> {
		return this.unique(cb);
	}

	/**
	 * Combines two iterators into a single iterator of pairs, which ends as soon as either iterator ends. If
	 * the first iterator ends before the second iterator, `next` will not be called on the second one.
	 */
	zip<U>(other: Iterable<U>): Iter<[T, U]> {
		return new TransformIter(this.inner(), (iter) => zip(iter, other));
	}

	// --- COLLECTORS ---

	all(cb: (item: T) => boolean): boolean {
		for (const item of this.inner()) {
			if (!cb(item)) {
				return false;
			}
		}
		return true;
	}

	count(): number {
		const inner = this.inner();

		if (Array.isArray(inner)) {
			return inner.length;
		} else {
			let ret = 0;
			for (const _item of inner) {
				++ret;
			}
			return ret;
		}
	}

	last(): IOption<T> {
		const inner = this.inner();

		if (Array.isArray(inner)) {
			return inner.length ? some(inner[inner.length - 1]) : none();
		} else {
			let last: IOption<T> = none();
			for (const item of inner) {
				last = some(item);
			}
			return last;
		}
	}

	max(): IOption<T> {
		let max = this.nth(0);
		for (const item of this.skip(1)) {
			max = max.map((max) => (max > item ? max : item));
		}
		return max;
	}

	min(): IOption<T> {
		let min = this.nth(0);
		for (const item of this.skip(1)) {
			min = min.map((min) => (min < item ? min : item));
		}
		return min;
	}

	nth(n: number): IOption<T> {
		const inner = this.inner();

		if (Array.isArray(inner)) {
			return inner.length > n ? some(inner[n]) : none();
		} else {
			if (n < 0) {
				throw new Error("`n` must be greater than or equal to 0");
			}
			for (const item of inner) {
				if (n === 0) {
					return some(item);
				}
				--n;
			}
			return none();
		}
	}

	sum(this: Iter<number>): number {
		let sum = 0;
		for (const item of this.inner()) {
			sum += item;
		}
		return sum;
	}

	toArray(): T[] {
		return [...this.inner()];
	}

	toGroupMap<K, V>(this: Iter<[K, V]>): SMap<K, V[]> {
		const ret = new SMap<K, V[]>();

		for (const [key, value] of this.inner()) {
			ret.getOrInsert(key, []).push(value);
		}

		return ret;
	}

	toGroupObject<V>(this: Iter<[string, V]>): { [key: string]: V[] } {
		const ret: { [key: string]: V[] } = {};

		for (const [key, value] of this.inner()) {
			if (!ret[key]) {
				ret[key] = [];
			}

			ret[key].push(value);
		}

		return ret;
	}

	toMap<K, V>(this: Iter<[K, V]>): Map<K, V> {
		return new Map(this.inner());
	}

	toSet(): Set<T> {
		return new Set(this.inner());
	}

	toObject<V>(this: Iter<[string, V]>): { [key: string]: V } {
		const ret: { [key: string]: V } = {};
		for (const [key, value] of this.inner()) {
			ret[key] = value;
		}
		return ret;
	}

	[Symbol.iterator](): Iterator<T> {
		return this.inner()[Symbol.iterator]();
	}
}

function* chain<T>(iterable: Iterable<T>, other: Iterable<T>): IterableIterator<T> {
	for (const item of iterable) {
		yield item;
	}
	for (const item of other) {
		yield item;
	}
}

function* enumerate<T>(iterable: Iterable<T>): IterableIterator<[number, T]> {
	let i = 0;
	for (const item of iterable) {
		yield tuple(i, item);
		i++;
	}
}

function* filter<T>(iterable: Iterable<T>, cb: (item: T) => boolean): IterableIterator<T> {
	for (const item of iterable) {
		if (cb(item)) {
			yield item;
		}
	}
}

function* flatMap<T, U>(iterable: Iterable<T>, cb: (item: T) => Iterable<U>): IterableIterator<U> {
	for (const subiter of iterable) {
		for (const item of cb(subiter)) {
			yield item;
		}
	}
}

function* flatten<T>(iterable: Iterable<Iterable<T>>) {
	for (const subiter of iterable) {
		for (const item of subiter) {
			yield item;
		}
	}
}

function* groupBy<T, U>(iterable: Iterable<T>, cb: (item: T) => U): IterableIterator<[U, Iter<T>]> {
	const iterator = iter(iterable)
		.map((x) => cb(x))
		.enumerate()
		[Symbol.iterator]();

	let result = iterator.next();
	if (result.done) {
		return;
	}
	let [groupStart, group] = result.value;

	while (true) {
		result = iterator.next();
		if (result.done) {
			break;
		}
		const [i, newGroup] = result.value;

		if (newGroup !== group) {
			yield [
				group,
				iter(iterable)
					.skip(groupStart)
					.take(i - groupStart),
			];
			group = newGroup;
			groupStart = i;
		}
	}

	yield [group, iter(iterable).skip(groupStart)];
}

function* inspect<T>(iterable: Iterable<T>, cb: (item: T) => void) {
	for (const item of iterable) {
		cb(item);
		yield item;
	}
}

function* map<T, U>(iterable: Iterable<T>, cb: (item: T) => U) {
	for (const item of iterable) {
		yield cb(item);
	}
}

function* scan<T, S, U>(iterable: Iterable<T>, state: S, cb: (state: S, item: T) => [S, IOption<U>]) {
	for (const item of iterable) {
		const result = cb(state, item);
		state = result[0];
		if (result[1].isSome()) {
			yield result[1].unwrap();
		} else {
			return;
		}
	}
}

function* skip<T>(iterable: Iterable<T>, num: number) {
	if (num < 0) {
		throw new Error("`num` must be greater than or equal to 0");
	}

	if (Array.isArray(iterable)) {
		// optimized loop only works on arrays
		for (let i = num; i < iterable.length; i++) {
			yield iterable[i];
		}
	} else {
		// default loop works on any iterable
		let i = 0;
		for (const item of iterable) {
			if (i < num) {
				++i;
				continue;
			}

			yield item;
		}
	}
}

function* skipWhile<T>(iterable: Iterable<T>, cb: (item: T) => boolean) {
	let skipping = true;
	for (const item of iterable) {
		if (skipping) {
			if (cb(item)) {
				continue;
			}

			skipping = false;
		}

		yield item;
	}
}

function* step<T>(iterable: Iterable<T>, step: number) {
	if (step < 0) {
		throw new Error("`num` must be greater than or equal to 0");
	}

	if (Array.isArray(iterable)) {
		// optimized loop only works on arrays
		for (let i = 0; i < iterable.length; i += step) {
			yield iterable[i];
		}
	} else {
		// default loop works on any iterable
		for (const [i, item] of iter(iterable).enumerate()) {
			if (i % step) {
				continue;
			}

			yield item;
		}
	}
}

function* take<T>(iterable: Iterable<T>, num: number) {
	if (num < 0) {
		throw new Error("`num` must be greater than or equal to 0");
	}

	let i = 0;
	for (const item of iterable) {
		if (i >= num) {
			return;
		}

		++i;
		yield item;
	}
}

function* takeWhile<T>(iterable: Iterable<T>, cb: (item: T) => boolean) {
	for (const item of iterable) {
		if (!cb(item)) {
			return;
		}

		yield item;
	}
}

function* unique<T>(iterable: Iterable<T>, cb?: (item: T) => any) {
	cb = cb || ((x) => x);

	const unique = new Set();

	for (const item of iterable) {
		const by = cb(item);

		if (!unique.has(by)) {
			unique.add(by);
			yield item;
		}
	}
}

function* zip<T, U>(left: Iterable<T>, right: Iterable<U>) {
	const leftIter = left[Symbol.iterator]();
	const rightIter = right[Symbol.iterator]();

	while (true) {
		const leftItem = leftIter.next();
		const rightItem = rightIter.next();

		if (leftItem.done || rightItem.done) {
			return;
		}

		yield tuple(leftItem.value, rightItem.value);
	}
}

@Frozen
@Immutable
class TransformIter<T, U> extends Iter<U> {
	constructor(private iterable: Iterable<T>, private transform: (iterable: Iterable<T>) => Iterator<U>) {
		super();
	}

	[Symbol.iterator](): Iterator<U> {
		return this.transform(this.iterable);
	}

	protected inner(): Iterable<U> {
		return this;
	}
}

@Frozen
@Immutable
class IterDateDispatch {
	constructor(private start: Date, private end?: Date) {}

	byDay(): DateByDayIter {
		return new DateByDayIter(this.start, this.end);
	}
}

@Frozen
@Immutable
class BasicIter<T> extends Iter<T> {
	constructor(private iterable: Iterable<T>) {
		super();
	}

	protected inner(): Iterable<T> {
		return this.iterable;
	}
}

@Frozen
@Immutable
class DateByDayIter extends Iter<Date> {
	constructor(private start: Date, private end?: Date) {
		super();
	}

	*[Symbol.iterator](): Iterator<Date> {
		let cur = this.start;

		while (this.end ? isBefore(cur, this.end) : true) {
			yield cur;
			cur = addDays(cur, 1);
		}
	}

	protected inner(): Iterable<Date> {
		return this;
	}
}

@Frozen
@Immutable
class FileListIter extends Iter<File> {
	constructor(private fileList: FileList) {
		super();
	}

	*[Symbol.iterator](): Iterator<File> {
		// can't use forof loop on a FileList
		// tslint:disable-next-line:prefer-for-of
		for (let i = 0; i < this.fileList.length; i++) {
			yield this.fileList[i];
		}
	}

	protected inner(): Iterable<File> {
		return this;
	}
}

@Frozen
@Immutable
class ObjIter<V> extends Iter<[string, V]> {
	constructor(private obj: { [key: string]: V }) {
		super();
	}

	*[Symbol.iterator](): Iterator<[string, V]> {
		for (const key in this.obj) {
			if (this.obj.hasOwnProperty(key)) {
				const val = this.obj[key];

				yield [key, val];
			}
		}
	}

	protected inner(): Iterable<[string, V]> {
		return this;
	}
}

@Frozen
@Immutable
class RangeIter extends Iter<number> {
	private start: number;
	private end?: number;

	constructor(start?: number, end?: number, inclusive?: boolean) {
		if (start && end && end < start) {
			throw new Error("`end` must be greater than or equal to `start`");
		}

		super();

		if (end !== undefined && inclusive) {
			++end;
		}

		this.start = start || 0;
		this.end = end;
	}

	last(): IOption<number> {
		if (!this.end) {
			throw new Error(".last() called on never-ending range iterator");
		} else if (this.end === this.start) {
			return none();
		} else {
			return some(this.end - 1);
		}
	}

	*[Symbol.iterator](): Iterator<number> {
		for (let i = this.start; this.end === undefined || i < this.end; i++) {
			yield i;
		}
	}

	protected inner(): Iterable<number> {
		return this;
	}
}

@Frozen
@Immutable
class RepeatIter<T> extends Iter<T> {
	constructor(private value: T) {
		super();
	}

	*[Symbol.iterator](): Iterator<T> {
		while (true) {
			yield this.value;
		}
	}

	protected inner(): Iterable<T> {
		return this;
	}
}
