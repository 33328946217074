import { HttpClient } from "@angular/common/http";
import { Component, Input, OnChanges, SimpleChanges } from "@angular/core";
import { IStmtResults } from "@model/stmt-results";
import { ToastrService } from "ngx-toastr";
import { BehaviorSubject } from "rxjs";
import { map, shareReplay, switchMap } from "rxjs/operators";

@Component({
	selector: "cm-client-edit-store-hour-locations[customerid]",
	template: `
		<p>
			<strong>Hours Locations:</strong>
		</p>
		<div class="form-check w-100 w-lg-auto">
			<div class="row">
				<ng-container *ngFor="let projectCat of projectCats; index as i">
					<div class="col-12 col-lg-6">
						<input
							class="mr-1"
							type="checkbox"
							id="option{{ i }}"
							[ngModel]="(customerProjects$ | async)?.has(projectCat.project_catid)"
							(ngModelChange)="save($event, projectCat.project_catid)"
						/>
						<label for="option{{ i }}">{{ projectCat.project_cat }}</label>
					</div>
				</ng-container>
			</div>
		</div>
		<label class="mr-1 font-weight-bold" for="banner">Current Website Banner: </label>
		<input
			*ngIf="customerNote$ | async as customerNote"
			type="text"
			id="banner"
			[ngModel]="customerNote.customer_note"
			(ngModelChangeDebounced)="saveNote($event, customerNote.customer_noteid)"
		/><br />
	`,
	styles: [
		`
			:host {
				display: block;
			}
		`,
	],
})
export class ClientEditStoreHourLocationsComponent implements OnChanges {
	@Input() customerid!: number;

	customeridBS = new BehaviorSubject(0);

	saving = false;

	projectCats: any[] = [];

	customerProjectCats = new Map();

	customerProjects$ = this.customeridBS.pipe(
		switchMap((customerid) =>
			this.http.post("/api/statement/GetCustomerProjects", {
				vars: { customerid, project_cat_ofid: 74, groupByProject: false },
			}),
		),
		map((response: IStmtResults<any>) => {
			const ret = new Map();

			response.results?.forEach((row) => {
				ret.set(row.project_catid, row.customer_project);
			});
			return ret;
		}),
		shareReplay(1),
	);

	customerNote$ = this.customeridBS.pipe(
		switchMap((customerid) =>
			this.http.post("/api/statement/GetCustomerNotes", {
				vars: { customerid, note_titleid: 13 },
			}),
		),
		map((response: IStmtResults<any>) =>
			response.results && response.results[0]
				? response.results[0]
				: { customer_note: "", customer_noteid: null },
		),
	);

	constructor(private http: HttpClient, private toastr: ToastrService) {
		this.http
			.post("/api/statement/GetProjectCats", { vars: { project_cat_ofid: 74 } })
			.subscribe((response: IStmtResults<any>) => (this.projectCats = response.results!));
	}

	save(enable: boolean, project_catid: number) {
		if (!project_catid || !this.customerid) {
			this.toastr.error("Missing Project Category and Customer");
			return;
		}
		this.saving = true;

		if (enable) {
			this.http
				.post("/api/statement/AddCustomerProject", {
					vars: { customerid: this.customerid, project_catid: project_catid },
				})
				.subscribe(() => this.customeridBS.next(this.customerid));
		} else {
			this.http
				.post("/api/statement/DeleteCustomerProject", {
					vars: { customerid: this.customerid, project_catid: project_catid },
				})
				.subscribe(() => this.customeridBS.next(this.customerid));
		}
	}

	saveNote(note: string, customerNoteId: null | number) {
		if (note) {
			if (customerNoteId) {
				this.http
					.post("/api/statement/UpdateCustomerNote", {
						vars: { customer_noteid: customerNoteId, customer_note: note },
					})
					.subscribe(() => this.customeridBS.next(this.customerid));
			} else {
				this.http
					.post("/api/statement/AddCustomerNote", {
						vars: { customerid: this.customerid, note_titleid: 13, customer_note: note },
					})
					.subscribe(() => this.customeridBS.next(this.customerid));
			}
		} else if (customerNoteId) {
			this.http
				.post("/api/statement/DeleteCustomerNote", {
					vars: { customer_noteid: customerNoteId },
				})
				.subscribe(() => this.customeridBS.next(this.customerid));
		}
	}

	ngOnChanges(changes: SimpleChanges): void {
		if (changes.customerid) {
			this.customeridBS.next(changes.customerid.currentValue);
		}
	}
}
