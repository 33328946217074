import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { ModalOutletComponent } from "./components/modal-outlet.component";
import { ModalComponent } from "./components/modal.component";
import { ModalDirective } from "./components/modal.directive";

@NgModule({
	declarations: [ModalComponent, ModalOutletComponent, ModalDirective],
	imports: [CommonModule, FontAwesomeModule],
	exports: [ModalComponent, ModalOutletComponent, ModalDirective],
})
export class ModalModule {}
